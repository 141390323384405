import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  AutoUnsubscribe,
  DateUtils,
  FeatureFlagKeys,
  HttpQueryFacade,
  UniAuthService,
  UniFeatureFlagsService,
  UniLanguageService,
  UniLayoutFacade,
  UniUserpilotService,
} from '@unifonic/common';
import { map, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AUDIENCE_QUERY_GROUP } from '@flows/store';

@Component({
  selector: 'fs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateUtils.datepickerFormat },
  ],
})
export class AppComponent extends AutoUnsubscribe implements OnInit {
  isLoaderActive = true;
  isLoaderActive$ = combineLatest([this.uniLayoutFacade.isLoaderActive$, this.httpQueryFacade.isInProgress$(AUDIENCE_QUERY_GROUP)]).pipe(
    map((loaders) => loaders.includes(true))
  );

  constructor(
    private uniLanguageService: UniLanguageService,
    private uniLayoutFacade: UniLayoutFacade,
    private httpQueryFacade: HttpQueryFacade,
    private cdr: ChangeDetectorRef,
    private uniAuthService: UniAuthService,
    private uniFeatureFlagService: UniFeatureFlagsService,
    private userpilotService: UniUserpilotService,
    @Optional() @Inject('environment') public environment
  ) {
    super();
    this.uniLayoutFacade.setIsLoader(true);
    this.uniAuthService.clearUserData();
  }

  initLoader() {
    this.subs.sink = this.isLoaderActive$.subscribe((isActive) => {
      this.isLoaderActive = isActive;
      this.cdr.detectChanges();
    });
  }

  ngOnInit() {
    this.uniLanguageService.initLanguages();
    this.uniFeatureFlagService
      .getPermission$(FeatureFlagKeys.userpilot_2022_q_1, true)
      .pipe(take(1))
      .subscribe(() => {
        this.userpilotService.initialize(this.environment.userpilotToken);
      });
    this.initLoader();
  }
}
