import * as TemplatesActions from '@templates/store/templates.actions';
import {
  TemplatesState,
  initialState,
} from '@templates/store/templates.state';

export function templatesReducer(
  state = initialState,
  action: TemplatesActions.All,
): TemplatesState {
  switch (action.type) {
    case TemplatesActions.SET_USER_TEMPLATES_SUCCESS: {
      return {
        ...state,
        userTemplates: action.response,
      };
    }
    case TemplatesActions.SAVE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        template: action.response,
      };
    }
    case TemplatesActions.SET_TEMPLATE_SUCCESS: {
      return {
        ...state,
        template: action.response,
      };
    }
    case TemplatesActions.UPDATE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        templateStatusResponse: action.response,
      };
    }
    case TemplatesActions.DESTROY_TEMPLATE_SUCCESS: {
      return {
        ...state,
        template: {},
      };
    }
    default: {
      return {...state};
    }
  }
}
