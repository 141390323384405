import {Injectable} from '@angular/core';
import {UniAuthFacade, Permission} from '@unifonic/common';


@Injectable({providedIn: 'root'})
export class TemplatePermissionsService {

  constructor(private uniAuthFacade: UniAuthFacade) {
  }

  canSee(): boolean {
    return this.uniAuthFacade.hasSomePermission('FS.TEMPLATE_MANAGEMENT', [
      Permission.see
    ]);
  }

  canCreate(): boolean {
    return this.uniAuthFacade.isAdminLevel();
  }

  canEdit(): boolean {
    return this.uniAuthFacade.isAdminLevel();
  }
}
