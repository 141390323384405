import * as WebhookTriggersActions from './webhook-trigger.actions';
import {
  WebhookTriggersState,
  initialWebhookTriggerState,
} from './webhook-trigger.state';

export function webhookTriggersReducer(
  state = initialWebhookTriggerState,
  action: WebhookTriggersActions.All,
): WebhookTriggersState {
  switch (action.type) {
    case WebhookTriggersActions.SET_FLOW_JWT_ACCESS_TOKEN_SUCCESS: {
      return {
        ...state,
        flowAuthenticationModel: action.response,
      };
    }
    case WebhookTriggersActions.GENERATE_FLOW_REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        flowAuthenticationModel: action.response,
      };
    }
    default: {
      return {...state};
    }
  }
}
