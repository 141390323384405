import * as LoginActions from './login.actions';
import { LoginState, initialState } from './login.state';

export function reducer(
  state = initialState,
  action: LoginActions.All
): LoginState {
  switch (action.type) {
    case LoginActions.SET_WORKSPACES_SUCCESS: {
      return {
        ...state,
        workspaces: action.workspaces,
        workspacesAmount: action.totalItems,
      };
    }
    default: {
      return { ...state };
    }
  }
}
