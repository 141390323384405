import {TemplateResponse, Template, TemplateStatusResponse} from './../shared/templates.model';

export const TEMPLATES_KEY = 'templates';
export interface TemplatesState {
  userTemplates: TemplateResponse;
  template: Template;
  templateStatusResponse: TemplateStatusResponse;
}

export const initialState: TemplatesState = {
  userTemplates: {},
  template: {},
  templateStatusResponse: {}
};
