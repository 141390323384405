import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { StoreModule } from '@ngrx/store';

import {
  HttpQueryModule,
  SettingFactory,
  SettingsHttpService,
  TokenInterceptorFactory,
  UniAuthRepository,
  UniAuthService,
  UniSharedModule
} from '@unifonic/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatExpansionModule } from '@angular/material/expansion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FlowBuilderComponent } from './flow-builder/flow-builder.component';
import { FlowListComponent } from './flow-list/flow-list.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { LazyTranslateLoader } from './app-translate-loader';
import { ExampleComponent } from './example/example.component';
import { LogoutComponent } from './logout/logout.component';
import { ActivationRequestComponent } from './activation-request/activation-request.component';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from 'ngx-clipboard';
import { FlowModule } from './flows/flows.module';
import { FlowsListModule } from '@flows/modules/flows-list/flows-list.module';
import { FlowTemplatesModule } from './flow-templates/flow-templates.module';
import { FlowExecutionComponent } from './flow-execution/flow-execution.component';
import { AuthModule } from '@auth/auth.module';
import { ErrorRoutingModule } from './error-routing.module';
import { effectsModule, reducerProvider, storeDevtoolsModule, storeModule } from './store';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { TemplatesModule } from '@templates/templates.module';
import { SharedModule } from '@shared/shared.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ExecutionChannelReportingComponent } from './execution-channel-reporting/execution-channel-reporting.component';
import { SettingsService } from '@shared/settings.service';

const modules = [
  AuthModule,
  FlowModule,
  FlowsListModule,
  TemplatesModule,
  MatMenuModule,
  MatGridListModule,
  MatTableModule,
  HttpClientModule,
  MatToolbarModule,
  // CommonModule,
  BrowserModule,
  BrowserAnimationsModule,
  MatExpansionModule,
  AppRoutingModule,
  StoreModule,
  ErrorRoutingModule,
  UniSharedModule,
  MatSnackBarModule,
  HttpQueryModule,
  LayoutModule,
  ...storeModule,
  ...effectsModule,
  ...storeDevtoolsModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: LazyTranslateLoader,
    },
  }),
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    ExampleComponent,
    LogoutComponent,
    FlowBuilderComponent,
    FlowListComponent,
    SideNavigationComponent,
    TemplateListComponent,
    FlowExecutionComponent,
    ActivationRequestComponent,
    ExecutionChannelReportingComponent,
  ],
  imports: [NgxGraphModule, ClipboardModule, FlowTemplatesModule, ...modules, SharedModule],
  exports: [modules],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: SettingFactory,
      deps: [SettingsHttpService],
      multi: true,
    },
    CookieService,
    reducerProvider,
    {
      provide: 'environment',
      useFactory: (settingsService: SettingsService) => settingsService.settings,
      deps: [SettingsService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: TokenInterceptorFactory(),
      multi: true,
      deps: [UniAuthService, UniAuthRepository, SettingsService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
