<uni-loader *ngIf="isLoaderActive"></uni-loader>

<uni-container>
    <uni-section [isFirst]="true">
        <uni-title-container>
            <uni-title-item>
                <uni-text-body>
                    MY flows
                    <ng-container *ngIf="dataSource">
                        ({{totalItemsCount}} flows)
                    </ng-container>
                </uni-text-body>
            </uni-title-item>
        </uni-title-container>
        <uni-separator></uni-separator>
    </uni-section>

    <uni-section>
        <div class="row">
            <div class="col-md-10">
                <uni-box-tabs>
                    <uni-box-tab
                        (click)="changeTab('ALL')"
                        [isActive]="activeTab === 'ALL'"
                    >
                        All
                    </uni-box-tab>
                    <uni-box-tab
                        (click)="changeTab('ACTIVE')"
                        [isActive]="activeTab === 'ACTIVE'"
                    >
                        Active
                    </uni-box-tab>
                    <uni-box-tab
                        (click)="changeTab('DRAFT')"
                        [isActive]="activeTab === 'DRAFT'"
                    >
                        DRAFT
                    </uni-box-tab>
                    <uni-box-tab
                        (click)="changeTab('INACTIVE')"
                        [isActive]="activeTab === 'INACTIVE'"
                    >
                        Inactive
                    </uni-box-tab>
                </uni-box-tabs>
            </div>
            <div class="col-md-1">
                <uni-button
                    [routerLink]="'/studio'"
                    [fitContent] = "'true'"
                    [fit] = "'true'"
                >
                Create
                </uni-button>
            </div>
        </div>
        <uni-table>
            <table [dataSource]="dataSource" cdk-table multiTemplateDataRows>

                <ng-container cdkColumnDef="name">
                    <th *cdkHeaderCellDef cdk-header-cell>
                        Name
                    </th>
                    <td *cdkCellDef="let element" cdk-cell>
                        <div class="flow-column-content flow-name">{{ element.name }}</div>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="version">
                    <th *cdkHeaderCellDef cdk-header-cell>
                        Versions
                    </th>
                    <td *cdkCellDef="let element" cdk-cell>
                        <div class="flow-column-content flow-version">
                            {{ element.version }}
                        </div>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="created">
                    <th *cdkHeaderCellDef cdk-header-cell>
                        Created
                    </th>
                    <td *cdkCellDef="let element" cdk-cell>
                        <div class="flow-column-content">
                            {{ element.createdAt | date: 'dd-MM-yyyy' }}
                        </div>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="last_used">
                    <th *cdkHeaderCellDef cdk-header-cell>
                        Last Used
                    </th>
                    <td *cdkCellDef="let element" cdk-cell>
                        <div class="flow-column-content">
                            {{ element.lastUsed | date: 'dd-MM-yyyy HH:mm' }}
                        </div>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="trigger">
                    <th *cdkHeaderCellDef cdk-header-cell>
                        Trigger
                    </th>
                    <td *cdkCellDef="let element" cdk-cell>
                        <div class="flow-column-content">
                            {{ element.trigger }}
                        </div>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="status">
                    <th *cdkHeaderCellDef cdk-header-cell>
                        Status
                    </th>
                    <td *cdkCellDef="let element" cdk-cell>
             <span [ngClass]="{
                                  'btn-outline-primary':element.status === 'DRAFT',
                                  'btn-outline-warning':element.status === 'FINALISING',
                                  'btn-outline-success':element.status === 'ACTIVE'
                                }" class="btn ">
                                {{element.status}}</span>
                    </td>
                </ng-container>

                <uni-table-action>
                    <ng-container cdkColumnDef="actions">
                        <th *cdkHeaderCellDef cdk-header-cell class="s">
                            Actions
                        </th>
                        <td *cdkCellDef="let element" cdk-cell class="list-actions">
                            <uni-link [path]="'studio?flowId=' + element.id + '&flowVersion=' + element.version + ''">
                                <uni-icon [name]="'edit'"></uni-icon>
                            </uni-link>
                            <uni-icon (click)="getVersionedData(element)"
                                      [class.example-expanded-row]="expandedElement?.id === element.id"
                                      [name]="'fas fa-layer-group'" [style]="'fas'"></uni-icon>
                            <uni-icon [matMenuTriggerFor]="menu" [name]="'ellipsis-v'"></uni-icon>
                            <mat-menu #menu="matMenu">
                                <button (click)="deleteConfirm(element)" mat-menu-item>
                                    <span>{{ 'label.delete' | translate }}</span>
                                </button>
                                <button mat-menu-item>
                                    <uni-link [path]="'studio?flowId=' + element.id +
                                    '&isDuplicate=true&flowVersion=' + element.version + ''">
                                      {{ 'label.duplicate' | translate }}
                                    </uni-link>
                                </button>
                              <button (click)="stopFlow(element)" mat-menu-item>
                                <span>{{ 'label.stopFlow' | translate }}</span>
                              </button>
                            </mat-menu>
                        </td>
                    </ng-container>
                </uni-table-action>

                <uni-box>
                    <ng-container cdkColumnDef="expandedDetail">
                        <td *cdkCellDef="let element" colspan="12">

                            <div [@detailExpand]="element.id == expandedElement?.id ? 'expanded' : 'collapsed'"
                                 class="example-element-detail">
                                <uni-section>
                                    <uni-box>
                                        <div class="div-table" *ngIf="versionedFlows.length">

                                            <ng-container *ngFor="let versionedFlow of versionedFlows">
                                                <mat-grid-list cols="5" rowHeight="50px">
                                                    <mat-grid-tile>
                                                        <div class=" flow-name">
                                                            {{versionedFlow.name}} v {{versionedFlow.version}}
                                                        </div>
                                                    </mat-grid-tile>
                                                    <mat-grid-tile>
                                                        <div class=" version-date">
                                                            {{versionedFlow.createdAt | date : 'dd-MM-yyyy'}}
                                                        </div>
                                                    </mat-grid-tile>
                                                    <mat-grid-tile>
                                                        <div
                                                            class=" version-date">{{versionedFlow.createdAt | date : 'HH:mm'}}</div>
                                                    </mat-grid-tile>
                                                    <mat-grid-tile>
                                                        <div class="version-status">
                                                              <span [ngClass]="{
                                                                  'btn-outline-primary':versionedFlow.status === 'DRAFT',
                                                                  'btn-outline-warning':versionedFlow.status === 'FINALISING'
                                                                }" class="btn ">
                                                                {{versionedFlow.status}}</span>
                                                        </div>
                                                    </mat-grid-tile>
                                                    <mat-grid-tile>
                                                        <div class="">
                                                            <uni-link [path]="'studio?flowId=' + expandedElement?.id + '&flowVersion=' + versionedFlow?.version + ''">
                                                                <uni-icon [name]="'edit'"></uni-icon>
                                                            </uni-link>
                                                        </div>
                                                    </mat-grid-tile>
                                                </mat-grid-list>
                                            </ng-container>
                                        </div>
                                        <div class="div-table text-center" *ngIf="versionedFlows.length == 0">
                                            <h4>You don't have versions created for this flow</h4>
                                        </div>

                                    </uni-box>
                                </uni-section>
                            </div>


                        </td>

                    </ng-container>
                </uni-box>
                <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
                <tr
                    *cdkRowDef="let row; let element; columns: displayedColumns; let isOdd = odd; let isEven = even; let isLast = last"
                    [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
                    cdk-row
                    class="example-element-row"
                >

                </tr>
                <tr *cdkRowDef="let row; columns: ['expandedDetail']" cdk-row class="example-detail-row"></tr>
                <!--                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>-->
            </table>
        </uni-table>

        <uni-pagination
            [items]="totalItemsCount"
            [itemsPerPage]="itemsPerPage"
        ></uni-pagination>
    </uni-section>
</uni-container>


<uni-modal
    [isActive]="isModalActive"
>

    <uni-modal-content *ngIf="elementForDelete">
        <div class="modal-icon-container">
            <uni-icon [name]="'trash-alt fa-stack-2x'" [style]="'fas'" class="modal-icon"></uni-icon>
        </div>
        <uni-text-subheader>Are you sure you want to delete this flow?</uni-text-subheader>
        <uni-text-info>All information related to the flow will be also removed.</uni-text-info>
    </uni-modal-content>
    <uni-modal-footer>
        <uni-button (click)="onReset()" [style]="'grey'">
            Cancel
        </uni-button>
        <uni-button (click)="onConfirmReset()" [style]="'alert'">
            Delete
        </uni-button>
    </uni-modal-footer>
</uni-modal>
