import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'uni-menu',
  templateUrl: './uni-menu.component.html',
  styleUrls: ['./uni-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniMenuComponent {
}
