<div xmlns="http://www.w3.org/1999/html">
  <label>Variables</label>
  <p>All currently available variables will be added to the request body for POST</p>
</div>


<form [formGroup]="form">
  <input class="form-control" formControlName="variableName" id="variableName" name="variableName" type="text" uniInput>
  <div class="mt-3">
    <uni-link (click)="onSubmit()"  >Add variable</uni-link>
  </div>
</form>
