
export class FlowModel {

  constructor(
    public id?: string,
    public name?: string,
    public description?: string,
    public businessKey?: string,
    public version?: number,
    public createdAt?: string,
    public lastUsed?: string,
    public trigger?: string,
    public channel?: number,
    public status?: string,
    public flowVersions?: FlowVersion[],
    public components?: FlowComponentModel[],
  ) {
  }

}

export class FlowTemplateModel {

  constructor(
    public flowTemplateId?: string,
    public status?: string,
    public components?: FlowComponentModel[],
  ) {
  }

}

export class FlowComponentModel {

  constructor(
    public index?: number,
    public type?: string,
    public skip?: boolean,
    public variable?: string,
    public operator?: string,
    public value?: string,
    public data?: Data,
    public components?: FlowComponentModel[],
    public nodes?: FlowComponentModel[],
    public name?: string,
    public variables?: string[]
  ) {
  }

}



export class FlowResponseModel {

  constructor(
    public id?: string,
    public version?: number,
    public name?: string,
    public description?: string,
    public userFlowId?: string,
    public flowId?: string
  ) {

  }

}



export interface FlowDetailModel {
  id: null;
  name: null;
  version: number;
  createDate: null;
  lastUsed: null;
  trigger: null;
  channel: number;
  status: null;
  accountId: null;
  flow: Flow;
  flowVersion: FlowVersion;
  triggerType: string;
}

export interface Flow {
  id: string;
  name: string;
  description: string;
  businessKey: null;
  flowVersions: FlowVersion[];
  createdAt: Date;
  modifiedAt: Date;
}

export interface FlowVersion {
  id: string;
  flowJson: FlowJSON[];
  flowStatus: string;
  startExecutionName: string;
  deploymentId: string;
  processDefinitionId: string;
  flowVersion: number;
  camundaVersion: null;
  lastExecutionTime: string;
  root: boolean;
  createdAt: string;
  modifiedAt: string;

}

export interface FlowTemplate {
  id: string;
  name: string;
  description: string;
  flowJson: FlowJSON[];
  templateStatus: string;
  triggerType: string;
  createdAt: string;
  modifiedAt: string;

}

export interface FlowJSON {
  skip: boolean;
  name: string;
  index: string;
  type: string;
  variables: string[];
  operator: string;
  value: string;
  data: Data;
  components?: FlowComponentModel[],
  nodes?: FlowComponentModel[],
}

export interface Data {
  color?: string;
  source_id?: string;
  whatsapp_recipient?: string;
  whatsapp_message_text?: string;
  wait_hours?: string;
  wait_minutes?: string;
  wait_seconds?: string;
  jump_to_destination_id?: string;
  jump_to_max_loop_count?: string;
  jump_to_destination_type?: string;
  jump_to_destination_color?: string;
  sms_recipient?: string;
  sms_message_text?: string;
}

export interface EdAt {
  nano: number;
  year: number;
  monthValue: number;
  dayOfMonth: number;
  hour: number;
  minute: number;
  second: number;
  month: string;
  dayOfWeek: string;
  dayOfYear: number;
}


