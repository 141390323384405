export enum UniIconType {
  // Current design names
  boldest = 'boldest',
  subtle = 'subtle',
  subtler = 'subtler',
  inverse = 'inverse',
  disabled = 'disabled',
  brand = 'brand',
  selected = 'selected',
  danger = 'danger',
  warning = 'warning',
  success = 'success',
  info = 'info',
  discovery = 'discovery',
  gray = 'gray',
  blue = 'blue',
  red = 'red',
  green = 'green',
  yellow = 'yellow',
  orange ='orange',
  purple = 'purple',
  teal = 'teal',
  pink = 'pink',

  simple = 'simple',
  white = 'white',
  destructive = 'destructive',
  secondary = 'secondary',
  link = 'link',
  neutral = 'neutral',
  error = 'error',
}
