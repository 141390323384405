import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UniCommonModule } from '@unifonic/common';
import { CheckPermissionsDirective } from '@shared/check-permissions.directive';
import { PhonePipe } from './phone.pipe';

const modules = [CommonModule, UniCommonModule, TranslateModule, RouterModule];

const directives = [CheckPermissionsDirective];

const pipes = [PhonePipe];

@NgModule({
  declarations: [...directives, ...pipes],
  imports: [...modules],
  exports: [...modules, ...directives, ...pipes],
})
export class SharedModule {}
