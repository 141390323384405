import { Observable, of } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { UniSnackbarFacade, httpEncoder, uniSnackbarActions } from '@unifonic/common';
import { SettingsService } from '@shared/settings.service';

import { FLOWS_KEY } from '@flows/store/flows.state';
import {
  Flow,
  FlowJumptoStorageModel,
  FlowResponse,
  FlowTemplateModel,
  FlowVersion,
  FlowVersionDTO,
  UpdateFlowDTO
} from './flows.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FlowsRepository {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private uniSnackbarFacade: UniSnackbarFacade
  ) {
  }

  getUserFlows(accountId: string, params: Params = {}): Observable<HttpResponse<FlowResponse>> {
    return this.http.get<FlowResponse>(`${this.settingsService.apiUrl}/api/v2/flows`, {
      params: httpEncoder(params),
      observe: 'response',
      headers: {
        queryName: 'getUserFlows',
        queryGroups: [FLOWS_KEY]
      }
    });
  }

  deleteFlow(
    flowId: string,
    accountId: string,
    flowVersion: number
  ): Observable<any> {
    return this.http.delete(`${this.settingsService.apiUrl}/api/v2/flow/${flowId}?flowVersion=${flowVersion}`, {
      headers: {
        queryName: 'deleteFlow',
        queryGroups: [FLOWS_KEY]
      }
    });
  }

  getFlowVersions(accountId: string, id: string, params: Params = {}): Observable<HttpResponse<FlowVersion[]>> {
    return this.http.get<FlowVersion[]>(`${this.settingsService.apiUrl}/api/v2/flow/${id}/flow-versions`, {
      params: httpEncoder(params),
      observe: 'response',
      headers: {
        queryName: 'getFlowVersions',
        queryGroups: [FLOWS_KEY]
      }
    });
  }

  getFlow(accountId: string, params: Params = {}): Observable<HttpResponse<Flow>> {
    return this.http.get<Flow>(
      `${this.settingsService.apiUrl}/api/v2/flow/${params.flowId}`,
      {
        params: httpEncoder(params),
        observe: 'response',
        headers: {
          queryName: 'getFlow',
          queryGroups: [FLOWS_KEY]
        }
      });
  }

  saveFlow(accountId: string, flow: Flow): Observable<any> {
    return this.http.post(
      `${this.settingsService.apiUrl}/api/v2/flow`,
      flow, {
        observe: 'body',
        headers: {
          queryName: 'saveFlow',
          queryGroups: [FLOWS_KEY]
        }
      }).pipe(catchError((err) => {
        this.uniSnackbarFacade.show('error', 'snackbar.errorDefault');
        throw err;
      }));
  }

  updateFlowName(accountId: string, flow: UpdateFlowDTO): Observable<any> {
    return this.http.put(
      `${this.settingsService.apiUrl}/api/v2/flow/name`,
      flow, {
        observe: 'body',
        headers: {
          queryName: 'updateFlowName',
          queryGroups: [FLOWS_KEY]
        }
      });
  }

  createFlowVersion(accountId: string, flowId: string, flow: Flow): Observable<any> {
    const url = `${this.settingsService.apiUrl}/api/v2/flow/${flowId}/flow-version`;
    return this.http.post(url, flow, {
      observe: 'body',
      headers: {
        queryName: 'saveFlow',
        queryGroups: [FLOWS_KEY]
      }
    }).pipe(catchError((err) => {
      this.uniSnackbarFacade.show('error', 'snackbar.errorDefault');
      throw err;
    }));
  }

  getActiveFlowVersions(accountId: string, status: string, params: Params = {}): Observable<HttpResponse<FlowVersionDTO[]>> {
    return this.http.get<FlowVersionDTO[]>(`${this.settingsService.apiUrl}/api/v2/flows/status/${status}`, {
      params: httpEncoder(params),
      observe: 'response',
      headers: {
        queryName: 'getActiveFlowVersions',
        queryGroups: [FLOWS_KEY]
      }
    });
  }

  deployFlow(flowId: string, flowVersion: number, accountId: string) {
    const url = `${this.settingsService.apiUrl}/api/v2/flow/${flowId}/deploy?flowVersion=${flowVersion}`;
    return this.http.post(url, {}, {
      observe: 'response',
      headers: {
        queryName: 'deployFlow',
        queryGroups: [FLOWS_KEY]
      }
    });
  }

  executeFlow(flowId: string, requestParams: object) {
    const url = `${this.settingsService.apiUrl}/api/v1/execution/flow/${flowId}/execute`;
    return this.http.post(url, requestParams, {
      observe: 'response',
      headers: {
        queryName: 'executeFlow',
        queryGroups: [FLOWS_KEY]
      }
    });
  }

  saveTemplate(flow: Flow): Observable<any> {
    return this.http.post(
      `${this.settingsService.apiUrl}/api/v1/template`,
      flow, {
        observe: 'body',
        headers: {
          queryName: 'executeFlow',
          queryGroups: [FLOWS_KEY]
        }
      });
  }

  updateFlowTemplate(data: FlowTemplateModel): Observable<any> {
    return this.http.patch(
      `${this.settingsService.apiUrl}/api/v1/template`,
      data, {
        observe: 'body',
        headers: {
          queryName: 'executeFlow',
          queryGroups: [FLOWS_KEY]
        }
      });
  }

  toggleWidgetParameterVisibility(data: boolean): Observable<any> {
    return new Observable(function subscribe(subscriber) {
      subscriber.next(data);
    });
  }


  toggleJumptoSelection(data: FlowJumptoStorageModel): Observable<any> {
    return new Observable(function subscribe(subscriber) {
      subscriber.next(data);
    });
  }

  undeployFlow(flowId: string, flowVersion: number, accountId: string) {
    const url = `${this.settingsService.apiUrl}/api/v2/flow/${flowId}/undeploy?flowVersion=${flowVersion}`;
    return this.http.post(url, {}, {
      observe: 'response',
      headers: {
        queryName: 'undeployFlow',
        queryGroups: [FLOWS_KEY]
      }
    });
  }

  validateScript(script: string, inputData: any) {
    const url = `${this.settingsService.apiUrl}/api/v2/validate/script`;
    let data: any = {};
    data.script = script;
    data.inputData = inputData;
    return this.http.post(url, data, {
      observe: 'response',
      headers: {
        queryName: 'validateScript',
        queryGroups: [FLOWS_KEY]
      }
    });
  }

  toggleWhatsAppSideBarVisibility(data: boolean): Observable<any> {
    return new Observable(function subscribe(subscriber) {
      subscriber.next(data);
    });
  }

  clearCache() {
    const url = `${this.settingsService.apiUrl}/api/v1/proxy/conversation/clear/redis/cache`;
    return this.http.delete(url, {
      observe: 'response',
      headers: {
        queryName: 'clearCache',
        queryGroups: [FLOWS_KEY]
      }
    });
  }
}
