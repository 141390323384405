<uni-loader *ngIf="isLoaderActive"></uni-loader>

<uni-container>
  <uni-section [isFirst]="true">
    <uni-title-container>
      <uni-title-item>
        <uni-text-body>
          {{ "flows.execution.title" | translate }}
          <ng-container *ngIf="dataSource"> ({{ totalElements }} executions)</ng-container>
        </uni-text-body>
      </uni-title-item>
    </uni-title-container>
    <uni-separator></uni-separator>
  </uni-section>

  <uni-section>
    <div *ngIf="form" [formGroup]="form" class="row mb-4">
      <div class="col-md-4">
        <div class="uni-form-search">
          <uni-label>
            {{ "flows.execution.referenceFilter" | translate }}
          </uni-label>
          <uni-form-field [margin]="false">
            <input
              (keydown.enter)="getExecutionStatusByReferenceId()"
              [placeholder]="'flows.execution.referenceFilter' | translate"
              [readonly]="isLoaderActive"
              formControlName="search"
              type="text"
              uniInput
            />
            <div *ngIf="true" uniSuffix>
              <uni-icon (click)="getExecutionStatusByReferenceId()" *ngIf="!searched" name="search"></uni-icon>
              <uni-icon (click)="clearSearchInput()" *ngIf="searched" name="times"></uni-icon>
            </div>
          </uni-form-field>
        </div>
      </div>

      <ng-container *ngIf="!searched">
        <div class="col-md-4">
          <uni-label class="align-items-center">
            {{ "flows.execution.selectDateRange" | translate }}
          </uni-label>
          <uni-form-field [margin]="false">
            <mat-form-field class="py-3">
              <mat-date-range-input (click)="rangePicker.open()" [rangePicker]="rangePicker" class="flow_execution_dateRange">
                <input formControlName="startDate" matStartDate placeholder="Start date" readonly />
                <input formControlName="endDate" matEndDate placeholder="End date" readonly />
              </mat-date-range-input>
              <mat-date-range-picker #rangePicker></mat-date-range-picker>
            </mat-form-field>
            <uni-icon-action (click)="clearDates()" *ngIf="isDatesSelected" name="times" uniSuffix></uni-icon-action>
          </uni-form-field>
        </div>
      </ng-container>

      <div class="col-md-2 ml-auto">
        <uni-label>&nbsp;</uni-label>
        <uni-button (click)="toggleVisibility()" [fitContent]="true">{{ "flows.execution.downloadReport" | translate }}</uni-button>
        <uni-modal (isActiveChange)="toggleVisibility()" [isActive]="isModalActive" [isCloseIcon]="true">
          <form (ngSubmit)="onSubmitDownloadReport()" [formGroup]="downloadReportForm">
            <uni-modal-content>
              <uni-form-field>
                <uni-label [required]="true" class="align-items-center">
                  {{ "flows.execution.selectDateRange" | translate }}
                  <mat-datepicker-toggle [for]="picker" uniLabelSuffix></mat-datepicker-toggle>
                </uni-label>
                <mat-form-field class="py-3">
                  <mat-date-range-input [rangePicker]="picker" class="flow_execution_dateRange">
                    <input formControlName="startDate" matStartDate placeholder="Start date" />
                    <input formControlName="endDate" matEndDate placeholder="End date" />
                  </mat-date-range-input>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </uni-form-field>

              <uni-form-field>
                <uni-label [required]="true">
                  {{ "flows.execution.emailAddress" | translate }}
                </uni-label>
                <input
                  [placeholder]="'flows.execution.emailAddress' | translate"
                  aria-label=""
                  formControlName="emailAddress"
                  type="email"
                  uniInput
                />
              </uni-form-field>

              <uni-checkbox>
                <mat-checkbox formControlName="isWithVariables">{{ "flows.execution.isWithVariables" | translate }}</mat-checkbox>
              </uni-checkbox>

              <ng-container *ngIf="{ isWithVariables: downloadReportForm.get('isWithVariables').value } as data">
                <uni-form-autocomplete
                  [control]="getControl(downloadReportForm, 'flow')"
                  [isLoading]="isLoadingFlows"
                  [options]="flowNameAndVersionList"
                  [required]="data.isWithVariables"
                  filterKey="flowName"
                  label="table.name"
                ></uni-form-autocomplete>

                <uni-form-autocomplete
                  [control]="getControl(downloadReportForm, 'flowVersion')"
                  [options]="flowVersionList"
                  [required]="data.isWithVariables"
                  label="table.version"
                ></uni-form-autocomplete>
              </ng-container>
            </uni-modal-content>
            <uni-modal-footer>
              <uni-button [disabled]="downloadReportForm.invalid" type="submit">
                {{ "flows.execution.download" | translate }}
              </uni-button>
            </uni-modal-footer>
          </form>
        </uni-modal>
      </div>
    </div>

    <div class="example-container mat-elevation-z8">
      <mat-table #table [dataSource]="dataSource">
        <!-- Name Column -->
        <ng-container [matColumnDef]="displayedColumns.name">
          <mat-header-cell *matHeaderCellDef> {{ "table." + displayedColumns.name | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.flowName }} (v{{ element.flowVersion }})</mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns.executionId">
          <mat-header-cell *matHeaderCellDef> {{ "flows.execution.reference" | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <uni-clipboard [isIcon]="true" [text]="element.executionId" length="14"></uni-clipboard>
          </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns.executionStatus">
          <mat-header-cell *matHeaderCellDef> {{ "flows.execution.status" | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span
              [ngClass]="{
                'success-status': element.executionStatus === 'SUCCESS',
                'running-status': element.executionStatus === 'RUNNING',
                'failed-status': element.executionStatus === 'FAILED'
              }"
              class="badge border"
            >
              {{ element.executionStatus }}</span
            >
          </mat-cell>
        </ng-container>

        <!-- Created Column -->
        <ng-container [matColumnDef]="displayedColumns.created">
          <mat-header-cell *matHeaderCellDef> {{ "table." + displayedColumns.created | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.createdAt | dateFormat }}</mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns.actions">
          <mat-header-cell *matHeaderCellDef>{{ "label.action" | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div style="margin-right: 5px">
              <uni-icon
                (click)="getFlowExecutionLogsData(element)"
                [class.example-expanded-row]="expandedElement?.flowId === element.flowId"
                [name]="'fas fa-layer-group'"
                [style]="'fas'"
              ></uni-icon>
              <uni-icon
                (click)="displayExecutionVariables(element)"
                [name]="'fas fa-th-list'"
                [style]="'fas'"
                class="variables-list"
              ></uni-icon>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column -->
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let detail">
            <div class="element-detail">
              <uni-section>
                <uni-box>
                  <div class="flow_execution_log_container">
                    <header *ngIf="expandedElement" class="clearfix">
                      <h4>{{ expandedElement.flowName | translate }} {{ "flows.execution.title" | translate }}</h4>
                    </header>
                    <div *ngIf="flowExecutionLogs.length > 0" class="flow_execution_log_main">
                      <ul class="flow_execution_log_tmtimeline">
                        <li *ngFor="let flowExecutionLog of flowExecutionLogs">
                          <time class="flow_execution_log_tmtime">
                            <span>{{ flowExecutionLog.createdAt | date: "dd/MM/yyyy" }}</span>
                            <span> {{ flowExecutionLog.createdAt | date: "HH:mm:ss" }}</span>
                          </time>
                          <div
                            class="{{
                              flowExecutionLog.status === 'SUCCESS'
                                ? 'flow_execution_log_tmicon flow_execution_log_tmicon-phone success-status success_box'
                                : 'flow_execution_log_tmicon flow_execution_log_tmicon-phone failed-status failed_box'
                            }}"
                          >
                            <i
                              class="{{ getWidgetItemByType(flowExecutionLog.widgetType).iconPrefix }} {{
                                getWidgetItemByType(flowExecutionLog.widgetType).icon
                              }} "
                            ></i>
                          </div>
                          <div class="flow_execution_log_tmlabel">
                            <h2>{{ flowExecutionLog.widgetName }}</h2>
                            <p>
                              {{ "flows.execution.duration" | translate }} :
                              {{ timeConverter(flowExecutionLog.duration) | date: "mm:ss" }} secs
                            </p>
                            <p>{{ "flows.execution.status" | translate }} : {{ flowExecutionLog.status }}</p>
                            <p>{{ "flows.execution.statusMessage" | translate }} : {{ flowExecutionLog.errorMessage }}</p>
                            <div>{{ "flows.execution.variable" | translate }} :</div>
                            <pre>{{ flowExecutionLog.flowVariable | json }}</pre>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </uni-box>
              </uni-section>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="myDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: myDisplayedColumns" [class.expanded]="expandedElement == row" class="element-row" matRipple>
        </mat-row>
        <mat-row
          *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
          [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'"
          style="overflow: hidden"
        >
        </mat-row>
      </mat-table>
    </div>
    <uni-pagination *ngIf="!searched" [itemsPerPage]="itemsPerPage" [items]="totalElements"></uni-pagination>
    <uni-card *ngIf="!totalElements && (searched || isDatesSelected)" class="mt-3 text-center">
      <uni-card-description>{{ "flows.execution.noExecutions" | translate }}</uni-card-description>
    </uni-card>
  </uni-section>
</uni-container>

<uni-modal [isActive]="isVariablesModalActive">
  <uni-modal-content>
    <uni-text-subheader *ngIf="actualVariablesDataSource">{{ "label.executionVariables" | translate }}</uni-text-subheader>

    <table [dataSource]="actualVariablesDataSource" mat-table>
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>{{ "table.name" | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.key }}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th *matHeaderCellDef mat-header-cell>{{ "label.value" | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.value }}</td>
      </ng-container>
      <tr *matHeaderRowDef="variableColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: variableColumns" mat-row></tr>
    </table>
    <ng-container *ngIf="actualVariablesDataSource.length === 0">
      <uni-text-info>{{ "label.noExecutionVariables" | translate }}</uni-text-info>
    </ng-container>
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button (click)="toggleVariablesModal()" [style]="'secondary'"> Close</uni-button>
  </uni-modal-footer>
</uni-modal>
