import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TriggerSelectionComponent } from './trigger-selection/trigger-selection.component';
import { WidgetVariableComponent } from './widget-variable/widget-variable.component';
import {UniSharedModule, UniTextModule} from "../../../unifonic-common/src";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { FlowListTableComponent } from './flow-list-table/flow-list-table.component';



@NgModule({
    declarations: [
        TriggerSelectionComponent,
        WidgetVariableComponent,
        FlowListTableComponent
    ],
  exports: [
    TriggerSelectionComponent,
    WidgetVariableComponent,
    FlowListTableComponent
  ],
  imports: [
    CommonModule,
    UniTextModule,
    FormsModule,
    ReactiveFormsModule,
    UniSharedModule
  ]
})
export class FlowModule { }
