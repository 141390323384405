import { Injectable } from '@angular/core';
import WidgetList from '../../../../../../assets/settings/widget-list.json';
import { WidgetCategoryListModel, WidgetListModel, WidgetModel } from '@flows/shared/widget.model';
import { UniFeatureFlagsService, UniLanguage, UniLanguageService } from '@unifonic/common';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  private widgetCategoryListMap = new Map<boolean, WidgetCategoryListModel>();
  private widgetListModelMap = new Map<string, WidgetListModel>();

  constructor(private uniFeatureFlagService?: UniFeatureFlagsService, private uniLanguageService?: UniLanguageService) {
    this.uniFeatureFlagService?.flagsStateUpdated$().subscribe(() => {
      this.widgetCategoryListMap = new Map();
      this.widgetListModelMap = new Map();
    });
  }

  get selectedLanguage(): UniLanguage {
    return this.uniLanguageService?.getSelectedLanguage() || UniLanguage.en;
  }

  loadEnabledFlowWidgets(): WidgetCategoryListModel {
    return this.loadFlowWidgets(true);
  }

  loadEnabledFlowWidgetsFlattened(): WidgetListModel {
    return this.loadFlowWidgetsFlattened(true);
  }

  loadEnabledFlowTriggersFlattened(): WidgetListModel {
    return this.loadFlowWidgetsFlattened(true, true);
  }

  getWidgetsByTypes(types: string[]): WidgetListModel {
    return this.loadEnabledFlowWidgetsFlattened().filter((widget) => types.includes(widget.type));
  }

  findWidgetByType(widgetType: string): WidgetModel {
    return this.loadFlowWidgetsFlattened().find((widget) => widget.type === widgetType);
  }

  findTriggerWidgetByType(widgetType: string): WidgetModel {
    return this.loadFlowWidgetsFlattened(true, true).find((widget) => widget.type === widgetType);
  }

  findCategoryByType(widgetType: string): string {
    return this.loadEnabledFlowWidgetsFlattened().find((widget) => widget.type === widgetType)?.categoryName;
  }

  isSystemVariable(variableName: string, isTrigger = false): boolean {
    return this.loadFlowWidgetsFlattened(false, isTrigger).some((widget) => (widget.variables as string[])?.includes(variableName));
  }

  private loadFlowWidgets(enabledOnly = false): WidgetCategoryListModel {
    const cachedList = this.widgetCategoryListMap.get(enabledOnly);
    if (cachedList) {
      return cachedList;
    }
    let widgetCategories = WidgetList as WidgetCategoryListModel<true>;
    if (enabledOnly) {
      widgetCategories = widgetCategories.map((category) => ({
        ...category,
        groups: category.groups
          .map((group) => ({
            ...group,
            data: group.data.filter(
              (widget) => widget.enabled && (!widget.featureFlag || this.uniFeatureFlagService.getPermission(widget.featureFlag))
            ),
          }))
          .filter((group) => group.data.length),
      }));
    }
    const list = widgetCategories
      .filter((category) => category.groups.length)
      .map((category) => ({
        ...category,
        groups: category.groups.map((group) => ({
          ...group,
          data: group.data.map((widget) => ({
            ...widget,
            name: widget.name[this.selectedLanguage] || widget.name[UniLanguage.en],
            description: widget.description?.[this.selectedLanguage] || widget.description?.[UniLanguage.en],
          })),
        })),
      }));
    this.widgetCategoryListMap.set(enabledOnly, list);
    return list;
  }

  private loadFlowWidgetsFlattened(enabledOnly = false, isTrigger = false): WidgetListModel {
    const mapKey = `${enabledOnly}-${isTrigger}`;
    const cachedList = this.widgetListModelMap.get(mapKey);
    if (cachedList) {
      return cachedList;
    }
    const list = this.loadFlowWidgets(enabledOnly)
      .flatMap((category) =>
        category.groups.flatMap((group) =>
          group.data.map((widget) => ({ ...widget, categoryName: category.categoryName, groupName: group.groupName }))
        )
      )
      .filter((widget) => (isTrigger ? widget.trigger : !widget.trigger));
    this.widgetListModelMap.set(mapKey, list);
    return list;
  }
}
