<div class="content__card_header">
    <h1>{{ title }}</h1>
    <i aria-hidden="true" class="fas fa-times"></i>
</div>

<ng-container *ngFor="let widget of getWidgetList()" >
    <div (click)="triggerSelected(widget.type)" class="content__card_tile">
        <div class="content__card_tile_icon">
            <i aria-hidden="true" class="{{widget.icon}}"></i>
        </div>
        <div class="content__card_tile_info">
            <h1>{{widget.name}}</h1>
            <p>{{widget.description}}</p>
        </div>
    </div>

</ng-container>

