import * as AudienceActions from '@flows/store/audience/audience.actions';
import { AudienceState, initialAudienceState } from '@flows/store/audience/audience.state';

export function audienceReducer(state = initialAudienceState, action: AudienceActions.All): AudienceState {
  switch (action.type) {
    case AudienceActions.SET_AUDIENCE_EVENTS_SUCCESS: {
      return {
        ...state,
        audienceEvents: action.response,
      };
    }
    case AudienceActions.SET_AUDIENCE_SOURCES_SUCCESS: {
      return {
        ...state,
        audienceSources: action.response,
      };
    }
    case AudienceActions.SET_AUDIENCE_PROPERTIES_SUCCESS: {
      return {
        ...state,
        audienceProperties: action.response,
      };
    }
    case AudienceActions.SET_AUDIENCE_MAPPINGS_SUCCESS: {
      return {
        ...state,
        mappings: action.response,
      };
    }
    case AudienceActions.SET_SELECTED_AUDIENCE_MAPPING: {
      return {
        ...state,
        selectedMapping: action.mapping,
      };
    }
    default: {
      return { ...state };
    }
  }
}
