<uni-modal
  isCloseIcon="true"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-section-header>
    <uni-text-caption>
      {{ title }}
    </uni-text-caption>
  </uni-section-header>
  <uni-modal-content>
    {{ description }}
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button
      [style]="'secondary'"
      (click)="hideModal()"
    >
      {{ cancelLabel }}
    </uni-button>
    <uni-button (click)="onConfirm()">
      {{ confirmLabel }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
