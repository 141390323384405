import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {MatMenuModule} from "@angular/material/menu";
import {MatGridListModule} from "@angular/material/grid-list";
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatTableModule } from '@angular/material/table';
import { UniSharedModule } from '@unifonic/common';
import { FlowsListModalComponent } from './components/flows-list-modal/flows-list-modal.component';
import { FlowsListTableComponent } from './components/flows-list-table/flows-list-table.component';
import { FlowVersionListModalComponent } from './components/flow-version-list-modal/flow-version-list-modal.component';
import { FlowsListComponent } from './containers/flows-list/flows-list.component';


const components = [
  FlowsListComponent,
  FlowsListModalComponent,
  FlowsListTableComponent,
  FlowVersionListModalComponent,
];


@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatGridListModule,
    UniSharedModule,
    MatToolbarModule,
    MatTableModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class FlowsListModule { }
