import {FlowModel} from "./flow.model";

export class UserFlowModel {

  constructor(
      public id?: string,
      public accountId?: string,
      public createdAt?: Date,
      public modifiedAt?: Date,
      public flows?: FlowModel[],
      public totalElements?: number,
      public totalPages?: number
  ) {
  }


}
