import { Observable, Subscription } from 'rxjs';

import { Component, HostBinding, OnInit } from '@angular/core';

import { ProductKey, ProductsService } from '../../../../shared';
import { AutoUnsubscribe } from '../../../../utils';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniAuthService } from '../../../uni-auth/shared/uni-auth.service';
import { UniNavFacade } from '../../../uni-nav/shared/uni-nav.facade';
import { Permission } from '../../../uni-auth/shared/uni-auth.model';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { map } from 'rxjs/operators';
import { FeatureFlagKeys } from '../../../uni-auth/shared/uni-feature-flags.model';
import { UniTopBarBalanceService } from '../../shared/uni-top-bar-balance.service';
import { TranslateService } from '@ngx-translate/core';
import { UniTextColor, UniTextWeight } from '../../../uni-text';
import moment from 'moment';

@Component({
  selector: 'uni-menu-content',
  templateUrl: './uni-menu-content.component.html',
  styleUrls: ['./uni-menu-content.component.scss']
})
export class UniMenuContentComponent extends AutoUnsubscribe implements OnInit {
  isProfileNavOpen = false;
  account = this.uniAuthFacade.user?.account?.name || '';
  uniTextWeight = UniTextWeight;
  uniTextColor = UniTextColor;
  isSwitchAccountVisible =
    this.uniAuthFacade.user?.accessibleCompaniesCount > 1;
  isActivePaymentMenu$: Observable<boolean> = this.uniFeatureFlagsService
    .getPermission$(FeatureFlagKeys.payment_module_wrapper_2022_q2);
  isTroubleshootMenuVisible$: Observable<boolean> = this.uniFeatureFlagsService
    .getPermission$(FeatureFlagKeys.troubleshooting_permission_2024_q_3)
    .pipe(map(hasPermission =>
      hasPermission &&
      this.uniAuthFacade.hasPermission('UC.TROUBLESHOOTING_MODE', Permission.update) &&
      !this.uniAuthFacade.isImpresonationSession()
    ));
  isAccountSwitchEnabled$: Observable<boolean> = this.uniFeatureFlagsService
    .getPermission$(FeatureFlagKeys.organization_2024_q_3)
    .pipe(map(hasPermission =>
      hasPermission &&
      !this.uniAuthFacade.isAdminLevel()
    ));
  troubleshootModeCaption$: Observable<string> =
  this.uniAuthFacade.userMe$.pipe(
    map((userMe) => userMe?.user?.account),
    map((account) => {
      if (!account?.troubleshootingEnabled) {
        return '';
      }

      const today = moment();
      const troubleshootingEnd = account?.troubleshootingEndsAt;
      const diff = today.diff(troubleshootingEnd);
      const unit = Math.abs(diff) >= 24 * 60 * 60 * 1000 ? 'day' : 'hour';

      const value = Math.round(
        Math.abs(today.diff(troubleshootingEnd, `${unit}s` as any, true))
      );

      return this.translateService.instant(
        `troubleshooting.label.${
          value === 1
            ? `activeFor${unit.charAt(0).toUpperCase() + unit.slice(1)}`
            : `activeFor${unit.charAt(0).toUpperCase() + unit.slice(1)}s`
        }`,
        { number: value }
      );
    })
  );

  @HostBinding('class.is-open')
  get getIsOpenClass(): boolean {
    return this.isProfileNavOpen;
  }

  get isImpresonationSession(): boolean {
    return this.uniAuthFacade.isImpresonationSession();
  }

  constructor(
    protected uniAuthService: UniAuthService,
    protected uniAuthFacade: UniAuthFacade,
    protected uniNavFacade: UniNavFacade,
    protected productsService: ProductsService,
    private uniFeatureFlagsService: UniFeatureFlagsService,
    private uniTopBarBalanceService: UniTopBarBalanceService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.selectProfileNavtoggle(),
    );
  }

  selectProfileNavtoggle(): Subscription {
    return this.uniNavFacade.isProfileNavOpen$.subscribe(state => {
      this.isProfileNavOpen = state;
    });
  }

  onStopImpersonation(): void {
    this.uniTopBarBalanceService.killInterval();
    this.productsService.stopImpersonation();
  }

  onProfile(): void {
    this.productsService.navigate(ProductKey.UC, '/profile');
  }

  logout(): void {
    this.uniAuthService.logout();
  }

  onPayment(): void {
    this.productsService.navigate(ProductKey.UC, '/payment');
  }

  onSwitchAccount(): void {
    // @TODO: Change it, when account switcher view ready
    this.productsService.navigate(ProductKey.UC, `?select_account_url=${window.location.href}`);
  }

  onTroubleshoot(): void {
    this.uniNavFacade.openTroubleshootConfirmation();
  }
}
