import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { select, Store } from '@ngrx/store';

import * as selectors from '../store/flows.selectors';
import * as flowsActions from '../store/flows.actions';
import { FlowsState } from '@flows/store/flows.state';
import { CreateFlow, FlowJumptoStorageModel, UpdateFlowDTO } from '@flows/shared/flows.model';

@Injectable({
  providedIn: 'root',
})
export class FlowsFacade {
  userFlows$ = this.store.pipe(select(selectors.selectUserFlows));
  flowVersions$ = this.store.pipe(select(selectors.selectFlowVersions));
  activeFlowVersions$ = this.store.pipe(select(selectors.selectActiveFlowVersions));
  flow$ = this.store.pipe(select(selectors.selectFlow));
  widgetVisibility$ = this.store.pipe(select(selectors.selectFlowWidgetVisibility));
  jumptoSelection$ = this.store.pipe(select(selectors.selectJumptoSelection));
  chooseTriggerVisibility$ = this.store.pipe(select(selectors.selectChooseTriggerVisibility));
  sourceIdsForNewWidget$ = this.store.pipe(select(selectors.selectSourceIdsForNewWidget));

  constructor(private store: Store<FlowsState>) {}

  deleteFlow(flowId: string, accountId: string, flowVersion: number, params: Params): void {
    this.store.dispatch(new flowsActions.DeleteFlow(flowId, accountId, flowVersion, params));
  }

  setUserFlows(id: string, params: Params): void {
    this.store.dispatch(new flowsActions.SetUserFlows(id, params));
  }

  setFlowVersions(accountId: string, flowId: string, params: Params): void {
    this.store.dispatch(new flowsActions.SetUserFlowVersions(accountId, flowId, params));
  }

  setFlow(id: string, params: Params): void {
    this.store.dispatch(new flowsActions.SetFlow(id, params));
  }

  saveFlow(accountId: string, flow: CreateFlow, params: Params): void {
    this.store.dispatch(new flowsActions.SaveFlow(accountId, flow, params));
  }

  updateFlow(accountId: string, flow: UpdateFlowDTO): void {
    this.store.dispatch(new flowsActions.UpdateFlow(accountId, flow));
  }

  updateFlowName(accountId: string, flow: UpdateFlowDTO): void {
    this.store.dispatch(new flowsActions.UpdateFlowName(accountId, flow));
  }

  destroyFlow(): void {
    this.store.dispatch(new flowsActions.DestroyFlow());
  }

  setActiveFlowVersions(accountId: string, status: string, params: Params): void {
    this.store.dispatch(new flowsActions.SetActiveFlowVersions(accountId, status, params));
  }

  deployFlow(flowId: string, accountId: string, flowVersion: number, params: Params): void {
    this.store.dispatch(new flowsActions.DeployFlow(flowId, accountId, flowVersion, params));
  }

  executeFlow(flowId: string, requestParams: object): void {
    this.store.dispatch(new flowsActions.ExecuteFlow(flowId, requestParams));
  }

  setWidgetParameterVisibility(visibility: boolean): void {
    this.store.dispatch(new flowsActions.ToggleWidgetParameterVisibility(visibility));
  }

  setJumptoSelection(request: FlowJumptoStorageModel): void {
    this.store.dispatch(new flowsActions.ToggleJumptoSelection(request));
  }

  undeployFlow(flowId: string, accountId: string, flowVersion: number, params: Params): void {
    this.store.dispatch(new flowsActions.UndeployFlow(flowId, accountId, flowVersion, params));
  }

  validateScript(script: string, inputData: any): void {
    this.store.dispatch(new flowsActions.ValidateScript(script, inputData));
  }

  setChooseTriggerVisibility(visibility: boolean): void {
    this.store.dispatch(new flowsActions.ToggleChooseTriggerVisibility(visibility));
  }

  clearCache(): void {
    this.store.dispatch(new flowsActions.ClearCache());
  }

  setSourceIdsForNewWidget(sourceIds?: string[]): void {
    this.store.dispatch(new flowsActions.SetSourceIdsForNewWidget(sourceIds));
  }
}
