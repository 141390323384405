import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-box-tab',
  templateUrl: './uni-box-tab.component.html',
  styleUrls: ['./uni-box-tab.component.scss'],
})
export class UniBoxTabComponent {
  @HostBinding('class.is-active') @Input() isActive = false;
  @HostBinding('class.is-disabled') @Input() isDisabled = false;

  constructor(public elementRef: ElementRef) {}
}
