import { Params } from '@angular/router';
import { Action } from '@ngrx/store';
import { Workspace } from '../shared/login.model';

export const SET_WORKSPACES = '[Login] SET_WORKSPACES';
export const SET_WORKSPACES_SUCCESS = '[Login] SET_WORKSPACES_SUCCESS';

export const PUT_SELECTED_ACCOUNT = '[Login] PUT_SELECTED_ACCOUNT';
export const PUT_SELECTED_ACCOUNT_SUCCESS =
  '[Login] PUT_SELECTED_ACCOUNT_SUCCESS';

export class SetWorkspaces implements Action {
  readonly type = SET_WORKSPACES;

  constructor(public id: string, public params?: Params) {}
}

export class SetWorkspacesSuccess implements Action {
  readonly type = SET_WORKSPACES_SUCCESS;

  constructor(public workspaces: Workspace[], public totalItems: number) {}
}

export class PutSelectedAccount implements Action {
  readonly type = PUT_SELECTED_ACCOUNT;

  constructor(public userId: string, public accountId: string) {}
}

export class PutSelectedAccountSuccess implements Action {
  readonly type = PUT_SELECTED_ACCOUNT_SUCCESS;
}

export type All =
  | SetWorkspaces
  | SetWorkspacesSuccess
  | PutSelectedAccount
  | PutSelectedAccountSuccess;
