
import {FlowAuthenticationModel} from '@flows/shared/webhookTrigger/webhook-trigger.model';

export const WEBHOOK_TRIGGER_KEY = 'webhookTrigger';
export interface WebhookTriggersState {
  flowAuthenticationModel: FlowAuthenticationModel;
}

export const initialWebhookTriggerState: WebhookTriggersState = {
  flowAuthenticationModel: {}
};
