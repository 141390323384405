import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { NOTIFICATION_KEY } from '@flows/store/notification/notification.state';
import { NotificationApplicationModel } from '@flows/shared/notification/notification.model';
import { SettingsService } from '@shared/settings.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationRepository {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  getNotificationApplications(): Observable<HttpResponse<NotificationApplicationModel[]>> {
    return this.http.get<NotificationApplicationModel[]>(`${this.settingsService.apiUrl}/api/v1/proxy/push/applications`, {
      observe: 'response',
      headers: {
        queryName: 'getNotificationApplications',
        queryGroups: [NOTIFICATION_KEY]
      }
    });
  }
}
