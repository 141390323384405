<uni-loader *ngIf="isLoaderActive"></uni-loader>

<uni-section>
  <div *ngIf="templatesList.length" class="x3-container">
    <div *ngFor="let template of templatesList" class="flow-template border rounded mt-4">
      <div class="flow-template-head">
        <uni-text-subheader [isUppercase]="false">{{template.name}}</uni-text-subheader>
        <div class="flow-template-aciton-container">
          <uni-tag type="${UniTagType.active}">{{template.templateStatus}}</uni-tag>
        </div>
      </div>
      <div class="flow-template-body">
        <uni-text-info>{{template.description}}</uni-text-info>
      </div>

      <uni-link [path]="'studio?type=template&templateId='+template.id+''">
        Edit
      </uni-link>
      <uni-link (click)="delete(template.id)">
         | Delete
      </uni-link>
      <uni-link *ngIf="template.templateStatus == 'PUBLISH'" [path]="'studio?type=template&useTemplateForFlow=true&templateId='+template.id+''">
         | Use template
      </uni-link>
    </div>

  </div>
</uni-section>

<uni-section>
  <div *ngIf="!templatesList.length" class="col-lg-12 col-md-12 col-sm-12 pt-4 rounded text-center no-flow">
    <div class="pt-4">
      <uni-text-caption [color]="uniTextColor.darkLight"
                        [isUppercase]="false">{{ 'label.noFlowTemplate' | translate }}</uni-text-caption>
    </div>
    <div class="pt-4">
      <img alt="No Flows" src="assets/img/no-flows.png">
    </div>
  </div>
</uni-section>
