import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UniFooterModule } from '../uni-footer';
import { UniTopBarModule } from '../uni-top-bar';

import {
  UniNavComponent,
  UniNavGroupComponent,
  UniNavGroupExpandItemComponent,
  UniNavItemComponent,
  UniNavItemContainerComponent,
  UniNavItemGroupComponent,
  UniNavSubitemComponent,
  UniNavToggleComponent,
  UniNavItemToggleComponent,
  UniNavItemIconComponent,
  UniNavItemContentComponent,
} from './components';
import { UniIconModule } from '../uni-icon';
import { UniLoginModule } from '../uni-login/uni-login.module';
import { UniMenuModule } from '../uni-menu';


const components = [
  UniNavComponent,
  UniNavGroupComponent,
  UniNavItemComponent,
  UniNavItemGroupComponent,
  UniNavSubitemComponent,
  UniNavToggleComponent,
  UniNavGroupExpandItemComponent,
  UniNavItemContainerComponent,
  UniNavItemToggleComponent,
  UniNavItemIconComponent,
  UniNavItemContentComponent
];

@NgModule({
  imports: [
    UniTopBarModule,
    UniFooterModule,
    UniMenuModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    MatTooltipModule,
    UniIconModule,
    UniLoginModule
  ],
  declarations: [...components],
  exports: [
      UniNavComponent,
  ],
})
export class UniNavNewModule { }
