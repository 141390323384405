<uni-nav *ngIf="displayNavigation">
  <uni-nav-header>
    <uni-nav-name>
      {{ 'sidebar.flowStudio.title' | translate }}
    </uni-nav-name>
    <uni-nav-back *ngIf="hasBack" (click)="goBack()"></uni-nav-back>
  </uni-nav-header>

  <uni-nav-category *ngIf="navigationList" categoryName="sidebar.flowStudio.categoryName">
    <ng-container *ngFor="let navigation of navigationList">
      <a *appCheckPermissions="navigation.permission, feature: navigation.feature" routerLink="{{navigation.path}}">
        <uni-nav-item routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [isSidebar]="true">
          <uni-nav-item-icon>
            <uni-icon name="{{navigation.icon}}"></uni-icon>
          </uni-nav-item-icon>
          <uni-nav-item-name>
            {{ navigation.name | translate }}
          </uni-nav-item-name>
        </uni-nav-item>
      </a>
    </ng-container>
  </uni-nav-category>
</uni-nav>
