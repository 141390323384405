import * as VoiceActions from './voice.actions';
import {
  VoiceState,
  initialVoiceState,
} from './voice.state';

export function voiceReducer(
  state = initialVoiceState,
  action: VoiceActions.All,
): VoiceState {
  switch (action.type) {
    case VoiceActions.SET_VOICE_APPLICATIONS_SUCCESS: {
      return {
        ...state,
        voiceApplicationModel: action.response,
      };
    }
    case VoiceActions.SET_VOICE_AUDIO_URLS_SUCCESS: {
      return {
        ...state,
        voiceAudioModel: action.response,
      };
    }
    case VoiceActions.SET_INBOUND_CALLER_IDS_SUCCESS: {
      return {
        ...state,
        primaryCaller: action.response,
      };
    }

    default: {
      return {...state};
    }
  }
}
