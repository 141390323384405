import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LoginState } from '../store/login.state';
import {
  selectWorkspaces,
  selectWorkspacesAmount,
} from '../store/login.selectors';
import { Params } from '@angular/router';
import * as loginActions from '../store/login.actions';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoginFacade {
  private isSelectAccountOpen = new BehaviorSubject<boolean>(false);

  workspaces$ = this.store.pipe(select(selectWorkspaces));
  workspacesAmount$ = this.store.pipe(select(selectWorkspacesAmount));
  isSelectAccountOpen$ = this.isSelectAccountOpen.asObservable();

  constructor(private store: Store<LoginState>) {}

  setWorkspaces(id: string, params?: Params): void {
    this.store.dispatch(new loginActions.SetWorkspaces(id, params));
  }

  putSelectedAccount(userId: string, accountId: string): void {
    this.store.dispatch(new loginActions.PutSelectedAccount(userId, accountId));
  }
}
