import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateListTableComponent } from './template-list-table/template-list-table.component';
import {TranslateModule} from '@ngx-translate/core';
import {UniSharedModule} from '@unifonic/common';



@NgModule({
    declarations: [
        TemplateListTableComponent
    ],
    exports: [
        TemplateListTableComponent
    ],
  imports: [
    CommonModule,
    TranslateModule,
    UniSharedModule
  ]
})
export class FlowTemplatesModule { }
