import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import FlowService from "./flow-service";
import {FlowModel} from "../models/flow.model";
import {UniLayoutFacade} from '@unifonic/common';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute, Router} from "@angular/router";


@Component({
    selector: 'app-flow-list',
    templateUrl: './flow-list.component.html',
    styleUrls: ['./../../assets/css/base.scss', 'flow-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*', display: 'flex'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
})
export class FlowListComponent implements OnInit {
    accountId: string = localStorage.getItem('user_me') ? JSON.parse(localStorage.getItem('user_me')).user.id : "b6a1fd4e-5032-4e75-b8fd-84c7d2a0519e";
    activeTab = localStorage.getItem('activeTab') ?  localStorage.getItem('activeTab') : 'ALL';
    topbar;
    isModalActive: boolean = false;
    isLoaderActive = false;
    elementForDelete: any;
    isTopbar: boolean = true;
    displayedColumns = [
        'name',
        'version',
        'created',
        'last_used',
        'trigger',
        'status',
        'actions',
    ];
    dataSource: FlowModel[];
    expandedElement: FlowModel | null;
    versionedFlows: FlowModel[] = [];
    totalItemsCount = 0;
    itemsPerPage = 10;
    page = 0;

    constructor(private flowService: FlowService, private router: Router, private route: ActivatedRoute) {
        this.route.queryParams.subscribe(params => {
            if(params['page'] !== undefined && params['page'] > 0) {
                this.page = params['page'] - 1;
            }
        });
    }

    ngOnInit(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        }
        this.isLoaderActive = true;
        this.initTable();
        this.isLoaderActive = false;
    }

    public deleteConfirm(element: any) {
        this.isModalActive = true;
        this.elementForDelete = element;
    }

    public onReset() {
        this.isModalActive = false;
    }

    public onConfirmReset() {
        this.isLoaderActive = true;
        this.flowService.delete(this.elementForDelete.id, this.accountId, this.elementForDelete.version)
            .subscribe(
            response => {
                this.initTable();
            },
            error => {
                this.isLoaderActive = false;
            },
            () => {
                this.isLoaderActive = false;
            }
        );

        this.isModalActive = false;
    }

    initTable(): void {
        this.isLoaderActive = true;
        this.flowService.getUserFlowsPaginated(this.accountId, this.page, this.activeTab)
            .subscribe((flowList) => {
                    if (flowList) {
                        this.dataSource = flowList.flows;
                        this.itemsPerPage = flowList.totalPages;
                        this.totalItemsCount = flowList.totalElements;
                    }
                },
                error => {
                    console.log("error happen while getting the flow list ", error);
                },
                () => {
                    this.isLoaderActive = false;
                }
            );
    }

    getVersionedData(flow: FlowModel) {
        this.isLoaderActive = true;

        if (this.expandedElement?.id === flow.id) {
            this.expandedElement = null;
            this.isLoaderActive = false;
            return;
        }

        this.flowService.getFlowVersions(this.accountId, flow, this.activeTab)
            .subscribe((flowList) => {
                console.log("response")
                    if (flowList) {
                        this.versionedFlows = flowList;
                        this.expandedElement = flow;
                    }
                },
                error => {
                    console.log("error happen while getting the flow list ", error);
                },
                () => {
                    console.log("final")
                    this.isLoaderActive = false;
                });
    }

    changeTab(activeTab: string) {
        this.activeTab = activeTab;
        localStorage.setItem('activeTab', activeTab);
        this.page = 0;
        this.initTable();
    }

  stopFlow(element) {

  }
}
