import { concat, of } from 'rxjs';
import { catchError, delay, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { uniSnackbarActions } from '@unifonic/common';
import * as FlowsActions from './flows.actions';
import { FlowsRepository } from '@flows/shared/flows.repository';

@Injectable()
export class FlowsEffects {
  @Effect() setUserFlows$ = this.actions$.pipe(
    ofType<FlowsActions.SetUserFlows>(FlowsActions.SET_USER_FLOWS),
    mergeMap(({ id, params }) =>
      this.flowsRepository.getUserFlows(id, params).pipe(mergeMap((response) => of(new FlowsActions.SetUserFlowsSuccess(response.body))))
    )
  );
  @Effect() deleteFlow$ = this.actions$.pipe(
    ofType<FlowsActions.DeleteFlow>(FlowsActions.DELETE_FLOW),
    mergeMap(({ flowId, accountId, flowVersion, params }) =>
      this.flowsRepository.deleteFlow(flowId, accountId, flowVersion).pipe(
        mergeMap(() =>
          concat(
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successDelete')),
            of(new FlowsActions.SetUserFlows(accountId, params))
          )
        ),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );
  @Effect() setUserFlowVersions$ = this.actions$.pipe(
    ofType<FlowsActions.SetUserFlowVersions>(FlowsActions.SET_USER_FLOW_VERSIONS),
    mergeMap(({ accountId, flowId, params }) =>
      this.flowsRepository
        .getFlowVersions(accountId, flowId, params)
        .pipe(
          mergeMap((response) =>
            of(new FlowsActions.SetUserFlowVersionsSuccess(response.body.sort((a, b) => (a.flowVersion > b.flowVersion ? -1 : 1))))
          )
        )
    )
  );
  @Effect() setFlow$ = this.actions$.pipe(
    ofType<FlowsActions.SetFlow>(FlowsActions.SET_FLOW),
    mergeMap(({ id, params }) =>
      this.flowsRepository.getFlow(id, params).pipe(
        mergeMap((response) => of(new FlowsActions.SetFlowSuccess(response.body))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.entityNotFound')))
      )
    )
  );
  @Effect() saveFlow$ = this.actions$.pipe(
    ofType<FlowsActions.SaveFlow>(FlowsActions.SAVE_FLOW),
    mergeMap(({ accountId, flow, params }) =>
      this.flowsRepository.saveFlow(accountId, flow).pipe(
        mergeMap((response) =>
          concat(of(new FlowsActions.SaveFlowSuccess(response)), of(new FlowsActions.SetUserFlows(accountId, params)))
        ),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.entityNotFound')))
      )
    )
  );
  @Effect() updateFlowName$ = this.actions$.pipe(
    ofType<FlowsActions.UpdateFlowName>(FlowsActions.UPDATE_FLOW_NAME),
    mergeMap(({ accountId, flow }) =>
      this.flowsRepository.updateFlowName(accountId, flow).pipe(
        mergeMap((response) =>
          concat(
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successUpdate')).pipe(
              delay(300),
              tap(() => window.location.reload())
            )
          )
        ),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.entityNotFound')))
      )
    )
  );
  @Effect() setFlowNull$ = this.actions$.pipe(
    ofType<FlowsActions.DestroyFlow>(FlowsActions.DESTROY_FLOW),
    mergeMap(() => of(new FlowsActions.SetFlowSuccess({})))
  );
  @Effect() setActiveFlowVersions$ = this.actions$.pipe(
    ofType<FlowsActions.SetActiveFlowVersions>(FlowsActions.SET_ACTIVE_FLOW_VERSIONS),
    mergeMap(({ accountId, status, params }) =>
      this.flowsRepository
        .getActiveFlowVersions(accountId, status, params)
        .pipe(mergeMap((response) => of(new FlowsActions.SetActiveFlowVersionsSuccess(response.body))))
    )
  );
  @Effect() deployFlow$ = this.actions$.pipe(
    ofType<FlowsActions.DeployFlow>(FlowsActions.DEPLOY_FLOW),
    mergeMap(({ flowId, accountId, flowVersion, params }) =>
      this.flowsRepository.deployFlow(flowId, flowVersion, accountId).pipe(
        mergeMap(() =>
          concat(
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successDeployed')),
            of(new FlowsActions.DestroyFlow()),
            of(new FlowsActions.SetFlow(accountId, params))
          )
        ),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );
  @Effect() executeFlow$ = this.actions$.pipe(
    ofType<FlowsActions.ExecuteFlow>(FlowsActions.EXECUTE_FLOW),
    mergeMap(({ flowId, requestParams }) =>
      this.flowsRepository.executeFlow(flowId, requestParams).pipe(
        mergeMap(() => concat(of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successExecute')))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );
  @Effect() toggleWidgetParameterVisibility$ = this.actions$.pipe(
    ofType<FlowsActions.ToggleWidgetParameterVisibility>(FlowsActions.TOGGLE_WIDGET_PARAMETER_VISIBILITY),
    mergeMap(({ visibility }) =>
      this.flowsRepository
        .toggleWidgetParameterVisibility(visibility)
        .pipe(mergeMap((response) => of(new FlowsActions.ToggleWidgetParameterVisibilitySuccess(response))))
    )
  );
  @Effect() toggleJumptoSelection$ = this.actions$.pipe(
    ofType<FlowsActions.ToggleJumptoSelection>(FlowsActions.TOGGLE_JUMPTO_SELECTION),
    mergeMap(({ request }) =>
      this.flowsRepository
        .toggleJumptoSelection(request)
        .pipe(mergeMap((response) => of(new FlowsActions.ToggleJumptoSelectionSuccess(response))))
    )
  );
  @Effect() undeployFlow$ = this.actions$.pipe(
    ofType<FlowsActions.UndeployFlow>(FlowsActions.UNDEPLOY_FLOW),
    mergeMap(({ flowId, accountId, flowVersion, params }) =>
      this.flowsRepository.undeployFlow(flowId, flowVersion, accountId).pipe(
        mergeMap(() =>
          concat(
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successUndeployed')),
            of(new FlowsActions.SetUserFlows(accountId, params))
          )
        ),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );
  @Effect() validateScript$ = this.actions$.pipe(
    ofType<FlowsActions.ValidateScript>(FlowsActions.VALIDATE_SCRIPT),
    mergeMap(({ script, inputData }) =>
      this.flowsRepository.validateScript(script, inputData).pipe(
        mergeMap(() => concat(of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.success')))),
        catchError((e) => of(new uniSnackbarActions.NewSnackbar('error', e.error.message)))
      )
    )
  );
  @Effect() clearCache$ = this.actions$.pipe(
    ofType<FlowsActions.ClearCache>(FlowsActions.CLEAR_CACHE),
    mergeMap(({}) =>
      this.flowsRepository.clearCache().pipe(
        mergeMap(() => concat(of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.success')))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  constructor(private actions$: Actions, private flowsRepository: FlowsRepository) {}
}
