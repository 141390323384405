import { SmsAppIdModel, SmsApplicationModel, SmsSenderModel } from '@flows/shared/sms/sms.model';
import { Action } from '@ngrx/store';

export const SET_SMS_APPLICATIONS = '[Sms] SET_SMS_APPLICATIONS';
export const SET_SMS_APPLICATIONS_SUCCESS = '[Sms] SET_SMS_APPLICATIONS_SUCCESS';

export const SET_SMS_APP_IDS = '[Sms] SET_SMS_APP_IDS';
export const SET_SMS_APP_IDS_SUCCESS = '[Sms] SET_SMS_APP_IDS_SUCCESS';

export const SET_SMS_SENDERS = '[Sms] SET_SMS_SENDERS';
export const SET_SMS_SENDERS_SUCCESS = '[Sms] SET_SMS_SENDERS_SUCCESS';

export class SetSmsApplications implements Action {
  readonly type = SET_SMS_APPLICATIONS;

  constructor() {}
}

export class SetSmsApplicationsSuccess implements Action {
  readonly type = SET_SMS_APPLICATIONS_SUCCESS;

  constructor(public response: SmsApplicationModel[]) {}
}

export class SetSmsAppIds implements Action {
  readonly type = SET_SMS_APP_IDS;

  constructor() {}
}

export class SetSmsAppIdsSuccess implements Action {
  readonly type = SET_SMS_APP_IDS_SUCCESS;

  constructor(public response: SmsAppIdModel[]) {}
}

export class SetSmsSenders implements Action {
  readonly type = SET_SMS_SENDERS;

  constructor() {}
}

export class SetSmsSendersSuccess implements Action {
  readonly type = SET_SMS_SENDERS_SUCCESS;

  constructor(public response: SmsSenderModel[]) {}
}

export type All =
  | SetSmsApplications
  | SetSmsApplicationsSuccess
  | SetSmsAppIds
  | SetSmsAppIdsSuccess
  | SetSmsSenders
  | SetSmsSendersSuccess;
