import { Injectable } from '@angular/core';
import {
  MixpanelEvent,
  MixpanelEventName,
  MixpanelEventTriggerElement,
  MixpanelEventTriggerLabel,
  MixpanelEventType,
  UniAnalyticsService,
} from '@unifonic/common';

enum FSMixpanelEventName {
  WidgetClick = 'Widget clicked',
}

enum FSMixpanelEventTriggerLabel {}

enum FSMixpanelEventTriggerElement {
  WidgetName = 'widget_name',
}

interface FSMixpanelEventTrigger {
  name: FSMixpanelEventTriggerLabel | MixpanelEventTriggerLabel | string;
  type: FSMixpanelEventTriggerElement | MixpanelEventTriggerElement;
}

interface FSMixpanelEvent {
  name: FSMixpanelEventName | MixpanelEventName;
  type: MixpanelEventType;
  trigger: FSMixpanelEventTrigger;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private uniAnalyticsService: UniAnalyticsService) {}

  trackClickOnWidget(widget: string) {
    this.track({
      name: FSMixpanelEventName.WidgetClick,
      type: MixpanelEventType.click,
      trigger: {
        name: widget,
        type: FSMixpanelEventTriggerElement.WidgetName,
      },
    });
  }

  trackClickCreateFlow() {
    this.track({
      name: MixpanelEventName.clickCreateFlow,
      type: MixpanelEventType.click,
      trigger: {
        name: MixpanelEventTriggerLabel.createFlow,
        type: MixpanelEventTriggerElement.button,
      },
    });
  }

  private track(event: FSMixpanelEvent) {
    this.uniAnalyticsService.track(event as MixpanelEvent);
  }
}
