import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import {
  UniTableSelection,
  UniAuthFacade,
  Permission
} from '@unifonic/common';
import { Subject } from 'rxjs';
import { Template } from '@templates/shared/templates.model';
import { TemplatesFacade } from '@templates/shared/templates.facade';
import { ActivatedRoute, Params } from '@angular/router';
import {Features} from '@shared/features';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fs-template-list-items',
  templateUrl: './template-list-items.component.html',
  styleUrls: ['./template-list-items.component.scss']
})
export class TemplateListItemsComponent extends UniTableSelection<Template> implements OnInit {

  @Input() params: Params = {};
  @Input() activeTab: Params;
  @Input() showPagination: Params;
  templatesList: Template[];
  isModalActive = false;
  isLoaderActive = true;
  unsubscribe$ = new Subject();
  totalItems = 0;
  itemsPerPage = 12;
  templateId: string;
  accountId: string;
  features = Features;
  permissions = Permission;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private templatesFacade: TemplatesFacade,
    private activatedRoute: ActivatedRoute,
    private uniAuthFacade: UniAuthFacade
  ) {
    super();
  }

  ngOnInit() {
    this.initItem();
    this.accountId = this.uniAuthFacade.user.id;
  }

  initItem(): void {
    this.templatesFacade.userTemplates$
      .subscribe(userTemplates => {
        if (userTemplates) {
          this.templatesList = userTemplates.content;
          if (userTemplates.totalElements !== undefined) {
            this.totalItems = userTemplates.totalElements;
            this.isLoaderActive = false;
          }
          this.changeDetector.detectChanges();
        }
      });
  }

  onRemove() {
    this.isLoaderActive = true;
    this.templatesFacade.deleteTemplate(this.templateId, this.accountId, this.params);
  }

  getParams(): Params {
    return { queryParams: this.activatedRoute.snapshot.queryParams };
  }

  openConfirmModal(id: string) {
    this.templateId = id;
    this.isModalActive = true;
  }

  toggleModal() {
    this.isModalActive = !this.isModalActive;
  }
}
