import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserFlowModel } from '../models/user-flow.model';
import { FlowModel } from '../models/flow.model';
import { SettingsService } from '@shared/settings.service';

@Injectable({
  providedIn: 'root'
})
export default class FlowService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  delete(flowId: string, accountId: string, flowVersion: number) {
    const url = this.settingsService.apiUrl
      + '/api/v1/flow-studio/accounts/' + accountId + '/flows/' + flowId + '?flowVersion=' + flowVersion;
    return this.httpClient.delete(url, {
      withCredentials: true
    });
  }

  getUserFlows(accountId: string) {
    const url = this.settingsService.apiUrl + '/api/v1/flow-studio/accounts/' + accountId + '/flows';
    return this.httpClient.get<UserFlowModel>(url, {
      withCredentials: true
    }).pipe(
      map(
        data => {
          const flows = [];
          for (const flow of data.flows) {

            let lastExecution;
            let status;
            let version;
            if (flow.flowVersions.length > 0) {
              const flowVersions = flow.flowVersions[0];
              status = flowVersions.flowStatus;
              lastExecution = flowVersions.lastExecutionTime;
              version = flowVersions.flowVersion;
            }

            const flowObj = new FlowModel(
              flow.id,
              flow.name,
              flow.description,
              flow.businessKey,
              version,
              flow.createdAt,
              lastExecution,
              flow.trigger,
              flow.channel,
              status
            );
            flows.push(flowObj);
          }

          return new UserFlowModel(
            data.id,
            data.accountId,
            data.createdAt,
            data.modifiedAt,
            flows
          );
        }
      )
    );
  }

  getFlowVersions(accountId: string, flow: FlowModel, status: string) {
    const url = `${this.settingsService.apiUrl}/api/v1/flow-studio/accounts/${accountId}/flows/${flow.id}/list?flowStatus=${status}`;
    return this.httpClient.get<FlowModel[]>(url, {
      withCredentials: true
    }).pipe(
      map((data: any) => {
        const flowList: FlowModel[] = [];
        for (const flowVersionDetail of data) {
          const flowData = new FlowModel(
            flowVersionDetail.id,
            flow.name,
            null,
            null,
            flowVersionDetail.flowVersion,
            flowVersionDetail.createdAt,
            flowVersionDetail.lastExecutionTime,
            null,
            null,
            flowVersionDetail.flowStatus
          );
          flowList.push(flowData);
        }
        return flowList;
      })
    );
  }

  getUserFlowsPaginated(accountId: string, page: number, status: string) {
    const url = `${this.settingsService.apiUrl}/api/v1/flow-studio/accounts/${accountId}/flows/list?limit=10&page=${page}&flowStatus=${status}`;
    return this.httpClient.get<UserFlowModel>(url, {
      withCredentials: true
    }).pipe(
      map(
        data => {
          const flows = [];
          // for (const flow of data['content']) {
          //   let parentFlowVersion = null;
          //   if (flow['flowVersions'].length > 0) {
          //     for (const flowVersion of flow['flowVersions']) {
          //       if (flowVersion['flowStatus'] === 'ACTIVE') {
          //         parentFlowVersion = flowVersion;
          //         break;
          //       }
          //     }
          //   }
          //   if (parentFlowVersion == null) {
          //     parentFlowVersion = flow['flowVersions'][0];
          //   }
          //
          //   const flowObj = new FlowModel(
          //     flow['id'],
          //     flow['name'],
          //     flow['description'],
          //     flow['businessKey'],
          //     parentFlowVersion['flowVersion'],
          //     flow['createdAt'],
          //     parentFlowVersion['lastExecutionTime'],
          //     flow['triggerType'],
          //     flow['channel'],
          //     parentFlowVersion['flowStatus']
          //   );
          //   flows.push(flowObj);
          // }

          return new UserFlowModel(
            null,
            null,
            null,
            null,
            flows,
            data.totalElements,
            (data as any).size
          );
        }
      )
    );
  }
}
