import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AutoUnsubscribe, HttpQueryFacade } from '@unifonic/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FlowsFacade } from '@flows/shared/flows.facade';
import FlowsListService from './flows-list-service';
import { FLOWS_KEY } from '@flows/store';
import { AnalyticsService } from '@shared/analytics.service';

@Component({
  selector: 'fs-flows-list',
  templateUrl: './flows-list.component.html',
  providers: [FlowsListService],
})
export class FlowsListComponent extends AutoUnsubscribe implements OnInit {
  accountId: string;
  queryParams: Params;
  activeTab = 'ALL';
  totalItems = 0;

  public isLoaderActive$ = this.httpQueryFacade.isInProgress$(FLOWS_KEY);

  constructor(
    private changeDetector: ChangeDetectorRef,
    private flowsFacade: FlowsFacade,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpQueryFacade: HttpQueryFacade,
    private flowsListService: FlowsListService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit() {
    this.flowsListService.init(10, this.activeTab, null, null);
    localStorage.setItem('activeTemplateTab', 'ALL');
    this.subs.sink = this.flowsFacade.userFlows$.subscribe((userFlows) => {
      this.totalItems = userFlows?.totalElements || 0;
      this.changeDetector.detectChanges();
    });
    this.subs.sink = this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
  }

  onCreateFlow() {
    this.router.navigate(['/flows/flow-builder']);
    this.analyticsService.trackClickCreateFlow();
  }

  changeTab(activeTab: string) {
    this.activeTab = activeTab;
    localStorage.setItem('activeTemplateTab', activeTab);
    this.queryParams = {
      ...this.queryParams,
      page: 1,
      flowStatus: activeTab,
    };
    this.router.navigate([`/flows`], { queryParams: this.queryParams });
  }
}
