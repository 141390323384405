import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  FlowDetailModel,
  FlowModel,
  FlowResponseModel,
  FlowTemplate,
  FlowTemplateModel,
  FlowVersion
} from '../models/flow.model';
import { tap } from 'rxjs/operators';
import { FLOWS_KEY } from '@flows/store';
import { SettingsService } from '@shared/settings.service';

@Injectable({
  providedIn: 'root'
})
export default class FlowBuilderService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  get apiUrl() {
    return this.settingsService.apiUrl + '/api/v1/flow-studio';
  }

  public createFlow(data: FlowModel, accountId: string) {
    const url = `${this.apiUrl}/accounts/` + accountId + `/flows`;
    return this.httpClient.post(url, data, {withCredentials: true})
      .pipe(
        tap((data1: FlowResponseModel) => console.log('Create Flow request submitted!'))
      );
  }

  public createTemplate(data: FlowModel) {
    const url = this.apiUrl + '/templates';
    return this.httpClient.post(url, data, {withCredentials: true})
      .pipe(
        tap((data1: FlowResponseModel) => console.log('Create template request submitted!'))
      );
  }


  public createVersion(data: FlowModel, userFlowId: string, accountId: string) {
    const url = `${this.apiUrl}/accounts/` + accountId + `/flows/` + userFlowId;
    return this.httpClient.post(url, data, {withCredentials: true})
      .pipe(
        tap((data1: FlowResponseModel) => console.log('Create version'))
      );
  }


  public deployFlow(userFlowId: string, flowVersion: number, accountId: string) {
    const url = `${this.apiUrl}/accounts/` + accountId + `/flows/` + userFlowId + `/deploy?flowVersion=` + flowVersion;
    return this.httpClient
      .post(url, {}, {withCredentials: true})
      .pipe(
        tap((data: FlowResponseModel) => console.log('Create Flow request submitted!'))
      );
  }

  public getFlowById(flowId: string, flowVersion: number, accountId: string) {
    const url = `${this.apiUrl}/accounts/` + accountId + `/flows/` + flowId + `?flowVersion=` + flowVersion;
    return this.httpClient.get(url, {withCredentials: true}).pipe(
      tap((result: FlowDetailModel) => {
        console.log('Get flow detail by id request submitted!,', result);
      })
    );
  }


  public updateFlow(data: FlowModel, accountId: string) {
    const url = `${this.apiUrl}/accounts/` + accountId + `/flows`;
    return this.httpClient.patch(url, data, {withCredentials: true}).pipe(
      tap((result: any) => {
        console.log('Flow updated!,', result);
      })
    );
  }


  public testFlowExecution(deploymentId: string, data) {
    const url = `${this.apiUrl}/execute/` + deploymentId;
    return this.httpClient
      .post(url, data, {withCredentials: true})
      .pipe(
        tap((data1: FlowResponseModel) => console.log('Execute Flow request submitted!'))
      );
  }

  public getVoiceApplicationList() {
    const url = `https://run.mocky.io/v3/fda90cc8-d6a7-496c-9d8e-d1e3b424006c`;
    return this.httpClient.get(url, {
      withCredentials: true, headers: {
        queryName: 'undeployFlow',
        queryGroups: [FLOWS_KEY]
      }
    }).pipe(
      tap((result: any) => {
        // console.log("Voice result,", result);
      })
    );
  }

  public getActiveFlowVersions(status: string, accountId: string) {
    const url = `${this.apiUrl}/accounts/` + accountId + `/flows/status/` + status + `/list`;

    return this.httpClient.get(url, {
      withCredentials: true, headers: {
        queryName: 'undeployFlow',
        queryGroups: [FLOWS_KEY]
      }
    }).pipe(
      tap((result: FlowVersion[]) => {
        console.log('Get flow versions by status request submitted!,', result);
      })
    );
  }

  public getFlowTemplate(templateId: string) {
    const url = this.apiUrl + '/templates/' + templateId;

    return this.httpClient.get(url, {withCredentials: true}).pipe(
      tap((result: FlowTemplate) => {
        console.log('Get flow template request submitted!,', result);
      })
    );
  }

  public updateFlowTemplate(data: FlowTemplateModel) {
    const url = this.apiUrl + '/templates';
    return this.httpClient.patch(url, data, {withCredentials: true}).pipe(
      tap((result: any) => {
        console.log('Flow template request updated!,', result);
      })
    );
  }


  public getVoiceAudioList() {
    const url = `https://run.mocky.io/v3/fc19344f-c231-4c3b-afdb-151a7e34e954`;
    return this.httpClient.get(url, {
      withCredentials: true, headers: {
        queryName: 'undeployFlow',
        queryGroups: [FLOWS_KEY]
      }
    }).pipe(
      tap((result: any) => {
        // console.log("Voice result,", result);
      })
    );
  }
}
