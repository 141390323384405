import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Permission, UniLayoutFacade } from '@unifonic/common';
import TemplateListService
  from './../+templates/modules/templates-list/containers/templates-list/template-list-service';
import FlowsListService from './../+flows/modules/flows-list/containers/flows-list/flows-list-service';
import { Features } from '@shared/features';
import { Subject } from 'rxjs';
import { SettingsService } from '@shared/settings.service';

@Component({
  selector: 'fs-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./../../assets/css/base.scss', './dashboard.component.scss'],
  providers: [FlowsListService, TemplateListService]
})
export class DashboardComponent implements OnInit, OnDestroy {
  features = Features;
  permissions = Permission;
  unsubscribe$ = new Subject();

  constructor(
    private uniLayoutFacade: UniLayoutFacade,
    private templateListService: TemplateListService,
    private flowsListService: FlowsListService,
    public settingsService: SettingsService
  ) {
  }

  ngOnInit(): void {
    this.uniLayoutFacade.setIsLoader(false);
    this.templateListService.init(4, 'PUBLISH');
    this.flowsListService.init(5, 'ALL', null, null);
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
