import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ChannelReportResponse} from '../models/channel-report.model';
import {Observable} from 'rxjs';
import {SettingsService} from '@shared/settings.service';


@Injectable({
  providedIn: 'root'
})
export class ExecutionChannelReportingService {

  constructor(private httpClient: HttpClient, private settingsService: SettingsService) {
  }

  private static parseWidgetType(widgetType: string) {
    let formattedWidgetType = '';
    switch (widgetType) {
      case 'Voice' :
        formattedWidgetType = 'FS_VOICE';
        break;
      case 'WhatsApp':
        formattedWidgetType = 'FS_WHATSAPP';
        break;
      case 'SMS':
        formattedWidgetType = 'FS_SMS';
        break;
      case 'Email':
        formattedWidgetType = 'FS_EMAIL';
        break;
      case 'Notification':
        formattedWidgetType = 'FS_NOTIFICATION';
        break;
      default:
        break;
    }

    return formattedWidgetType;
  }

  getChannelReport(page: number, limit: number, flowName: string, widgetType: string, destination: string): Observable<ChannelReportResponse> {
    let url = `${this.settingsService.apiUrl}/api/v1/reporting/channels?page=${page}&limit=${limit}`;
    if (flowName) {
      url += `&flowName=${flowName}`;
    }

    if (widgetType) {
      widgetType = ExecutionChannelReportingService.parseWidgetType(widgetType);
      url += `&widgetType=${widgetType}`;
    }

    if (destination) {
      url += `&destination=${destination}`;
    }
    
    return this.httpClient.get<ChannelReportResponse>(url);
  }
}
