import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WEBHOOK_TRIGGER_KEY } from '@flows/store/webhook-trigger/webhook-trigger.state';
import { FlowAuthenticationModel } from '@flows/shared/webhookTrigger/webhook-trigger.model';
import { SettingsService } from '@shared/settings.service';

@Injectable({
  providedIn: 'root'
})
export class WebhookTriggerRepository {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  setFlowJwtAccessToken(): Observable<any> {
    return this.http.get<FlowAuthenticationModel>(
      `${this.settingsService.apiUrl}/api/v1/auth/generate-fs-api-key`,
      {
        observe: 'response',
        headers: {
          queryName: 'setFlowJwtAccessToken',
          queryGroups: [WEBHOOK_TRIGGER_KEY]
        }
      });
  }

  generateFsTokenUsingRefreshToken(data: FlowAuthenticationModel): Observable<any> {
    return this.http.post(
      `${this.settingsService.apiUrl}/api/v1/auth/generate-fs-token/refresh-token`,
      data
    );
  }

}
