import { Component, OnInit } from '@angular/core';
import {FlowModel} from "../../models/flow.model";
import FlowService from "../../flow-list/flow-service";
import {UniTextColor} from "../../../../unifonic-common/src";

@Component({
  selector: 'app-flow-list-table',
  templateUrl: './flow-list-table.component.html',
  styleUrls: ['./flow-list-table.component.scss']
})
export class FlowListTableComponent implements OnInit {

  displayedColumns = [
    'name',
    'version',
    'created',
    'last_used',
    'trigger',
    'status',
  ];
  dataSource: FlowModel[] = [];
  accountId = localStorage.getItem('accountId');
  uniTextColor = UniTextColor;


  constructor(private flowService: FlowService) { }

  ngOnInit(): void {
    this.initTable();
  }

  initTable(): void {
    this.flowService.getUserFlowsPaginated(this.accountId, 0, 'ALL')
      .subscribe((flowList) => {
          if (flowList) {
            this.dataSource = flowList.flows;
          }
        },
        error => {
          console.log("error happen while getting the flow list ", error);
        },
        () => {
        }
      );
  }

}
