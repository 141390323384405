import * as FlowsActions from './flows.actions';
import { FlowsState, initialState } from './flows.state';

export function flowsReducer(state = initialState, action: FlowsActions.All): FlowsState {
  switch (action.type) {
    case FlowsActions.SET_USER_FLOWS_SUCCESS: {
      return {
        ...state,
        userFlows: action.response,
      };
    }
    case FlowsActions.SET_USER_FLOW_VERSIONS_SUCCESS: {
      return {
        ...state,
        flowVersions: action.response,
      };
    }
    case FlowsActions.SET_FLOW_SUCCESS: {
      return {
        ...state,
        flow: action.response,
      };
    }
    case FlowsActions.SAVE_FLOW_SUCCESS: {
      return {
        ...state,
        flow: action.response,
      };
    }
    case FlowsActions.UPDATE_FLOW_SUCCESS: {
      return {
        ...state,
        flow: action.response,
      };
    }
    case FlowsActions.UPDATE_FLOW_NAME_SUCCESS: {
      return {
        ...state,
        flow: action.response,
      };
    }
    case FlowsActions.DESTROY_FLOW_SUCCESS: {
      return {
        ...state,
        flow: {},
      };
    }
    case FlowsActions.SET_ACTIVE_FLOW_VERSIONS_SUCCESS: {
      return {
        ...state,
        activeFlowVersions: action.response,
      };
    }
    case FlowsActions.TOGGLE_WIDGET_PARAMETER_VISIBILITY_SUCCESS: {
      return {
        ...state,
        widgetVisibility: action.response,
      };
    }
    case FlowsActions.TOGGLE_JUMPTO_SELECTION_SUCCESS: {
      return {
        ...state,
        jumptoSelection: action.response,
      };
    }
    case FlowsActions.TOGGLE_CHOOSE_TRIGGER_VISIBILITY: {
      return {
        ...state,
        chooseTriggerVisibility: action.visibility,
      };
    }
    case FlowsActions.SET_SOURCE_IDS_FOR_NEW_WIDGET: {
      return {
        ...state,
        sourceIdsForNewWidget: action.sourceIds,
      };
    }
    default: {
      return { ...state };
    }
  }
}
