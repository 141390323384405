import { Subscription } from 'rxjs';

import { Component, HostBinding, OnInit } from '@angular/core';

import { ProductKey, ProductsService } from '../../../../shared';
import { AutoUnsubscribe } from '../../../../utils';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniAuthService } from '../../../uni-auth/shared/uni-auth.service';
import { UniNavFacade } from '../../../uni-nav/shared/uni-nav.facade';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { filter } from 'rxjs/operators';
import { FeatureFlagKeys } from '../../../uni-auth/shared/uni-feature-flags.model';
import { UniTopBarBalanceService } from '../../shared/uni-top-bar-balance.service';

@Component({
  selector: 'uni-menu-content',
  templateUrl: './uni-menu-content.component.html',
  styleUrls: ['./uni-menu-content.component.scss']
})
export class UniMenuContentComponent extends AutoUnsubscribe implements OnInit {
  isProfileNavOpen = false;
  isActivePaymentMenu = false;

  @HostBinding('class.is-open')
  get getIsOpenClass(): boolean {
    return this.isProfileNavOpen;
  }

  get isImpresonationSession(): boolean {
    return this.uniAuthFacade.isImpresonationSession();
  }

  constructor(
    protected uniAuthService: UniAuthService,
    protected uniAuthFacade: UniAuthFacade,
    protected uniNavFacade: UniNavFacade,
    protected productsService: ProductsService,
    private uniFeatureFlagsService: UniFeatureFlagsService,
    private uniTopBarBalanceService: UniTopBarBalanceService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.selectProfileNavtoggle(),
      this.checkFlags()
    );
  }

  selectProfileNavtoggle(): Subscription {
    return this.uniNavFacade.isProfileNavOpen$.subscribe(state => {
      this.isProfileNavOpen = state;
    });
  }

  onStopImpersonation(): void {
    this.uniTopBarBalanceService.killInterval();
    this.productsService.stopImpersonation();
  }

  onProfile(): void {
    this.productsService.navigate(ProductKey.UC, '/profile');
  }

  logout(): void {
    this.uniAuthService.logout();
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(filter(featureFlags => !!featureFlags))
      .subscribe(() => {
        this.isActivePaymentMenu = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.payment_module_wrapper_2022_q2);
      });
  }

  onPayment(): void {
    this.productsService.navigate(ProductKey.UC, '/payment');
  }

}
