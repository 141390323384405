import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UniTextColor } from '../../../uni-text';

@Component({
  selector: 'uni-menu-section',
  templateUrl: './uni-menu-section.component.html',
  styleUrls: ['./uni-menu-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UniMenuSectionComponent {
  @Input() header: string;
  headerColor = UniTextColor.secondary;
  headerLevel = '050';
  @HostBinding('class.is-wide') @Input() isWide = false;
}
