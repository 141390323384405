export enum MixpanelEventTriggerElement {
  button = 'Button',
};

export enum MixpanelEventTriggerLabel {
  login = 'Login',
  createAccount = 'Create Account',
  setPasswordNext = 'Set Password Next',
  resendLink = 'Resend Link',
  resendCode = 'Resend Code',
  createFlow = 'Create a Flow',
  createNewConnection = 'Create new connection',
  confirm = 'Confirm',
  requestApproval = 'Request approval'
};

export enum MixpanelEventName {
  view = 'Page View',
  signUp = 'Sign Up Flow',
  clickCreateFlow = 'Click Create a Flow',
  createIntegrationConnection = 'Creating Integration Connection',
  audioFileDeleted = 'Audio File Deleted',
  newCallerIDRequested = 'New Caller ID Requested',
  audioFileAdded = 'Audio File Added'
};

export enum MixpanelEventType {
  click = 'click',
  view = 'page_view',
};

export enum MixpanelPageViewName {
  addSubaccount =  'Add Subaccount',
  adminUsers =  'Admin Users',
  balanceNotifications =  'Balance Notifications',
  balanceTransfer =  'Balance Transfer',
  campaignsSMS = 'SMS Campaigns',
  campaignsSMSCreate = 'Create SMS Campaign',
  contacts =  'Contacts',
  conversationsReports =  'Conversations Reports',
  createContact =  'Create Contact',
  createDynamicGroup =  'Create Dynamic Group',
  createStaticGroup =  'Create Static Group',
  createUser =  'Create User',
  dashboardUC =  'UC Dashboard',
  dashboardCC =  'CC Dashboard',
  dynamicGroups =  'Dynamic Groups',
  editContact =  'Edit Contact',
  editSubaccount =  'Edit Subaccount',
  editUser =  'Edit User',
  forgotPassword =  'Forgot Password',
  groups =  'Groups',
  messageLogs = 'Message Logs',
  packages =  'Packages',
  pendingRequests =  'Pending Requests',
  profile =  'Profile',
  requestHistory =  'Request History',
  resetPassword =  'Reset Password',
  senderNameExpiring = 'Sender names expiring list',
  senderNameGeoPermissions =  'Sender name geo permissions',
  senderNameNew =  'New Sender name',
  senderNames =  'Sender names',
  signUp =  'Sign Up',
  staticGroups =  'Static Groups',
  subaccounts =  'Subaccounts',
  topUp =  'Top Up',
  trafficReport = 'Traffic Report',
  transferHistory =  'Transfer History',
  unitTransfer =  'Unit Transfer',
  users =  'Users',
  payments = 'Payments',
  security = 'Security',
  paymentHistory =  'Payment History',
  smsApplications = 'SMS Applications',
  newSmsApplication = 'New SMS Application',
  editSmsApplication = 'Edit SMS Application',
  voiceApplications = 'Voice Applications',
  newVoiceApplication = 'New Voice Application',
  editVoiceApplication = 'Edit Voice Application',
  pushApplications = 'Push Applications',
  newPushApplication = 'New Push Application',
  editPushApplication = 'Edit Push Application',
  apiKeys = 'API keys',
  requestNewCallerId = 'Request new Caller ID',
}

export interface MixpanelEventTrigger {
  name: MixpanelEventTriggerLabel;
  type: MixpanelEventTriggerElement;
};

export interface MixpanelEvent {
  name: MixpanelEventName;
  type: MixpanelEventType;
  trigger: MixpanelEventTrigger;
  props?: { [key: string]: any };
};

export interface MixpanelEvents {
  [key: string]: MixpanelEvent;
};

// Specific events props sets

export interface MixpanelProps {
  page_title?: string;
  step_name?: string;
  tab_title?: string;
  recipient_type?: string;
  batches?: boolean;
  batches_count?: number;
  call_retries_number?: number;
  content_type?: string;
  error_message?: string;
  file_size?: number;
  flow_type?: string;
  goals_count?: number;
  goals_types?: string;
  groups_count?: number;
  ivr_key_number?: number;
  message_category?: string;
  page_language?: string;
  recipients_count?: number;
  schedule?: boolean;
  segement_count?: number;
  trial_sender?: boolean;
  set_goal?: boolean;
  target_country?: string;
  template_count?: number;
  template_language?: string[];
  templates_type?: number;
  time_between_batches?: number;
  template_category?: string;
  template_subcategory?: string;
  language_count?: number;
  header_type?: string;
  button_name?: string[];
  button_count?: number;
  cards_count?: number;
  file_format?: string;
  message_units?: number;
  variable_source_type?: string[];
  trigger_type?: string;
  widget_name?: string;
  widget_number?: string;
  context?: string;
  sub_context?: string;
  action_type?: 'user' | 'system';
}

export type MixpanelEventPageView = Pick<
  MixpanelProps,
  | 'page_title'
  | 'page_language'
  | 'step_name'
  | 'tab_title'
>;
