import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { UniDirectivesModule } from '../../directives/directives.module';
import { UniIconModule } from '../uni-icon';
import { UniNavModule } from '../uni-nav/uni-nav.module';
import { UniMenuContentComponent } from './components/uni-menu-content/uni-menu-content.component';
import { UniProductsContentComponent } from './components/uni-products-content/uni-products-content.component';
import { UniTopBarAvatarComponent } from './components/uni-top-bar-avatar/uni-top-bar-avatar.component';
import {
  UniTopBarImpersonationComponent
} from './components/uni-top-bar-impersonation/uni-top-bar-impersonation.component';
import { UniTopBarLogoComponent } from './components/uni-top-bar-logo/uni-top-bar-logo.component';
import { UniTopBarMenuComponent } from './components/uni-top-bar-menu/uni-top-bar-menu.component';
import { UniTopBarProductsComponent } from './components/uni-top-bar-products/uni-top-bar-products.component';
import { UniTopBarSeparatorComponent } from './components/uni-top-bar-separator/uni-top-bar-separator.component';
import { UniTopBarComponent } from './components/uni-top-bar/uni-top-bar.component';
import { UniTopBarBalanceComponent } from './components/uni-top-bar-balance/uni-top-bar-balance.component';
import { UniTopBarTimezoneComponent } from './components/uni-top-bar-timezone/uni-top-bar-timezone.component';
import { UniTooltipModule } from '../uni-tooltip';
import { UniTopBarTopupComponent } from './components/uni-top-bar-topup/uni-top-bar-topup.component';
import { UniButtonModule } from '../uni-button/uni-button.module';

const components = [
  UniTopBarComponent,
  UniTopBarProductsComponent,
  UniTopBarMenuComponent,
  UniTopBarLogoComponent,
  UniTopBarAvatarComponent,
  UniProductsContentComponent,
  UniMenuContentComponent,
  UniTopBarSeparatorComponent,
  UniTopBarImpersonationComponent,
  UniTopBarBalanceComponent,
  UniTopBarTimezoneComponent,
  UniTopBarTopupComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    UniIconModule,
    UniNavModule,
    UniDirectivesModule,
    UniTooltipModule,
    UniButtonModule
  ],
  exports: [...components],
})
export class UniTopBarModule { }
