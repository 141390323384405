import * as WhatsappActions from './whatsapp.actions';
import {
  WhatsappState, initialWhatsappState
} from '@flows/store/whatsapp/whatsapp.state';


export function whatsappReducer(
  state = initialWhatsappState,
  action: WhatsappActions.All,
): WhatsappState {
  switch (action.type) {
    case '[Whatsapp] SET_WHATSAPP_APPLICATIONS_SUCCESS': {
      return {
        ...state,
        whatsappApplications: action.response
      };
    }
    case '[Whatsapp] SET_WHATSAPP_APPLICATION_TEMPLATES_SUCCESS': {
      return {
        ...state,
        whatsappApplicationTemplates: action.response
      };
    }
    case '[Whatsapp] SET_WHATSAPP_APPLICATION_KEY_SUCCESS': {
      return {
        ...state,
        whatsappApplicationKey: action.response
      };
    }
    default: {
      return {...state};
    }
  }
}
