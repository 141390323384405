import {Observable, of} from 'rxjs';

import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Permission, Settings, SettingsService, UniAuthFacade, UniLayoutFacade} from '@unifonic/common';
import {AuthService} from './auth.service';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {AuthFacade} from './auth.facade';
import {FlowsPermissionsService} from '@flows/shared/flows-permissions.service';
import {UniRouteService} from '@unifonic/common';

@Injectable()
export class AuthGuard implements CanActivate {
  settings: Settings = this.settingsService.settings;

  constructor(
    private authFacade: AuthFacade,
    private uniLayoutFacade: UniLayoutFacade,
    private authService: AuthService,
    private settingsService: SettingsService,
    private uniAuthFacade: UniAuthFacade,
    private modePermissions: FlowsPermissionsService,
    private uniRouteMode: UniRouteService
  ) {
  }

  canActivate(): boolean {
    this.uniLayoutFacade.setIsLoader(false);

    if (this.uniAuthFacade.isAdminLevel()) {
      return true;
    }

    if (!this.uniAuthFacade.hasPermission('FS.FLOWS', Permission.seeOwn)
      && !this.uniAuthFacade.hasPermission('FS.FLOWS', Permission.createOwn)
      && !this.uniAuthFacade.hasPermission('FS.FLOWS', Permission.editOwn)
      && !this.uniAuthFacade.hasPermission('FS.FLOWS', Permission.deleteOwn)
    ) {
      this.uniRouteMode.navigate('/activation-request');
      return false;
    }

    return true;
  }

}
