import * as SmsActions from './sms.actions';
import { SET_SMS_APP_IDS_SUCCESS, SET_SMS_APPLICATIONS_SUCCESS, SET_SMS_SENDERS_SUCCESS } from './sms.actions';
import { initialSmsState, SmsState } from '@flows/store/sms/sms.state';

export function smsReducer(state = initialSmsState, action: SmsActions.All): SmsState {
  switch (action.type) {
    case SET_SMS_APPLICATIONS_SUCCESS: {
      return {
        ...state,
        smsApplications: action.response,
      };
    }
    case SET_SMS_APP_IDS_SUCCESS: {
      return {
        ...state,
        smsAppIds: action.response,
      };
    }
    case SET_SMS_SENDERS_SUCCESS: {
      return {
        ...state,
        smsSenders: action.response,
      };
    }
    default: {
      return { ...state };
    }
  }
}
