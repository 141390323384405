import {concat, of} from 'rxjs';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {uniSnackbarActions} from '@unifonic/common';
import * as WebhookTriggersActions from './webhook-trigger.actions';
import {WebhookTriggerRepository} from '@flows/shared/webhookTrigger/webhook-trigger.repository';

@Injectable()
export class WebhookTriggerEffects {
  constructor(
    private actions$: Actions,
    private webhookTriggerRepository: WebhookTriggerRepository,
  ) {
  }


    @Effect() setFlowJwtAccessToken$ = this.actions$
    .pipe(
      ofType<WebhookTriggersActions.SetFlowJwtAccessToken>(WebhookTriggersActions.SET_FLOW_JWT_ACCESS_TOKEN),
      mergeMap(({}) => this.webhookTriggerRepository
        .setFlowJwtAccessToken()
        .pipe(
          mergeMap(response => of(new WebhookTriggersActions.SetFlowJwtAccessTokenSuccess(response.body))),
        )
      )
    );

    @Effect() generateFlowRefreshToken$ = this.actions$
    .pipe(
      ofType<WebhookTriggersActions.GenerateFlowRefreshToken>(WebhookTriggersActions.GENERATE_FLOW_REFRESH_TOKEN),
      mergeMap(({data}) => this.webhookTriggerRepository
        .generateFsTokenUsingRefreshToken(data)
        .pipe(
          mergeMap(response => of(new WebhookTriggersActions.GenerateFlowRefreshTokenSuccess(response.body))),
          catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault'))),
        )
      )
    );

}
