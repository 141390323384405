import { values } from 'lodash';

export namespace Environment {
  export enum APP {
    CONV = 'conversation',
    VOICE = 'voice',
    CC = 'cc',
    UC = 'uc',
    CONSOLE = 'console-api',
    PROD_CC = 'communication',
    AUDIENCE = 'customer-targeting-backend',
    ADMIN_LOGS = 'admin-logs-backend',
    FLOW_STUDIO_LOCAL = 'flow-studio',
    FLOW_STUDIO_PROD = 'flow',
    WEBHOOK = 'webhook',
    INTEGRATIONS = 'integrations',
    WEB = 'web',
    CDP = 'audience',
    CATALOG = 'catalog',
    CAMPAIGN = 'campaign',
    NOTICE = 'notice',
    PUSH = 'push',
    AUTHENTICATE = 'authenticate',
    ULINK = 'ulink',
    INSIGHTS = 'insights'
  }

  export function getMainHost(prefix?: string): string {
    const { hostname, protocol } = window.location;

    const parts = hostname.split('.');

    if (values(APP).includes(parts[0].replace(/^local/, '') as any)) {
      parts.shift();
    }

    return !!prefix
      ? `${protocol}//${prefix}.${parts.join('.')}`
      : `${protocol}//${parts.join('.')}`;
  }

  export function getProduct(): string {
    const { hostname } = window.location;
    const parts = hostname.split('.');

    if (values(APP).includes(parts[0] as any)) {
      return parts[0];
    }

    return APP.UC;
  }
}
