import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard as UniAuthGuard, UniPage403Component, UniPage404Component } from '@unifonic/common';

import { TranslateModule } from '@ngx-translate/core';
// components
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from './logout/logout.component';
import { FlowBuilderComponent } from './flow-builder/flow-builder.component';
import { FlowListComponent } from './flow-list/flow-list.component';
import { FlowExecutionComponent } from './flow-execution/flow-execution.component';
import { AppLoggedComponent } from './layout/containers/app-logged/app-logged.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { AuthGuard } from '@auth/shared/auth.guard';
import { ActivationRequestComponent } from './activation-request/activation-request.component';
import { ExecutionChannelReportingComponent } from './execution-channel-reporting/execution-channel-reporting.component';

const routesForLoggedUsers: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'activation-request',
    component: ActivationRequestComponent,
  },
  {
    path: 'studio',
    component: FlowBuilderComponent,
    data: { isSidebarHidden: true },
    canActivate: [AuthGuard],
  },
  {
    path: 'flow',
    component: FlowListComponent,
    data: { isSidebarHidden: true },
    canActivate: [AuthGuard],
  },
  {
    path: 'flows',
    loadChildren: () => import('./+flows/flows.module').then((mod) => mod.FlowsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'v2/templates',
    loadChildren: () => import('./+templates/templates.module').then((mod) => mod.TemplatesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'templates',
    component: TemplateListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    component: FlowExecutionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'channel-reports',
    component: ExecutionChannelReportingComponent,
    canActivate: [AuthGuard],
  },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AppLoggedComponent,
    canActivate: [UniAuthGuard],
    children: [
      {
        path: '',
        children: routesForLoggedUsers,
      },
    ],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '403',
    component: UniPage403Component,
  },
  {
    path: '404',
    component: UniPage404Component,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
