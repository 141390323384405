<uni-section [class]="'rounded'">
  <uni-table *ngIf="dataSource.length">
    <table [dataSource]="dataSource" cdk-table multiTemplateDataRows>

      <ng-container cdkColumnDef="name">
        <th *cdkHeaderCellDef cdk-header-cell>
          Name
        </th>
        <td *cdkCellDef="let element" cdk-cell>
          <div class="flow-column-content flow-name">{{ element.name }}</div>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="version">
        <th *cdkHeaderCellDef cdk-header-cell>
          Versions
        </th>
        <td *cdkCellDef="let element" cdk-cell>
          <div class="flow-column-content flow-version">
            {{ element.version }}
          </div>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="created">
        <th *cdkHeaderCellDef cdk-header-cell>
          Created
        </th>
        <td *cdkCellDef="let element" cdk-cell>
          <div class="flow-column-content">
            {{ element.createdAt | date: 'dd-MM-yyyy' }}
          </div>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="last_used">
        <th *cdkHeaderCellDef cdk-header-cell>
          Last Used
        </th>
        <td *cdkCellDef="let element" cdk-cell>
          <div class="flow-column-content">
            {{ element.lastUsed | date: 'dd-MM-yyyy HH:mm' }}
          </div>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="trigger">
        <th *cdkHeaderCellDef cdk-header-cell>
          Trigger
        </th>
        <td *cdkCellDef="let element" cdk-cell>
          <div class="flow-column-content">
            {{ element.trigger }}
          </div>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="status">
        <th *cdkHeaderCellDef cdk-header-cell>
          Status
        </th>
        <td *cdkCellDef="let element" cdk-cell>
             <span [ngClass]="{
                                  'btn-outline-primary':element.status === 'DRAFT',
                                  'btn-outline-warning':element.status === 'FINALISING',
                                  'btn-outline-success':element.status === 'ACTIVE'
                                }" class="btn ">
                                {{element.status}}</span>
        </td>
      </ng-container>
      <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
      <tr
        *cdkRowDef="let row; let element; columns: displayedColumns; let isOdd = odd; let isEven = even; let isLast = last"
        [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
        cdk-row
        class="example-element-row"></tr>
    </table>
  </uni-table>
</uni-section>

<uni-section>
  <div *ngIf="!dataSource.length" class="col-lg-12 col-md-12 col-sm-12 pt-4 rounded text-center no-flow">
    <div class="pt-4">
      <uni-text-caption [color]="uniTextColor.darkLight"
                        [isUppercase]="false">{{ 'label.noFlow' | translate}}</uni-text-caption>
    </div>
    <div class="pt-4">
      <img alt="No Flows" src="assets/img/no-flows.png">
    </div>
  </div>
</uni-section>
