<ng-container *ngIf="dataSource">
  <uni-box [isHidden]="false">
    <mat-table #table [dataSource]="dataSource">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Name Column -->
      <ng-container [matColumnDef]="columns.name">
        <mat-header-cell *matHeaderCellDef>
          <uni-table-filter-search [heading]="'table.' + columns.name | translate" controlName="flowName"></uni-table-filter-search>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.flowName }}</mat-cell>
      </ng-container>

      <!-- Trigger Column -->
      <ng-container [matColumnDef]="columns.trigger">
        <mat-header-cell *matHeaderCellDef>
          <uni-table-filter
            [data]="triggerTypes"
            [heading]="'table.' + columns.trigger | translate"
            controlName="triggerType"
          ></uni-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ getTriggerType(element.triggerType) }}</mat-cell>
      </ng-container>

      <!-- Created at Column -->
      <ng-container [matColumnDef]="columns.created">
        <mat-header-cell *matHeaderCellDef> {{ "table." + columns.created | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.createdAt | dateFormat }}</mat-cell>
      </ng-container>

      <!-- Version Column -->
      <ng-container [matColumnDef]="columns.version">
        <mat-header-cell *matHeaderCellDef> {{ "table." + columns.version | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.version }}</mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container [matColumnDef]="columns.status">
        <mat-header-cell *matHeaderCellDef> {{ "table." + columns.status | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            [ngClass]="{
              'draft-status': element.flowStatus === 'DRAFT',
              'finalising-status': element.flowStatus === 'FINALISING',
              'active-status': element.flowStatus === 'ACTIVE',
              'inactive-status': element.flowStatus === 'INACTIVE'
            }"
            class="badge border"
          >
            {{ element.flowStatus }}</span
          >
        </mat-cell>
      </ng-container>

      <!-- Action Column -->
      <ng-container [matColumnDef]="columns.actions">
        <mat-header-cell *matHeaderCellDef>{{ "label.action" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="ml-1 mr-3">
            <uni-link [path]="'flows/flow-builder?flowId=' + element.flowId + '&flowVersion=' + element.version + ''">
              <uni-icon name="edit"></uni-icon>
            </uni-link>
          </div>
          <div class="mr-3">
            <uni-icon
              (click)="getVersionedData(element)"
              [class.example-expanded-row]="expandedElement?.flowId === element.flowId"
              name="clock-rotate-left"
            ></uni-icon>
          </div>

          <uni-icon [matMenuTriggerFor]="menu" name="ellipsis-v"></uni-icon>
          <mat-menu #menu="matMenu" class="pl-4 pr-4">
            <a [href]="'flows/flow-builder?flowId=' + element.flowId + '&flowVersion=' + element.version" mat-menu-item>
              <uni-icon name="edit"></uni-icon>
              {{ "label.edit" | translate }}
            </a>
            <button (click)="openFlowVersionListModal(element.flowId, element.flowName)" class="btn" mat-menu-item>
              <uni-icon name="clock-rotate-left"></uni-icon>
              {{ "label.version" | translate }}
            </button>
            <a
              [href]="'flows/flow-builder?flowId=' + element.flowId + '&isDuplicate=true&flowVersion=' + element.version + ''"
              mat-menu-item
            >
              <uni-icon name="clone"></uni-icon>
              {{ "label.duplicate" | translate }}
            </a>
            <button (click)="openConfirmModal(element.flowId, element.version)" mat-menu-item>
              <uni-icon name="trash"></uni-icon>
              {{ "label.delete" | translate }}
            </button>
            <button (click)="undeployFlow(element)" *ngIf="element.flowStatus === 'ACTIVE'" mat-menu-item>
              <uni-icon name="stop"></uni-icon>
              {{ "label.stopFlow" | translate }}
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column -->
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let detail">
          <div class="example-element-detail">
            <uni-section>
              <uni-box>
                <uni-box-content>
                  <div *ngIf="versionedFlows.length" class="div-table">
                    <ng-container *ngFor="let versionedFlow of versionedFlows">
                      <mat-grid-list cols="5" rowHeight="50px">
                        <mat-grid-tile>
                          <div class="flow-name">{{ flowName }} v {{ versionedFlow.flowVersion }}</div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                          <div class="version-date">
                            {{ versionedFlow.createdAt | date: "dd-MM-yyyy" }}
                          </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                          <div class="version-date">{{ versionedFlow.createdAt | date: "HH:mm" }}</div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                          <div class="version-status">
                            <span
                              [ngClass]="{
                                'draft-status': versionedFlow.flowStatus === 'DRAFT',
                                'finalising-status': versionedFlow.flowStatus === 'FINALISING'
                              }"
                              class="badge border"
                            >
                              {{ versionedFlow.flowStatus }}</span
                            >
                          </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                          <div class="">
                            <uni-link
                              [path]="
                                'flows/flow-builder?flowId=' + expandedElement?.flowId + '&flowVersion=' + versionedFlow?.flowVersion + ''
                              "
                            >
                              <uni-icon name="edit"></uni-icon>
                            </uni-link>
                          </div>
                        </mat-grid-tile>
                      </mat-grid-list>
                    </ng-container>
                  </div>
                  <div *ngIf="versionedFlows.length == 0" class="div-table text-center">
                    <h4>You don't have versions created for this flow</h4>
                  </div>
                </uni-box-content>
              </uni-box>
            </uni-section>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [class.expanded]="expandedElement == row" class="element-row" matRipple>
      </mat-row>
      <mat-row
        *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
        [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'"
        style="overflow: hidden"
      >
      </mat-row>
    </mat-table>
  </uni-box>

  <uni-section *ngIf="totalItems == 0">
    <uni-table-empty [description]="(isSearchParams ? 'label.noFlowMatchSearch' : 'label.noFlow') | translate" [isTitle]="false">
      <div class="text-center">
        <div class="my-4">
          <img alt="No Flows" src="assets/img/no-flows.png" />
        </div>
        <uni-button (click)="resetSearch()" *ngIf="isSearchParams" [fit]="true">{{ "action.resetSearch" | translate }}</uni-button>
      </div>
    </uni-table-empty>
  </uni-section>

  <uni-pagination *ngIf="showPagination" [itemsPerPage]="itemsPerPage" [items]="totalItems"></uni-pagination>

  <fs-flows-list-modal (isActiveChange)="toggleModal()" (submitChange)="onRemove()" [isActive]="isModalActive"></fs-flows-list-modal>

  <fs-flow-version-list-modal
    (isActiveChange)="toggleFlowVersionListModal()"
    [flowId]="flowId"
    [flowName]="flowName"
    [flowVersions]="flowVersions"
    [isActive]="isFlowVersionListModalActive"
  >
  </fs-flow-version-list-modal>
</ng-container>
