import { Injectable } from '@angular/core';
import {
  WhatsappApplicationKey,
  WhatsappApplicationModel,
  WhatsappApplicationTemplates
} from '@flows/shared/whatsapp/whatsapp.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { WHATSAPP_KEY } from '@flows/store/whatsapp/whatsapp.state';
import { SettingsService } from '@shared/settings.service';

@Injectable({
  providedIn: 'root'
})
export class WhatsappRepository {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  getWhatsappApplications(): Observable<HttpResponse<WhatsappApplicationModel[]>> {
    return this.http.get<WhatsappApplicationModel[]>(`${this.settingsService.apiUrl}/api/v1/proxy/conversation/applications`, {
      observe: 'response',
      headers: {
        queryName: 'getWhatsappApplications',
        queryGroups: [WHATSAPP_KEY]
      }
    });
  }

  getWhatsappApplicationTemplates(applicationId: string, channelId: string): Observable<HttpResponse<WhatsappApplicationTemplates[]>> {
    const url = `${this.settingsService.apiUrl}/api/v1/proxy/conversation/application/${applicationId}/channel/${channelId}/templates`;
    return this.http.get<WhatsappApplicationTemplates[]>(url, {
      observe: 'response',
      headers: {
        queryName: 'getWhatsappApplications',
        queryGroups: [WHATSAPP_KEY]
      }
    });
  }

  getWhatsappApplicationKey(applicationId: string): Observable<HttpResponse<WhatsappApplicationKey>> {
    const url = `${this.settingsService.apiUrl}/api/v1/proxy/conversation/application/${applicationId}/api-key`;
    return this.http.get<WhatsappApplicationKey>(url, {
      observe: 'response',
      headers: {
        queryName: 'getWhatsappApplicationKey',
        queryGroups: [WHATSAPP_KEY]
      }
    });
  }
}
