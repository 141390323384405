import { createFeatureSelector, createSelector } from '@ngrx/store';

import { VoiceState } from './voice.state';

export const selectVoiceFeature = createFeatureSelector<VoiceState>('voice');

export const selectVoiceAudioModel = createSelector(
  selectVoiceFeature,
  (state: VoiceState) => state.voiceAudioModel,
);

export const selectVoiceApplicationModel = createSelector(
  selectVoiceFeature,
  (state: VoiceState) => state.voiceApplicationModel,
);
export const selectInboundCallerIds = createSelector(
  selectVoiceFeature,
  (state: VoiceState) => state.primaryCaller,
);

