import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UniBoxActionsComponent } from './components/uni-box-actions/uni-box-actions.component';
import { UniBoxContentComponent } from './components/uni-box-content/uni-box-content.component';
import { UniBoxFooterItemComponent } from './components/uni-box-footer-item/uni-box-footer-item.component';
import { UniBoxFooterComponent } from './components/uni-box-footer/uni-box-footer.component';
import { UniBoxItemComponent } from './components/uni-box-item/uni-box-item.component';
import { UniBoxItemsComponent } from './components/uni-box-items/uni-box-items.component';
import { UniBoxTabComponent } from './components/uni-box-tab/uni-box-tab.component';
import { UniBoxTabsComponent } from './components/uni-box-tabs/uni-box-tabs.component';
import { UniBoxComponent } from './components/uni-box/uni-box.component';
import { UniBoxInfoComponent } from './components/uni-box-info/uni-box-info.component';
import { UniBoxContentHeaderComponent } from './components/uni-box-content-header/uni-box-content-header.component';
import { UniBoxContentHeaderItemComponent } from './components/uni-box-content-header-item/uni-box-content-header-item.component';
import { UniBoxContentHeaderTitleComponent } from './components/uni-box-content-header-title/uni-box-content-header-title.component';

const components = [
  UniBoxComponent,
  UniBoxContentComponent,
  UniBoxTabsComponent,
  UniBoxTabComponent,
  UniBoxTabComponent,
  UniBoxActionsComponent,
  UniBoxInfoComponent,
  UniBoxItemComponent,
  UniBoxItemsComponent,
  UniBoxFooterComponent,
  UniBoxFooterItemComponent,
  UniBoxContentHeaderComponent,
  UniBoxContentHeaderItemComponent,
  UniBoxContentHeaderTitleComponent,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class UniBoxModule { }
