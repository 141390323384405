import { Params } from '@angular/router';

import {
  CreateFlow,
  Flow,
  FlowJumptoStorageModel,
  FlowResponse,
  FlowVersion,
  FlowVersionDTO,
  UpdateFlowDTO,
} from '@flows/shared/flows.model';
import { Action } from '@ngrx/store';

export const SET_USER_FLOWS = '[Flows] SET_USER_FLOWS';
export const SET_USER_FLOWS_SUCCESS = '[Flows] SET_USER_FLOWS_SUCCESS';

export const SET_USER_FLOW_VERSIONS = '[Flows] SET_USER_FLOW_VERSIONS';
export const SET_USER_FLOW_VERSIONS_SUCCESS = '[Flows] SET_USER_FLOW_VERSIONS_SUCCESS';

export const DELETE_FLOW = '[Flows] DELETE_FLOW';
export const DELETE_FLOW_SUCCESS = '[Flows] DELETE_FLOW_SUCCESS';

export const SET_FLOW = '[Flows] SET_FLOW';
export const SET_FLOW_SUCCESS = '[Flows] SET_FLOW_SUCCESS';

export const SAVE_FLOW = '[Flows] SAVE_FLOW';
export const SAVE_FLOW_SUCCESS = '[Flows] SAVE_FLOW_SUCCESS';

export const UPDATE_FLOW = '[Flows] UPDATE_FLOW';
export const UPDATE_FLOW_SUCCESS = '[Flows] UPDATE_FLOW_SUCCESS';

export const UPDATE_FLOW_NAME = '[Flows] UPDATE_FLOW_NAME';
export const UPDATE_FLOW_NAME_SUCCESS = '[Flows] UPDATE_FLOW_NAME_SUCCESS';

export const DESTROY_FLOW = '[Flows] DESTROY_FLOW';
export const DESTROY_FLOW_SUCCESS = '[Flows] DESTROY_FLOW_SUCCESS';

export const SET_ACTIVE_FLOW_VERSIONS = '[Flows] SET_ACTIVE_FLOW_VERSIONS';
export const SET_ACTIVE_FLOW_VERSIONS_SUCCESS = '[Flows] SET_ACTIVE_FLOW_VERSIONS_SUCCESS';

export const DEPLOY_FLOW = '[Flows] DEPLOY_FLOW';
export const DEPLOY_FLOW_SUCCESS = '[Flows] DEPLOY_FLOW_SUCCESS';

export const UNDEPLOY_FLOW = '[Flows] UNDEPLOY_FLOW';
export const UNDEPLOY_FLOW_SUCCESS = '[Flows] UNDEPLOY_FLOW_SUCCESS';

export const VALIDATE_SCRIPT = '[Flows] VALIDATE_SCRIPT';
export const VALIDATE_SCRIPT_SUCCESS = '[Flows] VALIDATE_SCRIPT_SUCCESS';

export const EXECUTE_FLOW = '[Flows] EXECUTE_FLOW';
export const EXECUTE_FLOW_SUCCESS = '[Flows] EXECUTE_FLOW_SUCCESS';

export const TOGGLE_WIDGET_PARAMETER_VISIBILITY = '[Flows] TOGGLE_WIDGET_PARAMETER_VISIBILITY';
export const TOGGLE_WIDGET_PARAMETER_VISIBILITY_SUCCESS = '[Flows] TOGGLE_WIDGET_PARAMETER_VISIBILITY_SUCCESS';

export const TOGGLE_CHOOSE_TRIGGER_VISIBILITY = '[Flows] TOGGLE_CHOOSE_TRIGGER_VISIBILITY';

export const TOGGLE_JUMPTO_SELECTION = '[Flows] TOGGLE_JUMPTO_SELECTION';
export const TOGGLE_JUMPTO_SELECTION_SUCCESS = '[Flows] TOGGLE_JUMPTO_SELECTION_SUCCESS';

export const CLEAR_CACHE = '[Flows] CLEAR_CACHE';
export const CLEAR_CACHE_SUCCESS = '[Flows] CLEAR_CACHE_SUCCESS';

export const SET_SOURCE_IDS_FOR_NEW_WIDGET = '[Flows] SET_SOURCE_IDS_FOR_NEW_WIDGET';

export class SetUserFlows implements Action {
  readonly type = SET_USER_FLOWS;

  constructor(public id: string, public params: Params) {}
}

export class SetUserFlowsSuccess implements Action {
  readonly type = SET_USER_FLOWS_SUCCESS;

  constructor(public response: FlowResponse) {}
}

export class SetUserFlowVersions implements Action {
  readonly type = SET_USER_FLOW_VERSIONS;

  constructor(public accountId: string, public flowId: string, public params: Params) {}
}

export class SetUserFlowVersionsSuccess implements Action {
  readonly type = SET_USER_FLOW_VERSIONS_SUCCESS;

  constructor(public response: FlowVersion[]) {}
}

export class SetFlow implements Action {
  readonly type = SET_FLOW;

  constructor(public id: string, public params: Params) {}
}

export class SetFlowSuccess implements Action {
  readonly type = SET_FLOW_SUCCESS;

  constructor(public response: Flow) {}
}

export class SaveFlow implements Action {
  readonly type = SAVE_FLOW;

  constructor(public accountId: string, public flow: CreateFlow, public params: Params) {}
}

export class SaveFlowSuccess implements Action {
  readonly type = SAVE_FLOW_SUCCESS;

  constructor(public response: Flow) {}
}

export class DeleteFlow implements Action {
  readonly type = DELETE_FLOW;

  constructor(public flowId: string, public accountId: string, public flowVersion: number, public params: Params) {}
}

export class DeleteFlowSuccess implements Action {
  readonly type = DELETE_FLOW_SUCCESS;

  constructor() {}
}

export class UpdateFlow implements Action {
  readonly type = UPDATE_FLOW;

  constructor(public id: string, public flow: UpdateFlowDTO) {}
}

export class UpdateFlowSuccess implements Action {
  readonly type = UPDATE_FLOW_SUCCESS;

  constructor(public response: Flow) {}
}

export class UpdateFlowName implements Action {
  readonly type = UPDATE_FLOW_NAME;

  constructor(public accountId: string, public flow: UpdateFlowDTO) {}
}

export class UpdateFlowNameSuccess implements Action {
  readonly type = UPDATE_FLOW_NAME_SUCCESS;

  constructor(public response: Flow) {}
}

export class DestroyFlow implements Action {
  readonly type = DESTROY_FLOW;

  constructor() {}
}

export class DestroyFlowSuccess implements Action {
  readonly type = DESTROY_FLOW_SUCCESS;

  constructor() {}
}

export class SetActiveFlowVersions implements Action {
  readonly type = SET_ACTIVE_FLOW_VERSIONS;

  constructor(public accountId: string, public status: string, public params: Params) {}
}

export class SetActiveFlowVersionsSuccess implements Action {
  readonly type = SET_ACTIVE_FLOW_VERSIONS_SUCCESS;

  constructor(public response: FlowVersionDTO[]) {}
}

export class DeployFlow implements Action {
  readonly type = DEPLOY_FLOW;

  constructor(public flowId: string, public accountId: string, public flowVersion: number, public params: Params) {}
}

export class DeployFlowSuccess implements Action {
  readonly type = DEPLOY_FLOW_SUCCESS;

  constructor() {}
}

export class ExecuteFlow implements Action {
  readonly type = EXECUTE_FLOW;

  constructor(public flowId: string, public requestParams: object) {}
}

export class ExecuteFlowSuccess implements Action {
  readonly type = EXECUTE_FLOW_SUCCESS;

  constructor() {}
}

export class ToggleWidgetParameterVisibility implements Action {
  readonly type = TOGGLE_WIDGET_PARAMETER_VISIBILITY;

  constructor(public visibility: boolean) {}
}

export class ToggleWidgetParameterVisibilitySuccess implements Action {
  readonly type = TOGGLE_WIDGET_PARAMETER_VISIBILITY_SUCCESS;

  constructor(public response: boolean) {}
}

export class ToggleChooseTriggerVisibility implements Action {
  readonly type = TOGGLE_CHOOSE_TRIGGER_VISIBILITY;

  constructor(public visibility: boolean) {}
}

export class ToggleJumptoSelection implements Action {
  readonly type = TOGGLE_JUMPTO_SELECTION;

  constructor(public request: FlowJumptoStorageModel) {}
}

export class ToggleJumptoSelectionSuccess implements Action {
  readonly type = TOGGLE_JUMPTO_SELECTION_SUCCESS;

  constructor(public response: FlowJumptoStorageModel) {}
}

export class UndeployFlow implements Action {
  readonly type = UNDEPLOY_FLOW;

  constructor(public flowId: string, public accountId: string, public flowVersion: number, public params: Params) {}
}

export class UndeployFlowSuccess implements Action {
  readonly type = UNDEPLOY_FLOW_SUCCESS;

  constructor() {}
}

export class ValidateScript implements Action {
  readonly type = VALIDATE_SCRIPT;

  constructor(public script: string, public inputData: any) {}
}

export class ValidateScriptSuccess implements Action {
  readonly type = VALIDATE_SCRIPT_SUCCESS;

  constructor() {}
}

export class ClearCache implements Action {
  readonly type = CLEAR_CACHE;

  constructor() {}
}

export class ClearCacheSuccess implements Action {
  readonly type = CLEAR_CACHE_SUCCESS;

  constructor() {}
}

export class SetSourceIdsForNewWidget implements Action {
  readonly type = SET_SOURCE_IDS_FOR_NEW_WIDGET;

  constructor(public sourceIds?: string[]) {}
}

export type All =
  | SetUserFlows
  | SetUserFlowsSuccess
  | SetUserFlowVersions
  | SetUserFlowVersionsSuccess
  | DeleteFlow
  | DeleteFlowSuccess
  | SetFlow
  | SetFlowSuccess
  | SaveFlow
  | SaveFlowSuccess
  | UpdateFlow
  | UpdateFlowSuccess
  | DestroyFlow
  | DestroyFlowSuccess
  | SetActiveFlowVersions
  | SetActiveFlowVersionsSuccess
  | DeployFlow
  | DeployFlowSuccess
  | ExecuteFlow
  | ExecuteFlowSuccess
  | ToggleWidgetParameterVisibility
  | ToggleWidgetParameterVisibilitySuccess
  | ToggleJumptoSelection
  | ToggleJumptoSelectionSuccess
  | UndeployFlow
  | UndeployFlowSuccess
  | ValidateScript
  | ValidateScriptSuccess
  | UpdateFlowName
  | UpdateFlowNameSuccess
  | ToggleChooseTriggerVisibility
  | ClearCache
  | ClearCacheSuccess
  | SetSourceIdsForNewWidget;
