<uni-container>
  <uni-section [isFirst]="true">
    <uni-title-container>
      <uni-title-item>
        <uni-icon [isRightSpace]="true" name="angle-left" routerLink="/"></uni-icon>
        <uni-text-body>
          {{ 'sidebar.flowStudio.templates' | translate }}
        </uni-text-body>
      </uni-title-item>
      <uni-title-item>

        <uni-button *appCheckPermissions="permissions.create, feature: features.TEMPLATE_MANAGEMENT" [fitContent]="true"
                    [fit]="true"
                    [queryParams]="{type: 'template'}" [routerLink]="'/flows/flow-builder'">
          {{ 'templates.list.create' | translate }}
          <uni-icon-action></uni-icon-action>
        </uni-button>
      </uni-title-item>
    </uni-title-container>
    <uni-separator></uni-separator>
  </uni-section>
  <uni-section>
    <div class="">
      <div class="to_the_left">
        <uni-box-tabs>
          <uni-box-tab (click)="changeTab('ALL')" [isActive]="activeTab === 'ALL'">
            {{ 'label.all' | translate }}
          </uni-box-tab>
          <uni-box-tab (click)="changeTab('DRAFT')" [isActive]="activeTab === 'DRAFT'">
            {{ 'label.draft' | translate }}
          </uni-box-tab>
          <uni-box-tab (click)="changeTab('PUBLISH')" [isActive]="activeTab === 'PUBLISH'">
            {{ 'label.publish' | translate }}
          </uni-box-tab>
          <uni-box-tab (click)="changeTab('UNPUBLISH')" [isActive]="activeTab === 'UNPUBLISH'">
            {{ 'label.unpublish' | translate }}
          </uni-box-tab>
        </uni-box-tabs>
      </div>
    </div>
    <fs-template-list-items [params]="queryParams" [showPagination]="true"></fs-template-list-items>
  </uni-section>
  <uni-section>
    <uni-pagination [itemsPerPage]="itemsPerPage" [items]="totalItems"></uni-pagination>
  </uni-section>
  <uni-section>
    <div *ngIf="totalItems === 0" class="col-lg-12 col-md-12 col-sm-12 pt-4 rounded text-center no-flow">
      <div class="pt-4">
        <uni-text-caption [isUppercase]="false">{{ 'label.noFlowTemplate' | translate }}</uni-text-caption>
      </div>
      <div class="pt-4">
        <img alt="No Templates" src="assets/img/no-flows.png">
      </div>
    </div>
  </uni-section>
</uni-container>
