import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UniRouteService } from '@unifonic/common';
import {TemplatePermissionsService} from '@templates/shared/template-permissions.service';


@Injectable({ providedIn: 'root' })
export class TemplatesUserGuard implements CanActivate {
  constructor(
    private modePermissions: TemplatePermissionsService,
    private uniRouteMode: UniRouteService,
  ) { }

  canActivate(): boolean {
    if (!this.modePermissions.canSee()) {
      this.uniRouteMode.navigate('/403');
      return false;
    }

    return true;
  }
}
