<uni-modal isCloseIcon="true" [isActive]="isActive" (isActiveChange)="hideFlowVersionModal()">
  <uni-section-header>
    <div class="flows-list-modal__icon-container">
      <h3>{{flowName}}</h3>
    </div>
  </uni-section-header>
  <uni-modal-content style="max-width: none;" *ngIf="flowVersions">

    <uni-table  *ngIf="flowVersions.length != 0">

      <table cdk-table [dataSource]="flowVersions">
        <ng-container [cdkColumnDef]="columns.version">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.version | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.flowVersion }}
          </td>
        </ng-container>


        <ng-container [cdkColumnDef]="columns.status">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.status | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">

            <span [ngClass]="{
              'btn-outline-primary':element.flowStatus === 'DRAFT',
              'btn-outline-warning':element.flowStatus === 'FINALISING',
              'btn-outline-success':element.flowStatus === 'ACTIVE'
            }" class="btn ">
              {{element.flowStatus}}</span>
            <!-- @TODO: you can use 'uni-status' component here if it covers your needs -->
          </td>
        </ng-container>
        <ng-container [cdkColumnDef]="columns.created">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.created | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.createdAt }}
          </td>
        </ng-container>



        <ng-container [cdkColumnDef]="columns.actions">
          <th cdk-header-cell *cdkHeaderCellDef>
            Action
          </th>
          <td cdk-cell *cdkCellDef="let element">
            <!-- @TODO: I added only edit action to show you the idea -->

            <uni-link [path]="'flows/flow-builder?flowId=' + flowId + '&flowVersion=' + element?.flowVersion + ''">
              <uni-icon [name]="'edit'"></uni-icon>
            </uni-link>

          </td>
        </ng-container>

        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr cdk-row
          *cdkRowDef="let row; columns: displayedColumns; let isOdd = odd; let isEven = even; let isLast = last"
          [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"></tr>
      </table>
    </uni-table>
    <div class="div-table text-center" *ngIf="flowVersions.length == 0">
      <h4>You don't have versions created for this flow</h4>
    </div>
  </uni-modal-content>
</uni-modal>