export const REGEX: Record<string, RegExp> = {
  number: /^[0-9]*$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w]|[_])).[\S]{9,64}$/,
  passwordLength: /^.{10,64}$/,
  passwordLowercase: /^(?=.*?[a-z]).+$/,
  passwordNumber: /^(?=.*?[0-9]).+$/,
  passwordSpecial: /^(?=.*?([^\w]|[_])).+$/,
  passwordUppercase: /^(?=.*?[A-Z]).+$/,
  noSpaces: /^[\S]+$/,
  noMultipleSpaces: /\s{2,}/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/,
  price: /^\d+(.\d{1,2})?$/,
  price3: /^\d+(.\d{1,3})?$/,
  price4: /^\d+(.\d{1,4})?$/,
  price5: /^\d+(.\d{1,5})?$/,
  price6: /^\d+(.\d{1,6})?$/,
  price7: /^\d+(.\d{1,7})?$/,
  price8: /^\d+(.\d{1,8})?$/,
  price9: /^\d+(.\d{1,9})?$/,
  notZero: /[^0]+/,
  letters: /^(?=.*[a-zA-Z])[\D]*$/,
  templateNames: /^[a-z0-9_]+$/,
  phone: /^[+]{0,1}[0-9]*$/,
  phones: /^([+]{0,1}[0-9, ])*$/,
  urlWithHttpsProtocol: /^(https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  // eslint-disable-next-line
  arabicCharacters:
  /^[0-9\u0600-\u06ff\u0750-\u077f\ufb50-\ufbc1\ufbd3-\ufd3f\ufd50-\ufd8f\ufd50-\ufd8f\ufe70-\ufefc\uFDF0-\uFDFD .,'":;!?\\-\\\n]*$/,
  urduCharacters:
  /^[0-9\u0600-\u06ff\u0750-\u077f\ufb50-\ufdff\ufe70-\ufeff .,'":;!?\\-\\\n]*[\u0600-\u06ff\u0750-\u077f\ufb50-\ufdff\ufe70-\ufeff].*$/,
  englishCharacters: /^[0-9a-zA-Z .,'":;!?\\-\\\n]*$/,
  urlPattern: /^(http(s?):\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  urlPatternWithParam: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  urlPath: /^\/{1}.[^ ]*/,
  alphanumeric: /^[0-9a-zA-Z]+$/,
  notOnlySpecialCharacters: /^(?=.*[a-zA-Z0-9]).*$/,
  alphanumericAndUnderscore: /^[0-9a-zA-Z_]+$/,
  senderNameCharset: /[a-zA-Z\d\-_\$&\*#. +]/,
  senderNamePattern: /^(\d{1,12}|[a-zA-Z\d\-_\$&\*#. +]{1,11})$/,
  trailingSpacesPattern: /^[^\s](.*[^\s])?$/,
  ip: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
  templateVariable: /{{\d+}}/g,
  templateWordExceptVariable: /\b\w+[^{{\d+}}]/g,
  beginsWithVariable: /^{{\d+}}/,
  endsWithVariable: /{{\d+}}*$/,
  specialChars: /[*|\":<>[\]{}`\\()';@&$]/,
  specialCharactersNotAllowed: /[!@#$%^&*()\-=_+{}[\]|;:'",<>\/?~\n\t\r]/g,
  allCharactersExceptNumbersRegExRule: /[^0-9]/g,
};

export const XREGEX: Record<string, RegExp> = {
  name: /^[a-z ,.'-]+$/i,
  nameWithArabic: /^[\u0600-\u06ff\u0750-\u077f\ufb50-\ufbc1\ufbd3-\ufd3f\ufd50-\ufd8f\ufd50-\ufd8f\ufe70-\ufefc\uFDF0-\uFDFDa-z ,.'-]+$/i,
  name2: /^[\p{Letter}0-9\s.'-]+$/u,
};

export const getPriceDecimalRegex = (decimalValue = 2): RegExp => {
  switch (decimalValue) {
    case 3: return REGEX.price3;
    case 4: return REGEX.price4;
    case 5: return REGEX.price5;
    case 6: return REGEX.price6;
    case 7: return REGEX.price7;
    case 8: return REGEX.price8;
    case 9: return REGEX.price9;
    default: return REGEX.price;
  }
};
