<uni-modal
  isCloseIcon="true"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-section-header>
<!--    <div class="flows-list-modal__icon-container">-->
<!--      <uni-icon-->
<!--        [name]="'trash-alt fa-stack-2x'"-->
<!--        [style]="'fas'"-->
<!--        class="flows-list-modal__icon"-->
<!--      ></uni-icon>-->
<!--    </div>-->
  </uni-section-header>
  <uni-modal-content>
    <uni-text-subheader>
      {{ 'flows.list.modal.header' | translate }}
    </uni-text-subheader>
    <uni-text-info>
      {{ 'flows.list.modal.info' | translate }}
    </uni-text-info>
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button [style]="'secondary'" (click)="hideModal()">
      {{ 'label.cancel' | translate }}
    </uni-button>
    <uni-button (click)="onSubmit()">
      {{ 'label.delete' | translate }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
