import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { httpEncoder } from '@unifonic/common';
import { SettingsService } from '@shared/settings.service';
import { TEMPLATES_KEY } from '@templates/store/templates.state';
import {
  CreateTemplate,
  FlowTemplateModel,
  Template,
  TemplateResponse,
  TemplateStatusResponse
} from '@templates/shared/templates.model';

@Injectable({
  providedIn: 'root'
})
export class TemplatesRepository {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  getUserTemplates(accountId: string, params: Params = {}): Observable<HttpResponse<TemplateResponse>> {
    return this.http.get<TemplateResponse>(`${this.settingsService.apiUrl}/api/v1/templates`, {
      params: httpEncoder(params),
      observe: 'response',
      headers: {
        queryName: 'getUserTemplates',
        queryGroups: [TEMPLATES_KEY]
      }
    });
  }

  deleteTemplate(
    templateId: string,
    accountId: string
  ): Observable<any> {
    return this.http.delete(`${this.settingsService.apiUrl}/api/v1/template/${templateId}`, {
      headers: {
        queryName: 'deleteTemplate',
        queryGroups: [TEMPLATES_KEY]
      }
    });
  }

  saveTemplate(template: CreateTemplate): Observable<HttpResponse<Template>> {
    return this.http.post<Template>(
      `${this.settingsService.apiUrl}/api/v1/template`, template, {
        observe: 'response',
        headers: {
          queryName: 'saveTemplate',
          queryGroups: [TEMPLATES_KEY]
        }
      }
    );
  }

  updateTemplate(data: FlowTemplateModel): Observable<HttpResponse<TemplateStatusResponse>> {
    return this.http.patch<TemplateStatusResponse>(
      `${this.settingsService.apiUrl}/api/v1/template`, data, {
        observe: 'response',
        headers: {
          queryName: 'updateTemplate',
          queryGroups: [TEMPLATES_KEY]
        }
      }
    );
  }

  getTemplate(templateId: string): Observable<HttpResponse<Template>> {
    return this.http.get<Template>(`${this.settingsService.apiUrl}/api/v1/template/${templateId}`, {
      observe: 'response',
      headers: {
        queryName: 'getTemplate',
        queryGroups: [TEMPLATES_KEY]
      }
    });
  }


}
