import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-widget-variable',
  templateUrl: './widget-variable.component.html',
  styleUrls: ['./widget-variable.component.scss']
})
export class WidgetVariableComponent implements OnInit {
  @Output() variableEvent = new EventEmitter<string>();

  public form: FormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = new FormGroup({
      variableName: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.variableEvent.emit(this.form.value.variableName);
      this.form.reset();
    }
  }
}
