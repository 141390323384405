import { Component, HostBinding, Input } from '@angular/core';
import { UniNavFacade, FeatureFlagKeys, Permission } from '@unifonic/common';
import { Router, Event, NavigationEnd } from '@angular/router';
import {Features} from '@shared/features';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  @HostBinding('class.visible') @Input() isVisible: boolean = false;
  @Input() hasBack: boolean = false;
  featureFlagKeys = FeatureFlagKeys;
  displayNavigation: boolean = true;

  features = Features;
  permissions = Permission;

  constructor(private uniNavFacade: UniNavFacade, private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.displayNavigation = !router.url.includes('/flows/flow-builder');
      }
    });
  }

  version: string = localStorage.getItem('version') ? localStorage.getItem('version') : 'v2';
  navigationVersions = {
    v1: {
      navigations: [
        {
          path: '/dashboard',
          name: 'sidebar.flowStudio.dashboard',
          icon: 'home',
          feature: this.features.DASHBOARD,
          permission: this.permissions.see
        },
        {
          path: '/flow',
          name: 'sidebar.flowStudio.myFlows',
          icon: 'compass',
          feature: this.features.FLOWS,
          permission: this.permissions.seeOwn
        },
        {
          path: '/templates',
          name: 'sidebar.flowStudio.templates',
          icon: 'book-open',
          feature: this.features.TEMPLATE_MANAGEMENT,
          permission: this.permissions.see
        },
        {
          path: '/reports',
          name: 'sidebar.flowStudio.reports',
          icon: 'home',
          feature: this.features.REPORTS,
          permission: this.permissions.see
        }
      ]
    },
    v2: {
      navigations: [
        {
          path: '/dashboard',
          name: 'sidebar.flowStudio.dashboard',
          icon: 'home',
          feature: this.features.DASHBOARD,
          permission: this.permissions.see
        },
        {
          path: '/flows',
          name: 'sidebar.flowStudio.myFlows',
          icon: 'compass',
          feature: this.features.FLOWS,
          permission: this.permissions.seeOwn
        },
        {
          path: '/v2/templates/template-list',
          name: 'sidebar.flowStudio.templates',
          icon: 'book-open',
          feature: this.features.TEMPLATE_MANAGEMENT,
          permission: this.permissions.see
        },
        {
          path: '/reports',
          name: 'sidebar.flowStudio.executions',
          icon: 'analytics',
          feature: this.features.REPORTS,
          permission: this.permissions.see
        },
        {
          path: '/channel-reports',
          name: 'sidebar.flowStudio.channel-executions',
          icon: 'analytics',
          feature: this.features.REPORTS,
          permission: this.permissions.see
        }
      ]
    }
  };

  navigationList = this.navigationVersions.v2.navigations;
  versions = [
    {
      name: 'v1',
      value: this.navigationVersions.v1.navigations
    },
    {
      name: 'v2',
      value: this.navigationVersions.v2.navigations
    }
  ];
  selectedVersion = [];
  changeNavigationList(version){
    if (version === 'v1'){
      this.navigationList = this.navigationVersions.v1.navigations;
    }else{
      this.navigationList = this.navigationVersions.v2.navigations;
    }
    this.version = version;
    localStorage.setItem('version', version);
  }

  goBack(): void {
    this.uniNavFacade.setNavState(false);
  }
}
