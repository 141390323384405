import { Component, OnInit } from '@angular/core';
import { ExecutionChannelReportingService } from './execution-channel-reporting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { UniLayoutFacade } from '@unifonic/common';

@Component({
  selector: 'fs-execution-channel-reporting',
  templateUrl: './execution-channel-reporting.component.html',
  styleUrls: ['./execution-channel-reporting.component.scss'],
})
export class ExecutionChannelReportingComponent implements OnInit {
  activeTab = 'executions';
  dataSource: any = null;
  totalItems = 0;
  itemsPerPage = 10;
  triggerTypes: any[] = ['Voice', 'SMS', 'WhatsApp', 'Email', 'Notification'].map((name) => ({ name, id: name }));

  displayedColumns = [
    'created',
    'flowName',
    'channel',
    'status',
    'destination',
    'source',
    'executionId',
    'content',
    'messageId',
    'channelMessage',
  ];

  constructor(
    private channelReportingService: ExecutionChannelReportingService,
    private route: ActivatedRoute,
    private router: Router,
    private uniLayoutFacade: UniLayoutFacade
  ) {}

  get isSearchParams(): boolean {
    const { flowName, channel, destination } = this.route.snapshot.queryParams;
    return !!(flowName || channel || destination);
  }

  ngOnInit(): void {
    this.initTable();
  }

  resetSearch() {
    return this.router.navigate([], {
      queryParams: {
        page: 1,
      },
    });
  }

  private initTable() {
    this.route.queryParams
      .pipe(
        tap(() => this.uniLayoutFacade.setIsLoader(true)),
        switchMap(({ page = 1, flowName = '', channel = '', destination = '' }) =>
          this.channelReportingService
            .getChannelReport(page - 1, this.itemsPerPage, flowName, channel, destination)
            .pipe(finalize(() => this.uniLayoutFacade.setIsLoader(false)))
        )
      )
      .subscribe((channelReport) => {
        this.dataSource = channelReport?.content || [];
        this.totalItems = channelReport.totalElements;
      });
  }
}
