

export class FlowExecutionResponseModel {

  constructor(
    public id?: string,
    public accountId?: string,
    public createdAt?: Date,
    public modifiedAt?: Date,
    public list?: FlowExecution[],
    public totalElements?: number,
    public totalPages?: number
  ) {

  }

}

export interface FlowExecution {
  id: string;
  flowId: string;
  flowName: string;
  flowVersion: number;
  processDefinitionId: string;
  processInstanceId: string;
  createdAt: Date;
  modifiedAt: Date;
  executionId: string;
}


export interface FlowExecutionLog {
  id: string;
  correlationId: string;
  widgetType: string;
  flowVariable: any;
  duration: number;
  status: string;
  errorMessage: null;
  createdAt: Date;
  modifiedAt: Date;
  widgetName: string;
}

export interface FlowNameAndVersionList {
  flowId: string;
  flowName;
  flowVersionList?: number[];
}
