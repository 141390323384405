import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FlowsState } from './flows.state';

export const selectFlowsFeature = createFeatureSelector<FlowsState>('flows');

export const selectUserFlows = createSelector(selectFlowsFeature, (state: FlowsState) => state.userFlows);

export const selectFlowVersions = createSelector(selectFlowsFeature, (state: FlowsState) => state.flowVersions);

export const selectActiveFlowVersions = createSelector(selectFlowsFeature, (state: FlowsState) => state.activeFlowVersions);

export const selectFlow = createSelector(selectFlowsFeature, (state: FlowsState) => state.flow);

export const selectFlowWidgetVisibility = createSelector(selectFlowsFeature, (state: FlowsState) => state.widgetVisibility);

export const selectJumptoSelection = createSelector(selectFlowsFeature, (state: FlowsState) => state.jumptoSelection);

export const selectSourceIdsForNewWidget = createSelector(selectFlowsFeature, (state: FlowsState) => state.sourceIdsForNewWidget);

export const selectChooseTriggerVisibility = createSelector(selectFlowsFeature, (state: FlowsState) => state.chooseTriggerVisibility);
