<uni-container>
  <uni-section [isFirst]="true">
    <uni-title-container>
      <uni-title-item>
        <uni-text-body>
          {{ "flows.execution.channel-title" | translate }}
          <ng-container *ngIf="dataSource"> ({{ totalItems }} executions)</ng-container>
        </uni-text-body>
      </uni-title-item>
    </uni-title-container>
    <uni-separator></uni-separator>
  </uni-section>
  <uni-box *ngIf="dataSource">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef> Created At</mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea"> {{ element.createdAt | date: "dd-MM-yyyy HH:mm" }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="flowName">
        <mat-header-cell *matHeaderCellDef>
          <uni-table-filter-search controlName="flowName" heading="Flow Name"></uni-table-filter-search>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea"> {{ element.flowName }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="channel">
        <mat-header-cell *matHeaderCellDef>
          <uni-table-filter [data]="triggerTypes" controlName="channel" heading="Channel"></uni-table-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea"> {{ element.widgetName }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea"> {{ element.status }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination">
        <mat-header-cell *matHeaderCellDef>
          <uni-table-filter-search controlName="destination" heading="Destination"></uni-table-filter-search>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea"> {{ element.destination }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="source">
        <mat-header-cell *matHeaderCellDef>Source</mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea"> {{ element.source }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="executionId">
        <mat-header-cell *matHeaderCellDef> ExecutionId</mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea">
          <uni-clipboard [isIcon]="true" [text]="element.executionId" length="8"></uni-clipboard>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="content">
        <mat-header-cell *matHeaderCellDef> Content</mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea">
          <mat-cell *ngIf="element.content; else elseBlock">{{ element.content }}</mat-cell>
          <ng-template #elseBlock>
            <span class="ml-auto mr-auto">-</span>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="messageId">
        <mat-header-cell *matHeaderCellDef> Message ID</mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea"> {{ element.messageId }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="channelMessage">
        <mat-header-cell *matHeaderCellDef> Message</mat-header-cell>
        <mat-cell *matCellDef="let element" class="width-textArea"> {{ element.channelMessage }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </uni-box>

  <uni-table-empty *ngIf="dataSource?.length === 0" [description]="'flows.execution.emptyChannelExecution' | translate" [isTitle]="false">
    <div class="text-center">
      <uni-button (click)="resetSearch()" *ngIf="isSearchParams" [fit]="true">{{ "action.resetSearch" | translate }}</uni-button>
    </div>
  </uni-table-empty>

  <uni-pagination [itemsPerPage]="itemsPerPage" [items]="totalItems"></uni-pagination>
</uni-container>
