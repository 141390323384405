import {NotificationApplicationModel} from '@flows/shared/notification/notification.model';
import {Action} from '@ngrx/store';

export const SET_NOTIFICATION_APPLICATIONS = '[Notification] SET_NOTIFICATION_APPLICATIONS';
export const SET_NOTIFICATION_APPLICATIONS_SUCCESS = '[Notification] SET_NOTIFICATION_APPLICATIONS_SUCCESS';

export class SetNotificationApplications implements Action {
  readonly type = SET_NOTIFICATION_APPLICATIONS;

  constructor() {
  }
}

export class SetNotificationApplicationsSuccess implements Action {
  readonly type = SET_NOTIFICATION_APPLICATIONS_SUCCESS;

  constructor(public response: NotificationApplicationModel[]) {
  }
}

export type All =
  SetNotificationApplications
  | SetNotificationApplicationsSuccess;
