import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
})
export class SideNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
