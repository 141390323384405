import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export default class TriggerSelectionService {

    private widgetList = [
        {
            type: 'FS_VOICE',
            icon: 'fas fa-phone-arrow-down-left',
            name: 'Incoming voice call',
            description: 'Wait for an inbound voice call from a specific caller ID'
        },
        {
            type: 'FS_WHATSAPP',
            icon: 'fab fa-whatsapp',
            name: 'Inbound Whatsapp message',
            description: 'Wait for an inbound messaging from an specific whatsapp business account.'
        },
        {
            type: 'FS_FACEBOOK',
            icon: 'fab fa-facebook-messenger',
            name: 'Inbound Facebook message',
            description: 'Wait for an inbound messaging from an specific facebook messenger account.'
        },
        {
            type: 'FS_SMS',
            icon: 'fas fa-sms',
            name: 'Inbound SMS',
            description: 'Wait for an inbound message from an specific Sender ID.'
        },
        {
            type: 'FS_WAIT_FOR_WEBHOOK',
            icon: 'fas fa-webhook',
            name: 'Webhook',
            description: 'Trigger an API Call'
        },
        {
            type: 'FS_AUDIENCE_PROFILE_CHANGE_TRIGGER',
            icon: 'far fa-user',
            name: 'Contact change',
            description: 'Trigger flow whenever there’s a change in a contact.',
        },
        {
          type: 'FS_AUDIENCE_TRIGGER',
          icon: 'fa-light fa-arrow-pointer',
          name: 'Event Based',
          description: 'Trigger flow based on events'
        }
    ];

    private templateWidgetList = [
        {
            type: 'Voice',
            icon: 'fas fa-phone-alt',
            name: 'Voice'
        },
        {
            type: 'SMS',
            icon: 'fas fa-sms',
            name: 'SMS'
        },
        {
            type: 'Conversation',
            icon: 'fab fa-whatsapp',
            name: 'Conversation'
        },
        {
            type: 'Omnichannel',
            icon: 'fab fa-facebook-messenger',
            name: 'Omnichannel'
        }

    ];
    public getFlowWidgetList() {
        return this.widgetList;
    }

    public getTemplateWidgetList() {
        return this.templateWidgetList;
    }
}
