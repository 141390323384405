import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe, UniAuthFacade, Permission } from '@unifonic/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TemplatesFacade } from '@templates/shared/templates.facade';

import TemplateListService from './template-list-service';

import { Subject } from 'rxjs';
import { Template } from '@templates/shared/templates.model';
import {Features} from '@shared/features';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fs-templates-list',
  templateUrl: './templates-list.component.html',
  providers: [TemplateListService]
})
export class TemplatesListComponent extends AutoUnsubscribe implements OnInit, OnDestroy {
  @Input() params: Params;
  accountId: string;
  queryParams: Params;
  templatesList: Template[];
  isModalActive = false;
  unsubscribe$ = new Subject();
  totalItems = 0;
  templateId: string;
  activeTab = 'ALL';
  features = Features;
  permissions = Permission;
  itemsPerPage = 12;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private templatesFacade: TemplatesFacade,
    private uniAuthFacade: UniAuthFacade,
    private templateListService: TemplateListService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
      if (params.flowTemplateStatus) {
        this.activeTab = params.flowTemplateStatus;
      }

      this.templateListService.init(this.itemsPerPage, this.activeTab);
    });

    this.templatesFacade.userTemplates$.subscribe(templates => {
      this.totalItems = templates.totalElements;
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onRemove(templateId) {
    this.templatesFacade.deleteTemplate(templateId, this.accountId,  this.params);
  }

  onCreateTemplate() {
    this.router.navigate(['/templates/list'], { queryParams: this.activatedRoute.snapshot.queryParams });
  }

  changeTab(activeTab: string) {
    this.activeTab = activeTab;
    this.queryParams = {
      ...this.queryParams,
      page: 1,
      flowTemplateStatus: activeTab
    };
    this.router.navigate([`/v2/templates/template-list`], { queryParams: this.queryParams });
  }
}
