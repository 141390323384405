import { SmsAppIdModel, SmsApplicationModel, SmsSenderModel } from '@flows/shared/sms/sms.model';

export const SMS_KEY = 'sms';

export interface SmsState {
  smsApplications: SmsApplicationModel[];
  smsAppIds: SmsAppIdModel[];
  smsSenders: SmsSenderModel[];
}

export const initialSmsState: SmsState = {
  smsApplications: [],
  smsAppIds: [],
  smsSenders: [],
};
