import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniSharedModule } from '@unifonic/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TemplatesComponent } from './containers/templates/templates.component';
import { TemplatesRoutingModule } from './templates-routings.module';
import { TemplatesListModule } from './modules/templates-list/templates-list.module';
import { TemplatesFacade } from './shared/templates.facade';
import { TemplatesRepository } from './shared/templates.repository';
import { TemplatesEffects } from './store';
import { templatesReducer } from './store';

const components = [
  TemplatesComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    UniSharedModule,
    TemplatesRoutingModule,
    TemplatesListModule,
    StoreModule.forFeature('templates', templatesReducer),
    EffectsModule.forFeature([TemplatesEffects]),
  ],
  exports: [TemplatesListModule],
  providers: [
    TemplatesFacade,
    TemplatesRepository,
    // TemplatesListGuard, @TODO: To be added
  ],
})
export class TemplatesModule { }
