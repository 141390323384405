export interface AudienceEventModel {
  total: number;
  eventTypes: EventType[];
}

export interface EventType {
  id: string;
  name: string;
}

export interface AudienceSourceModel {
  total: number;
  eventSources: EventSource[];
}

export interface EventSource {
  id: string;
  name: string;
  description: string;
  channel: string;
  timestamp: string;
  enabled: boolean;
  transitional: boolean;
  returnsProfile: boolean;
  permanentProfileId: boolean;
  requiresConsent: boolean;
  locked: boolean;
  synchronizeProfiles: boolean;
  type: string[];
  tags: string[];
  groups: string[];
  bridge: Bridge;
}

export interface Bridge {
  id: string;
  name: string;
}

export enum AudienceQueryNames {
  GetEventList = 'getEventList',
  GetSourceList = 'getSourceList',
  GetPropertyList = 'getPropertyList',
  GetAudienceMappings = 'getAudienceMappings',
}

export interface AudiencePropertiesModel {
  properties: string[];
  groups: PropertyGroups[];
  items: Record<
    string,
    Record<
      string,
      | string
      | {
          mapping: string;
          type: AttributeTypesEnum;
        }
    >
  >;
}

export enum AttributeTypesEnum {
  Date = 'DATE',
  Text = 'TEXT',
  Number = 'NUMBER',
  Boolean = 'BOOLEAN',
}

export interface PropertyGroups {
  name: string;
  properties: string[];
}

export interface AudienceMapping {
  id: string;
  name: string;
  description: string;
  fields: ProfileField[];
  identifications: IdentificationField[];
  enabled: boolean;
  tags: string[];
  eventType: EventType;
  source?: MappingSource;
}

export interface ProfileField {
  eventProperty: string;
  profileProperty?: string;
  identification: boolean;
  trait: boolean;
}

export type IdentificationField = Omit<ProfileField, 'identification' | 'trait'>;

export interface EventType {
  id: string;
  name: string;
}

export type MappingSource = EventType;

export interface GetMappingsResponse {
  total: number;
  count: number;
  mappings: AudienceMapping[];
}

export class AttributeOperation {
  symbolicValue: string;
  supportedTypes: AttributeTypesEnum[];

  constructor(symbolicValue: string, ...supportedTypes: AttributeTypesEnum[]) {
    this.symbolicValue = symbolicValue;
    this.supportedTypes = supportedTypes;
  }
}

export const AttributeOperations: Record<string, AttributeOperation> = {
  IS: new AttributeOperation('=', AttributeTypesEnum.Boolean, AttributeTypesEnum.Text, AttributeTypesEnum.Number, AttributeTypesEnum.Date),
  IS_NOT: new AttributeOperation(
    '!=',
    AttributeTypesEnum.Boolean,
    AttributeTypesEnum.Text,
    AttributeTypesEnum.Number,
    AttributeTypesEnum.Date
  ),
  CONTAINS: new AttributeOperation('*_*', AttributeTypesEnum.Text),
  STARTS_WITH: new AttributeOperation('_*', AttributeTypesEnum.Text),
  ENDS_WITH: new AttributeOperation('*_', AttributeTypesEnum.Text),
  IS_EMPTY: new AttributeOperation('_', AttributeTypesEnum.Text, AttributeTypesEnum.Number, AttributeTypesEnum.Date),
  IS_NOT_EMPTY: new AttributeOperation('!_', AttributeTypesEnum.Text, AttributeTypesEnum.Number, AttributeTypesEnum.Date),
  LESS_THAN: new AttributeOperation('<', AttributeTypesEnum.Number),
  LESS_THAN_OR_EQUAL: new AttributeOperation('<=', AttributeTypesEnum.Number),
  GREATER_THAN_OR_EQUAL: new AttributeOperation('>=', AttributeTypesEnum.Number),
  GREATER_THAN: new AttributeOperation('>', AttributeTypesEnum.Number),
  BEFORE: new AttributeOperation('<', AttributeTypesEnum.Date),
  UNTIL: new AttributeOperation('<=', AttributeTypesEnum.Date),
  SINCE: new AttributeOperation('>', AttributeTypesEnum.Date),
  AFTER: new AttributeOperation('>=', AttributeTypesEnum.Date),
};

export interface SelectOption {
  label: string;
  value: string;
}

export function getOperationsByType(attributeType: AttributeTypesEnum): SelectOption[] {
  return Object.entries(AttributeOperations).reduce((acc, [key, operation]) => {
    if (operation.supportedTypes.includes(attributeType)) {
      const label = key
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/^\w/, (substring) => substring.toUpperCase());
      acc.push({ label, value: operation.symbolicValue });
    }
    return acc;
  }, []);
}

export const OperationsForTypes: Record<AttributeTypesEnum, SelectOption[]> = Object.values(AttributeTypesEnum).reduce((acc, x) => {
  acc[x] = getOperationsByType(x);
  return acc;
}, {} as Record<AttributeTypesEnum, SelectOption[]>);
