import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import { UniSharedModule } from '@unifonic/common';
import { TemplateListItemsComponent } from './components/template-list-items/template-list-items.component';
import { TemplatesListComponent } from './containers/templates-list/templates-list.component';
import {TemplateDeleteModalComponent} from './components/template-delete-modal/template-delete-modal.component';
import {SharedModule} from '@shared/shared.module';


const components = [
  TemplatesListComponent,
  TemplateListItemsComponent,
  TemplateDeleteModalComponent
];

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    UniSharedModule,
    SharedModule,
  ],
  exports: [...components],
  declarations: [...components],
})
export class TemplatesListModule { }
