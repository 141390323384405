<uni-loader *ngIf="isLoaderActive || (isLoaderActive$ | async)"></uni-loader>

<main class="flow_studio_base flow_studio_style">
  <!-- START OF HEADER -->
  <header *ngIf="startBuilding" class="header" style="z-index: 1;">
    <div class="header__bottom">
      <!-- START OF HEADER BOTTOM LEFT -->
      <div class="header__bottom_left">

        <div *ngIf="!isTemplate" class="header__top_left">
          <a routerLink="/flows">
            <div class="header__top_left_icon">
              <i class="fas fa-angle-left"></i>
            </div>
          </a>

          <p class="header__top_left_text">{{name}}</p>
        </div>

        <div *ngIf="isTemplate" class="header__top_left">
          <a routerLink="/templates">
            <div class="header__top_left_icon">
              <i class="fas fa-angle-left"></i>
            </div>
          </a>

          <p class="header__top_left_text">{{templateName}}</p>
        </div>

        <!-- <i class="far fa-edit"></i> -->

        <div class="header__bottom_left_badge">{{flowStatus}}</div>

        <div (click)="showFlowVersionsModal()" *ngIf="flow" class="header__bottom_left_icon">
          <i class="fas fa-layer-group"></i>
        </div>

        <span *ngIf="modifiedAt">Last saved {{modifiedAt | date: 'dd-MM-yyyy HH:mm'}}</span>


      </div>
      <!-- END OF HEADER BOTTOM LEFT -->

      <!-- START OF STUDIO ERROR MESSAGE -->
      <div *ngIf="backendError" class="alert alert-danger studio-err-msg" role="alert">
        {{backendErrorMsg}}
      </div>
      <!-- END OF ERROR MESSAGE -->

      <!-- START OF HEADER BOTTOM RIGHT -->
      <div class="header__bottom_right">
        <div class="header__bottom_right_tile">
          <div class="header__bottom_right_tile_icon" id="clearFlow">
            <i class="fas fa-trash"></i>
          </div>
        </div>

        <button (click)="showCreateFlowConfirmation()" class="header__bottom_right_btn--white">
          Save draft
        </button>

        <button (click)="showTestFlowModal()" *ngIf="!isTemplate" class="header__bottom_right_btn--white">
          Test <i class="fas fa-play"></i>
        </button>

        <button (click)="showPublishFlowConfirmation()" *ngIf="!isTemplate" class="header__bottom_right_btn--solid">
          Publish
        </button>
        <button (click)="showFlowTemplateConfirmation('Publish')" *ngIf="isTemplate && !toggleTemplateButton"
          class="header__bottom_right_btn--solid">
          Publish
        </button>
        <button (click)="showFlowTemplateConfirmation('Unpublish')" *ngIf="isTemplate && toggleTemplateButton"
          class="header__bottom_right_btn--solid">
          Unpublish
        </button>
      </div>
      <!-- END OF HEADER BOTTOM RIGHT -->
    </div>
  </header>
  <!-- END OF HEADER -->

  <uni-modal [isActive]="isFlowTemplatePublishModalActive">
    <uni-modal-content>
      <uni-text-body>Confirmation Alert
        <uni-icon><i class="fa fa-exclamation-circle fa-5"></i></uni-icon>
      </uni-text-body>
      <p>Are you sure you want to <strong> publish?</strong> Please confirm!</p>
      <h4 class="text-center"></h4>
    </uni-modal-content>
    <uni-modal-footer>
      <uni-button (click)="hideFlowTemplateConfirmation('Publish')" [style]="'secondary'">
        Cancel
      </uni-button>
      <uni-button (click)="updateFlowTemplateStatus('Publish')">
        Publish
      </uni-button>
    </uni-modal-footer>
  </uni-modal>

  <uni-modal [isActive]="isFlowTemplateUnPublishModalActive">
    <uni-modal-content>
      <uni-text-body>Confirmation Alert
        <uni-icon><i class="fa fa-exclamation-circle fa-5"></i></uni-icon>
      </uni-text-body>
      <p>Are you sure you want to <strong> unpublish?</strong> Please confirm!</p>
      <h4 class="text-center"></h4>
    </uni-modal-content>
    <uni-modal-footer>
      <uni-button (click)="hideFlowTemplateConfirmation('Unpublish')" [style]="'secondary'">
        Cancel
      </uni-button>
      <uni-button (click)="updateFlowTemplateStatus('Unpublish')">
        Unpublish
      </uni-button>
    </uni-modal-footer>
  </uni-modal>



  <section *ngIf="!startBuilding" class="content__container" id="parent_canvasg">
    <!-- Create Form -->
    <div *ngIf="!triggerSelection" class="content__card">
      <!-- START OF CONTENT CARD HEADER -->
      <div *ngIf="!isTemplate" class="content__card_header">
        <h1>Create a new flow</h1>
        <uni-link [icon]="'fas fa-times'" [path]="'/'" style="color: inherit;"></uni-link>
      </div>
      <div *ngIf="isTemplate" class="content__card_header">
        <h1>Create a new template</h1>
        <uni-link [icon]="'fas fa-times'" [path]="'/'" style="color: inherit;"></uni-link>
      </div>

      <!-- END OF CONTENT CARD HEADER -->
      <form class="content__container_actions_params_form white_background" onsubmit="return false">
        <div *ngIf="!isTemplate" class="form_item border_bottom">
          <label for="name">
            Flow title
          </label>
          <input [(ngModel)]="name" id="name" name="name" placeholder="Flow title" type="text">
        </div>

        <div *ngIf="isTemplate" class="form_item border_bottom">
          <label for="name">
            Template title
          </label>
          <input [(ngModel)]="templateName" id="templateName" name="templateName" placeholder="Template title"
            type="text">
        </div>

        <div *ngIf="!isTemplate" class="form_item border_bottom">
          <label for="description">
            Description
          </label>
          <textarea [(ngModel)]="description" id="description" name="description" placeholder="Description"
            rows="9"></textarea>
        </div>

        <div *ngIf="isTemplate" class="form_item border_bottom">
          <label for="description">
            Description
          </label>
          <textarea [(ngModel)]="templateDescription" id="templateDescription" name="templateDescription"
            placeholder=" Template Description" rows="9"></textarea>
        </div>

        <button (click)="selectTrigger()" *ngIf="name && !isTemplate" class="form_item_btn" name="next_wizard">
          save
        </button>
        <button (click)="selectTrigger()" *ngIf="templateName && isTemplate" class="form_item_btn" name="next_wizard">
          save
        </button>
      </form>
      <!-- START OF CONTENT CARD CONTENT -->
    </div>

    <!-- Select Trigger -->

    <div *ngIf="triggerSelection " class="content__card">
      <app-trigger-selection title="Choose a Tigger" selectionType="flow" (messageEvent)="triggerSelected($event)">
      </app-trigger-selection>
    </div>

    <!-- <div *ngIf="triggerSelection && isTemplate" class="content__card">
        <app-trigger-selection title= "Choose a Category" selectionType="template" (messageEvent)="triggerSelected($event)"></app-trigger-selection>
    </div>-->
  </section>

  <!-- START OF CONTENT -->
  <section *ngIf="startBuilding" class="content__container" id="parent_canvasg">


    <div class="content__container_widgets" id="widget_section">
      <div class="content__container_widgets_header" id="parent_widget">
        <h1>widgets</h1>
        <i class="fas fa-angle-down"></i>
      </div>

      <div class="content__container_widgets_tile">
        <div class="content__container_widgets_tile_header accordion">
          <h1>contacts</h1>
          <i class="fas fa-plus"></i>
        </div>
        <div class="panel">
          <div class="content__container_widgets_tile_content  draggable" draggable="true" id="content_management">

            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-book"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>content management</h1>
              <p class="show_on_hover">
                Use this widget to add, remove or edit attributes related
                to your contacts
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content__container_widgets_tile">
        <div class="content__container_widgets_tile_header accordion">
          <h1>email</h1>
          <i class="fas fa-plus"></i>
        </div>

        <div class="panel">
          <div class="content__container_widgets_tile_content  draggable" draggable="true" id="FS_EMAIL"
            title="FS_EMAIL">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-envelope-open-text"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>send email</h1>
              <p class="show_on_hover">
                This widget allows you to send Emails to a user.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content__container_widgets_tile">
        <div class="content__container_widgets_tile_header accordion">
          <h1>flow control</h1>
          <i class="fas fa-plus"></i>
        </div>

        <div class="panel">
          <div class="content__container_widgets_tile_content  draggable" draggable="true" id="branch"
            title="FS_BRANCH">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-code-branch"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>branch</h1>
              <p class="show_on_hover">
                Branch widget allows you to split your flow and connect
                to specific widgets based on user conditions.
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content  draggable" draggable="true" id="FS_GO_TO_FLOW">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-code-branch"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>go to flow</h1>
              <p class="show_on_hover">
                This widget allows you to trigger another flow that
                is already running in the account
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content  draggable" draggable="true" id="FS_JUMP_TO"
            title="FS_JUMP_TO">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-forward"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>jump to</h1>
              <p class="show_on_hover">
                Jump to any other widget of the flow
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_SET_VARIABLES"
            title="FS_SET_VARIABLES">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-code"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>set variables</h1>
              <p class="show_on_hover">
                Set variables allows you to save key values pairs in the
                global context of the flow's execution.

              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="wait"
            title="FS_TIMER_WAIT">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-hourglass-start"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>wait</h1>
              <p class="show_on_hover">
                Use this widget to set a hold in the flow. It means the next
                widget will be executed once the wait time is reached.
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="end" title="FS_END">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-stop"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>end</h1>
              <p class="show_on_hover">
                Use this widget to end a flow. It means the next
                widget will not be executed.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content__container_widgets_tile">
        <div class="content__container_widgets_tile_header accordion">
          <h1>functions</h1>
          <i class="fas fa-plus"></i>
        </div>

        <div class="panel">
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_API_CALL"
            title="FS_API_CALL">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-laptop-code"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>api request</h1>
              <p class="show_on_hover">
                Use this widget to make an API request to an external API.
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-cube"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>custom widget</h1>
              <p class="show_on_hover">
                coming soon...
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_WAIT_FOR_WEBHOOK"
            title="FS_WAIT_FOR_WEBHOOK">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-th-large"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>web hook</h1>
              <p class="show_on_hover">
                Use this widget to recieve new variables via a webhook
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content__container_widgets_tile">
        <div class="content__container_widgets_tile_header accordion">
          <h1>sms</h1>
          <i class="fas fa-plus"></i>
        </div>

        <div class="panel">
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_SMS" title="FS_SMS">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-sms"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>send sms</h1>
              <p class="show_on_hover">
                Send message allows you to send a message to a user.
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_SAY_TEXT"
            title="FS_SAY_TEXT">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-comments"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>inbound sms</h1>
              <p class="show_on_hover">
                Gather input as SMS allows you to wait for a reply and collect the users
                response.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content__container_widgets_tile">
        <div class="content__container_widgets_tile_header accordion">
          <h1>messaging</h1>
          <i class="fas fa-plus"></i>
        </div>

        <div class="panel">
          <!-- <div class="content__container_widgets_tile_content draggable" id="send_facebook_message" draggable="true">
              <div class="content__container_widgets_tile_content_icon">
                  <i class="fab fa-facebook"></i>
              </div>

              <div class="content__container_widgets_tile_content_info">
                  <h1>send facebook message</h1>
                  <p>
                      Use this widget to send messages to facebook's messenger app.
                  </p>
              </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" id="send_twitter_message" draggable="true">
              <div class="content__container_widgets_tile_content_icon">
                  <i class="fab fa-twitter"></i>
              </div>

              <div class="content__container_widgets_tile_content_info">
                  <h1>send twitter message</h1>
                  <p>
                      Use this widget to send a message to the twitter app.
                  </p>
              </div>
          </div> -->
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_WHATSAPP"
            title="FS_WHATSAPP">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fab fa-whatsapp"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>send whatsapp message</h1>
              <p class="show_on_hover">
                Use this widget to send a message to the whatsapp app.
              </p>
            </div>
          </div>

          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_NOTIFICATION"
               title="FS_NOTIFICATION">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fa fa-bell"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>send push notification</h1>
              <p class="show_on_hover">
                Use this widget to send push notification.
              </p>
            </div>
          </div>

        </div>
      </div>
      <div class="content__container_widgets_tile">
        <div class="content__container_widgets_tile_header accordion">
          <h1>voice</h1>
          <i class="fas fa-plus"></i>
        </div>

        <div class="panel">
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_VOICE"
            title="FS_VOICE">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-phone-volume"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>make a call!</h1>
              <p class="show_on_hover">
                Make outgoing calls allows you to add the contacts phone number
                within your flow.
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_PLAY_AUDIO"
            title="FS_PLAY_AUDIO">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-microphone"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>Play audio</h1>
              <p class="show_on_hover">
                Play an audio once a call is answered.
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_VOICE_COLLECT_INPUT"
            title="FS_VOICE_COLLECT_INPUT">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-microphone"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>Collect Input / Voice recognition</h1>
              <p class="show_on_hover">
                Gather Input On Call allows you to gather a user’s input while they are on call.
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_VOICE_FORWARD_CALL"
               title="FS_VOICE_FORWARD_CALL">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-microphone"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>Forward Call</h1>
              <p class="show_on_hover">
                Forward call
              </p>
            </div>
          </div>
          <div class="content__container_widgets_tile_content draggable" draggable="true" id="FS_VOICE_END_CALL"
            title="FS_VOICE_END_CALL">
            <div class="content__container_widgets_tile_content_icon">
              <i class="fas fa-microphone"></i>
            </div>

            <div class="content__container_widgets_tile_content_info">
              <h1>End Call</h1>
              <p class="show_on_hover">
                Use this widget to end a call and then trigger a widget from any other service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="canvas" id='canvas'>
    </div>

    <div class="content__container_branch">
      <div id="buttonWrapper">

        <i aria-hidden="true" class="fa-lg fas fa-desktop" id="reset" style="width: 30px;margin-right: 10px;"></i>

        <input id="plus" type="button" value="+">
        <input id="minus" type="button" value="-">
      </div>
      <div style="visibility: hidden;">
        <div class="content__container_branch_card">
          <div class="content__container_branch_card_icon --fixed">
            <i class="fas fa-rocket"></i>
          </div>
          <div class="content__container_branch_card_icon">
            <i class="fas fa-phone-alt"></i>
          </div>
          <h1 class="content__container_branch_card_title">
            Inbound voice call
          </h1>
        </div>

        <div class="content__container_branch_arrow">
          <div class="content__container_branch_arrow_dot"></div>
        </div>

        <div class="content__container_branch_card">
          <div class="content__container_branch_card_icon --blue">
            <i class="fas fa-code"></i>
          </div>
          <h1 class="content__container_branch_card_title">
            Set Variable
          </h1>
        </div>

        <div class="content__container_branch_arrow">
          <div class="content__container_branch_arrow_dot"></div>
        </div>

        <div class="content__container_branch_card">
          <div class="content__container_branch_card_icon --purple">
            <i class="far fa-play-circle"></i>
          </div>
          <h1 class="content__container_branch_card_title">
            Play Audio
          </h1>
        </div>

      </div>

    </div>

    <div class="content__container_actions" id="parent_properties" style="visibility: hidden;">
      <div class="content__container_actions_variables">
        <div (click)="getVariables()" class="content__container_actions_variables_heading" id="variables_heading">
          <uni-text-subheader>Variables</uni-text-subheader>
          <i class="fas fa-angle-down"></i>
        </div>
        <!-- <div id="close">
            <img src="/assets/svg/close.svg">
        </div> -->

        <div class="content__container_actions_variables_info panel" id="variables_info">
          <div class="variable-info-text">
            <uni-text-info>Drag variables into the parameters' fields</uni-text-info>
          </div>
          <ng-container *ngFor="let variable of variableListForDisplay">
            <div class="content__container_actions_variables_info_badge" draggable="true" ondragstart="dragStart(event)"
              title="{{variable}}">
              <p>{{variable}}</p>
            </div>
          </ng-container>

        </div>
      </div>

      <div class="content__container_actions_params">
        <div class="content__container_actions_params_heading" id="close_properties" style="cursor: pointer;">
          <h1>parameters</h1>
          <i class="fas fa-times"></i>
        </div>
        <div class="content__container_actions_params_tile">
          <h1 id="properties_name">incoming voice call</h1>
          <i (click)="showWidgetNameModal()" class="fas fa-edit"></i>
        </div>

        <form class="content__container_actions_params_form" id="properties_id" onsubmit="return false">


          <!-- SMS Variables -->
          <div class="form_item border_bottom" id="variable_sms_app_id" name="variable_input">
            <label for="sms_app_id">
              Application ID <i class="fas fa-question"></i>
            </label>
            <div class="editable_content" contenteditable="true" id="sms_app_id" placeholder="Enter application ID"
              type="text">
              w5n1ZdDRjivDZYyY850AOrcOLThyaX
            </div>
            <div class="invisible text-danger mt-1" id="sms_app_id_validation">
              <span>Application ID is required!</span>
            </div>
          </div>
          <div class="form_item border_bottom" id="variable_sms_sender_id" name="variable_input">
            <label for="sms_sender_id">
              Sender ID
            </label>
            <div class="editable_content" contenteditable="true" id="sms_sender_id" placeholder="Enter sender ID"
              type="text">
              Unifonic
            </div>
            <div class="invisible text-danger mt-1" id="sms_sender_id_validation">
              <span>Sender ID is required!</span>
            </div>
          </div>

          <div class="form_item border_bottom" id="variable_sms_recipient" name="variable_input">
            <label for="sms_recipient">
              Destination number
            </label>
            <div class="editable_content" contenteditable="true" id="sms_recipient" placeholder="Enter sender ID"
              type="text"></div>
            <div class="invisible text-danger mt-1" id="sms_recipient_validation">
              <span>Destination number is required!</span>
            </div>
          </div>

          <div class="form_item border_bottom" id="variable_sms_message_text" name="variable_input">
            <label for="sms_message_text">
              Message Body
            </label>
            <div class="editable_content multiline" contenteditable="true" id="sms_message_text"></div>
            <div class="invisible text-danger mt-1" id="sms_message_text_validation">
              <span>Message body is required!</span>
            </div>
          </div>

          <!-- Whatsapp -->
          <div class="form_item border_bottom" id="variable_whatsapp_public_id" name="variable_input">
            <label for="whatsapp_public_id">
              Public Id
            </label>
            <div class="editable_content" contenteditable="true" id="whatsapp_public_id" type="text">
              a1eaf649-5df2-426d-b4b8-03efa32c281e
            </div>
            <div class="invisible text-danger mt-1" id="whatsapp_public_id_validation">
              <span>Sender ID is required!</span>
            </div>
          </div>
          <div class="form_item border_bottom" id="variable_whatsapp_secret" name="variable_input">
            <label for="whatsapp_secret">
              Secret
            </label>
            <div class="editable_content" contenteditable="true" id="whatsapp_secret" type="text">
              tj9lWNsAcUwN6KTOwaLKbilecdAHEdJyExo0rDzwZvvzIgWkEefVTvIwSqZwkGMtIHTCW9F2v63
            </div>
          </div>
          <div class="form_item border_bottom" id="variable_whatsapp_template_name" name="variable_input">
            <label for="whatsapp_template_name">
              Template Name
            </label>
            <div class="editable_content" contenteditable="true" id="whatsapp_template_name" type="text">
              dev_test_non_media_shipping_template
            </div>
          </div>
          <div class="form_item border_bottom" id="variable_whatsapp_template_namespace" name="variable_input">
            <label for="whatsapp_template_namespace">
              Template Namespace
            </label>
            <div class="editable_content" contenteditable="true" id="whatsapp_template_namespace" type="text">
              0fc23cf2_5207_42fb_b5d7_751574f8dee9
            </div>
          </div>
          <div class="form_item border_bottom" id="variable_whatsapp_recipient" name="variable_input">
            <label for="whatsapp_recipient">
              Destination number
            </label>
            <div class="editable_content" contenteditable="true" id="whatsapp_recipient" type="text">
            </div>
          </div>
          <div class="form_item border_bottom" id="variable_whatsapp_message_text" name="variable_input">
            <label for="whatsapp_message_text">
              Message Body
            </label>
            <div class="editable_content multiline" contenteditable="true" id="whatsapp_message_text">
            </div>
          </div>

          <!-- Wait -->
          <div class="form_item border_bottom" id="variable_wait_seconds" name="variable_input">
            <label for="wait_seconds">
              Wait time (in seconds)
            </label>
            <div class="editable_content" contenteditable="true" id="wait_seconds"></div>
          </div>
          <div class="form_item border_bottom" id="variable_wait_minutes" name="variable_input">
            <label for="wait_minutes">
              Wait time (in minutes)
            </label>
            <div class="editable_content" contenteditable="true" id="wait_minutes"></div>
          </div>
          <div class="form_item border_bottom" id="variable_wait_hours" name="variable_input">
            <label for="wait_hours">
              Wait time (in hours)
            </label>
            <div class="editable_content" contenteditable="true" id="wait_hours"></div>
          </div>

          <!-- Voice -->

          <!-- Voice Make a call -->
          <div class="form_item border_bottom" id="variable_voice_application_name" name="variable_input">
            <label for="voice_application_name">
              Application names<i class="fas fa-question"></i>
            </label>
            <select (click)="getPhoneNumber($event.target.value)" id="voice_application_name">
              <option value="">Please choose application</option>
              <option *ngFor="let application of voiceApplications" [value]="application.applicationKey">
                {{application.name}}
              </option>
            </select>
            <div class="invisible text-danger mt-1" id="voice_application_name_validation">
              <span>Application name is required!</span>
            </div>
          </div>
          <div class="form_item border_bottom" id="variable_voice_application_key" name="variable_input">

            <label for="voice_application_key">
              Apps ID<i class="fas fa-question"></i>
            </label>
            <input [value]="applicationKey" id="voice_application_key" readonly="readonly" type="text">
            <div class="invisible text-danger mt-1" id="voice_application_key_validation">
              <span>Application key is required!</span>
            </div>
          </div>
          <div class="form_item border_bottom" id="variable_voice_caller_id" name="variable_input">
            <label for="voice_caller_id">
              Caller id<i class="fas fa-question"></i>
            </label>
            <input [value]="phoneNumber" id="voice_caller_id" readonly="readonly" type="text">
            <div class="invisible text-danger mt-1" id="voice_caller_id_validation">
              <span>Caller id is required!</span>
            </div>
            <p>Don not have an caller ID?</p>
            <span>Request a caller ID</span>
          </div>

          <div class="form_item border_bottom" id="variable_voice_call_destination" name="variable_input">
            <label for="voice_call_destination">
              Call destination<i class="fas fa-question"></i>
            </label>
            <div class="editable_content" contenteditable="true" id="voice_call_destination" type="number"></div>
            <div class="invisible text-danger mt-1" id="voice_call_destination_validation">
              <span>Call destination is required!</span>
            </div>
          </div>

          <!-- Voice play audio -->
          <div class="form_item border_bottom" id="variable_voice_play_audio_name" name="variable_input">
            <label for="voice_play_audio_name">
              Select the audio <i class="fas fa-question"></i>
            </label>
            <select (click)="getAudioUrl($event.target.value)" id="voice_play_audio_name">
              <option value="">Please choose audio</option>
              <option *ngFor="let audio of voiceAudioList" [value]="audio.audioName">
                {{audio.audioName}}
              </option>
            </select>
            <div class="invisible text-danger mt-1" id="voice_play_audio_name_validation">
              <span>Audio is required!</span>
            </div>
          </div>

          <div id="variable_voice_play_audio_url" name="variable_input" style="display: none;">
            <input [value]="audioUrl" id="voice_play_audio_url" readonly="readonly" style="display: none;" type="text">
          </div>

          <div class="form_item border_bottom" id="variable_voice_play_audio_loop_count" name="variable_input">
            <label for="voice_play_audio_loop_count">
              Number of loops<i class="fas fa-question"></i>
            </label>
            <div class="editable_content" contenteditable="true" id="voice_play_audio_loop_count" type="number"></div>
            <div class="invisible text-danger mt-1" id="voice_play_audio_loop_count_validation">
              <span>Play audio loop count is required!</span>
            </div>
          </div>

          <!-- Voice collect input -->
          <div class="form_item border_bottom" id="variable_voice_collect_play_audio_name" name="variable_input">
            <label for="voice_collect_play_audio_name">
              Select the audio <i class="fas fa-question"></i>
            </label>
            <select (click)="getAudioUrl($event.target.value)" id="voice_collect_play_audio_name">
              <option value="">Please choose audio</option>
              <option *ngFor="let audio of voiceAudioList" [value]="audio.audioName">
                {{audio.audioName}}
              </option>
            </select>
            <div class="invisible text-danger mt-1" id="voice_collect_play_audio_name_validation">
              <span>Audio is required!</span>
            </div>
          </div>

          <div id="variable_voice_collect_play_audio_url" name="variable_input" style="display: none;">
            <input [value]="audioUrl" id="voice_collect_play_audio_url" readonly="readonly" style="display: none;"
              type="text">
            <div class="invisible text-danger mt-1" id="variable_voice_collect_play_audio_url_validation">
              <span>Audio URL is required!</span>
            </div>
          </div>

          <div class="form_item border_bottom" id="variable_voice_collect_wait_for_response_seconds"
            name="variable_input">
            <label for="voice_collect_wait_for_response_seconds">
              Timeout in seconds<i class="fas fa-question"></i>
            </label>
            <div class="editable_content" contenteditable="true" id="voice_collect_wait_for_response_seconds"
              type="number"></div>
            <div class="invisible text-danger mt-1" id="voice_collect_wait_for_response_seconds_validation">
              <span>Timeout is required!</span>
            </div>
          </div>

          <!-- Goto -->
          <div class="form_item border_bottom" id="variable_goto_process_definition_id" name="variable_input">
            <label for="goto_process_definition_id">
              Goto Flow
            </label>
            <select id="goto_process_definition_id">
              <option value="">Choose a flow</option>
              <option *ngFor="let flowVersion of flowVersionsModel" value={{flowVersion.processDefinitionId}}>
                {{flowVersion.startExecutionName}}
              </option>
            </select>
            <div class="invisible text-danger mt-1" id="goto_process_definition_id_validation">
              <span>Flow is required!</span>
            </div>
          </div>


          <!-- Branch properties -->
          <div class="form_item border_bottom" id="variable_branch_variable" name="variable_input">
            <label for="branch_variable">
              Variable Name
            </label>
            <div class="editable_content" contenteditable="true" id="branch_variable"></div>
            <div class="invisible text-danger mt-1" id="branch_variable_validation">
              <span>Variable is required!</span>
            </div>
          </div>

          <div class="form_item border_bottom" id="variable_branch_operator" name="variable_input">
            <label for="branch_operator">
              Operator
            </label>
            <select id="branch_operator">
              <option value=">">Greater than</option>
              <option value="<">Less than</option>
              <option value="==">Equals</option>
              <option value="contains">Contains</option>
            </select>
          </div>

          <div class="form_item border_bottom" id="variable_branch_value" name="variable_input">
            <label for="branch_value">
              Value
            </label>
            <div class="editable_content" contenteditable="true" id="branch_value"></div>
            <div class="invisible text-danger mt-1" id="branch_value_validation">
              <span>Value is required!</span>
            </div>
          </div>
          <div class="form_item border_bottom" id="variable_branch_component" name="variable_input">
            <label for="branch_component">
              Component
            </label>
            <select id="branch_component">
              <option value="FS_EMAIL">Email</option>
              <option value="FS_TIMER_WAIT">Wait</option>
              <option value="FS_API_CALL">API Call</option>
              <option value="FS_WAIT_FOR_WEBHOOK">FS_WAIT_FOR_WEBHOOK</option>
              <option value="FS_SMS">SMS</option>
              <option value="FS_WHATSAPP">WHATSAPP</option>
              <option value="FS_VOICE">Voice</option>
              <option value="FS_PLAY_AUDIO">Play Audio</option>
              <option value="FS_JUMP_TO">Jump To</option>
              <option value="FS_VOICE_COLLECT_INPUT">Collect Input</option>
              <option value="FS_VOICE_FORWARD_CALL">Forward Call</option>
              <option value="FS_VOICE_END_CALL">End Call</option>
              <option value="FS_NOTIFICATION">NOTIFICATION</option>

            </select>
          </div>

          <div class="form_item border_bottom" id="variable_branch_else_component" name="variable_input">
            <label for="branch_else_component">
              Else Component
            </label>
            <select id="branch_else_component">
              <option value="FS_EMAIL">Email</option>
              <option value="FS_TIMER_WAIT">Wait</option>
              <option value="FS_API_CALL">API Call</option>
              <option value="FS_WAIT_FOR_WEBHOOK">FS_WAIT_FOR_WEBHOOK</option>
              <option value="FS_SMS">SMS</option>
              <option value="FS_WHATSAPP">WHATSAPP</option>
              <option value="FS_VOICE">Voice</option>
              <option value="FS_PLAY_AUDIO">Play Audio</option>
              <option value="FS_JUMP_TO">Jump To</option>
              <option value="FS_VOICE_COLLECT_INPUT">Collect Input</option>
              <option value="FS_VOICE_FORWARD_CALL">Forward Call</option>
              <option value="FS_VOICE_END_CALL">End Call</option>
              <option value="FS_NOTIFICATION">NOTIFICATION</option>
            </select>
          </div>

          <!-- Jump to properties -->

          <div class="form_item border_bottom" id="variable_jump_to_max_loop_count" name="variable_input">
            <label for="jump_to_max_loop_count">
              Number of loops
            </label>
            <div class="editable_content" contenteditable="true" id="jump_to_max_loop_count"></div>
            <div class="invisible text-danger mt-1" id="jump_to_max_loop_count_validation">
              <span>Number of loops is required!</span>
            </div>
          </div>
          <div class="form_item border_bottom hide" id="variable_jump_to_destination_type" name="variable_input">
            <label for="jump_to_destination_type">
              Destination type
            </label>
            <input disabled="true" id="jump_to_destination_type" placeholder="Destination type" type="text">
          </div>
          <div class="form_item border_bottom hide" id="variable_jump_to_destination_id" name="variable_input">
            <label for="jump_to_destination_id">
              Destination Id
            </label>
            <input disabled="true" id="jump_to_destination_id" placeholder="Destination Id" type="text">
          </div>
          <div class="form_item border_bottom hide" id="variable_jump_to_destination_color" name="variable_input">
            <label for="jump_to_destination_color">
              Color
            </label>
            <input disabled="true" id="jump_to_destination_color" placeholder="Destination color" type="text">
          </div>

          <!-- Wait for webhooks -->

          <div class="form_item border_bottom" id="variable_wait_for_webhook_seconds" name="variable_input">
            <ng-container *ngIf="flowStatus == 'DRAFT'">
              <uni-section>
                <div class="card bg-light col-md-12">
                  <div class="card-body">
                    <h5 class="card-title">Webhook URL</h5>
                    <p class="card-text">A URL will be generated once your flow is published.</p>
                  </div>
                </div>
              </uni-section>
              <uni-section>
                <div class="card bg-light col-md-12">
                  <div class="card-body">
                    <h5 class="card-title">Authentication</h5>
                    <p>To trigger this flow, send the JWT token generated by calling our
                      authentication
                      API. This token should be embedded in each HTTP request.</p>
                  </div>
                </div>
              </uni-section>
            </ng-container>
            <ng-container *ngIf="flowStatus == 'ACTIVE'">
              <uni-section>
                <h5 class="card-title">Webhook URL</h5>
                <div class="input-group mt-4">
                  <input #formInputText class="form-control border-right-0 webhook-url" readonly="readonly" type="text"
                    value="{{webhookUrl}}">
                  <span class="input-group-append bg-white">
                    <button [ngxClipboard]="formInputText" class="btn border border-left-0 webhook-url-button"
                      type="button">
                      <i class="far fa-copy"></i></button>
                  </span>
                </div>
                <uni-link (click)="showPostRequest()">SHOW POST REQUEST</uni-link>
              </uni-section>
            </ng-container>
            <uni-section>
              <label for="wait_for_webhook_seconds">
                Wait time (in seconds)
              </label>
              <div class="editable_content" contenteditable="true" id="wait_for_webhook_seconds"></div>
              <div class="invisible text-danger mt-1" id="wait_for_webhook_seconds_validation">
                <span>Wait time is required!</span>
              </div>
            </uni-section>
            <uni-separator></uni-separator>
            <app-widget-variable (variableEvent)="addNewVariable($event)"></app-widget-variable>
          </div>

          <div class="form_item border_bottom" id="variable_api_request" name="variable_input">
            <div class="">
              <label for="api_call_method">Method</label>
              <select class="" id="api_call_method">
                <option value="POST">POST</option>
                <option value="GET">GET</option>
              </select>
            </div>
            <uni-separator></uni-separator>
            <div class="">
              <label for="api_call_url">URL</label>
              <div class="editable_content droptargetVariable" contenteditable="true" id="api_call_url" type="text">
              </div>
              <div class="invisible text-danger mt-1" id="api_call_url_validation">
                <span>URL is required!</span>
              </div>
            </div>
            <uni-separator></uni-separator>
            <div class="">
              <label for="api_call_authentication_type">Authentication</label>
              <select class="" id="api_call_authentication_type" name="variable_api_authentication">
                <option>POST</option>
              </select>
            </div>
            <uni-separator></uni-separator>
            <div class="">
              <label for="api_call_header_type">Header</label>
              <div class="editable_content droptargetVariable" contenteditable="true" id="api_call_header_type"
                name="variable_api_header" type="text"></div>
            </div>
            <uni-separator></uni-separator>
            <div class="">
              <label for="api_call_body">Body</label>
              <div class="editable_content droptargetVariable" contenteditable="true" id="api_call_body"
                name="api_call_body" type="text"></div>
            </div>
            <uni-separator></uni-separator>
            <app-widget-variable (variableEvent)="addNewVariable($event)"></app-widget-variable>
          </div>


          <!-- Buttons -->
          <!-- <button class="form_item_btn" id="add-branch-button" name="variable_input">Add to branch</button> -->
          <!-- <button class="form_item_btn" id="save-button" name="variable_input">save</button> -->
          <!-- <button class="form_item_btn" id="jump-to-destination" name="variable_input">Select destination</button> -->


        </form>

      </div>
    </div>
  </section>
  <!-- END OF CONTENT -->
</main>


<uni-modal [isActive]="isCreateModalActive">
  <uni-modal-content>
    <uni-text-body>Confirmation Alert
      <uni-icon><i class="fa fa-exclamation-circle fa-5"></i></uni-icon>
    </uni-text-body>
    <p>Are you sure you want to <strong>save as draft?</strong> Please confirm!</p>
    <h4 class="text-center"></h4>
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button (click)="hideCreateFlowConfirmation()" [style]="'secondary'">
      Cancel
    </uni-button>
    <uni-button (click)="createFlow()">
      Save
    </uni-button>
  </uni-modal-footer>
</uni-modal>

<uni-modal [isActive]="isPublishModalActive">
  <uni-modal-content>
    <uni-text-body>Confirmation Alert
      <uni-icon><i class="fa fa-exclamation-circle fa-5"></i></uni-icon>
    </uni-text-body>
    <p>Are you sure you want to <strong>publish this flow?</strong> Please confirm!</p>
    <h4 class="text-center"></h4>
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button (click)="hidePublishFlowConfirmation()" [style]="'secondary'">
      Cancel
    </uni-button>
    <uni-button (click)="deployFlow()">
      Publish
    </uni-button>
  </uni-modal-footer>
</uni-modal>


<uni-modal *ngIf="flow" [isActive]="isTestFlowModalActive">
  <div class="reviews-list">
    <div class="review-card">
      <div class="review-header">
        <div class="name-group">
          <p>{{name}} v {{flowVersion}}</p>
        </div>
        <div class="rating">
          Inbound : Whatsapp
        </div>
      </div>
      <div class="review-description">
        Test your flow by providing the expected values. This is exactly how you would be executing the flow.
      </div>
      <div class="review-description">
        <div class="test-variable">
          <label>
            Set Variable
          </label>
        </div>
        <div class="test-variable">

          <select [(ngModel)]="testVariableName" name="testVariableName">
            <option value="">Select Variable</option>
            <option *ngFor="let flowVariable of flowVariables" value={{flowVariable}}>{{flowVariable}}
            </option>
          </select>
          <input [(ngModel)]="testVariableValue" name="testVariableValue" placeholder="Value" type="text">
          <i (click)="addTestVariable()" class="fas fa-plus"></i>
        </div>
        <div *ngIf="testFlowVariables.length > 0">
          <h5>Variable List</h5>
          <table class="table">
            <thead>
              <tr>
                <th>Variable</th>
                <th>Value</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody *ngFor="let testFlowVariable of testFlowVariables">
              <tr>
                <td>{{testFlowVariable.name}}</td>
                <td>{{testFlowVariable.value}}</td>
                <td><i (click)="removeTestVariable(testFlowVariable.name)" class="fas fa-trash"></i>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>

    </div>
  </div>
  <uni-modal-footer>
    <uni-button (click)="hideTestFlowModal()" [style]="'secondary'">
      Cancel
    </uni-button>
    <uni-button (click)="testFlowExecution()">
      Execute
    </uni-button>
  </uni-modal-footer>
</uni-modal>


<uni-modal [isActive]="isSetWidgetNameModalActive">
  <uni-modal-content *ngIf="widgetComponent">
    <uni-text-body>Edit Widget Name
      <uni-icon [name]="'fa-exclamation-circle fa-5'" [style]="'fa'"></uni-icon>
    </uni-text-body>
    <p>Update widget name <strong>{{widgetComponent.name}}</strong>, type : {{widgetComponent.type}}!</p>
    <h4 class="text-center"></h4>
    <input [(ngModel)]="widgetName" name="widgetName" placeholder="Name" type="text">
  </uni-modal-content>

  <uni-modal-footer>
    <uni-button (click)="hideWidgetNameModal()" [style]="'secondary'">
      Cancel
    </uni-button>
    <uni-button (click)="changeWidgetName()">
      Change
    </uni-button>
  </uni-modal-footer>
</uni-modal>


<uni-modal *ngIf="flow" [isActive]="isFlowVersionsModalActive">
  <uni-modal-content>
    <div>
      {{name}} ({{flow.flowVersions.length}} versions)
    </div>
    <div class="div-table">
      <ng-container *ngFor="let flowVersion of flow.flowVersions">
        <hr />
        <mat-grid-list cols="5" rowHeight="50px">
          <mat-grid-tile>
            <div class=" flow-name">
              v {{flowVersion.flowVersion}}
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class=" version-date">
              {{flowVersion.createdAt | date : 'dd MMM, yyyy'}}
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class=" version-date">{{flowVersion.createdAt | date : 'hh:mm'}}</div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="version-status">
              <span
                [ngClass]="{'btn-outline-success':flowVersion.flowStatus === 'ACTIVE','btn-outline-primary':flowVersion.flowStatus === 'DRAFT','btn-outline-warning':flowVersion.flowStatus === 'FINALISING'}"
                class="btn ">
                {{flowVersion.flowStatus}}</span>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="">
              <uni-link [path]="'studio?flowId=' + flowId + '&flowVersion=' + flowVersion.flowVersion + ''">
                <uni-icon [name]="'edit'"></uni-icon>
              </uni-link>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </ng-container>
    </div>
    <h4 class="text-center"></h4>
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button (click)="hideFlowVersionsModal()" [style]="'secondary'">
      Close
    </uni-button>

  </uni-modal-footer>
</uni-modal>


<uni-modal [isActive]="isModalActive">
  <uni-modal-content *ngIf="webhookUrl.length">
    <h2 class="text-center">YOUR WEBHOOK URL</h2>
    <div class="input-group mt-4">
      <input #formInputText class="form-control border-right-0 webhook-url" readonly="readonly" type="text"
        value="{{webhookUrl}}">
      <span class="input-group-append">
        <button [ngxClipboard]="formInputText" class="btn border border-left-0 webhook-url-button" type="button">
          <i class="far fa-copy"></i></button>
      </span>
    </div>
    <div class="text-center mt-3">Use this URL to trigger your flow.</div>
    <div class="card mt-5 bg-light rounded">
      <div class="card-body webhook-curl">
        curl -XPOST -H "Content-type: application/json" -d '&#123;
        "deploymentId": "{{flowDeploymentId}}",
        "startExecutionName": "{{flowExecutionName}}",
        "requestParams": &#123;&#125;
        &#125;' '{{webhookUrl}}'
      </div>
    </div>
    <uni-link (click)="hidePostRequest()">Close</uni-link>
  </uni-modal-content>

</uni-modal>
