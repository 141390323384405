import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UniSharedModule } from '@unifonic/common';

import { AuthGuard } from './shared/auth.guard';
import { AuthRepository } from './shared/auth.repository';
import { AuthEffects } from './store/auth.effects';
import { authReducer } from './store/auth.reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UniSharedModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [
    AuthGuard,
    AuthRepository
  ]
})
export class AuthModule { }
