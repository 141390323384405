import {NotificationApplicationModel} from '@flows/shared/notification/notification.model';

export const NOTIFICATION_KEY = 'notification';

export interface NotificationState {
  notificationApplicationModel: NotificationApplicationModel[];
}

export const initialNotificationState: NotificationState = {
  notificationApplicationModel: [],
};
