import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplatesListComponent } from './modules/templates-list/containers/templates-list/templates-list.component';
import {TemplatesUserGuard} from '@templates/modules/templates-list/shared/templates-user.guard';

const routes: Routes = [
  {
    path: 'template-list',
    component: TemplatesListComponent,
    canActivate: [TemplatesUserGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplatesRoutingModule {}
