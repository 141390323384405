import {Injectable} from '@angular/core';
import {UniAuthFacade, Permission} from '@unifonic/common';
import {Features} from '@shared/features';

@Injectable({providedIn: 'root'})
export class FlowsPermissionsService {

  constructor(private uniAuthFacade: UniAuthFacade) {
  }

  canSee(): boolean {
    return this.uniAuthFacade.hasSomePermission(Features.FLOWS, [
      Permission.seeOwn,
      Permission.see,
    ]);
  }

  canCreate(): boolean {
    return this.uniAuthFacade.hasSomePermission(Features.FLOWS, [
      Permission.create,
      Permission.createOwn
    ]);
  }

  canEdit(): boolean {
    return this.uniAuthFacade.hasSomePermission(Features.FLOWS, [
      Permission.editOwn
    ]);
  }
}
