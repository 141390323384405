import * as NotificationActions from './notification.actions';
import {
  NotificationState, initialNotificationState
} from '@flows/store/notification/notification.state';


export function notificationReducer(
  state = initialNotificationState,
  action: NotificationActions.All,
): NotificationState {
  switch (action.type) {
    case NotificationActions.SET_NOTIFICATION_APPLICATIONS_SUCCESS: {
      return {
        ...state,
        notificationApplicationModel: action.response
      };
    }
    default: {
      return {...state};
    }
  }
}
