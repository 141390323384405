<!-- Example: tabs -->
<uni-section>
  <uni-box-tabs>
    <uni-box-tab
      [isActive]="activeTab === 'first'"
      (click)="activeTab = 'first'"
    >
      First tab
    </uni-box-tab>
    <uni-box-tab
      [isActive]="activeTab === 'second'"
      (click)="activeTab = 'second'"
    >
      Second tab
    </uni-box-tab>
  </uni-box-tabs>

  <uni-box *ngIf="activeTab === 'first'">
    <uni-box-content>
      <uni-section-header size="medium">
        <uni-text-caption>
          Header 2
        </uni-text-caption>
      </uni-section-header>

      Description 2

    </uni-box-content>
    <uni-box-footer>
      Buttons/Actions
    </uni-box-footer>
  </uni-box>

  <uni-box *ngIf="activeTab === 'second'">
    <uni-box-content>
      Second tab
    </uni-box-content>
  </uni-box>
</uni-section>

<!-- Example: buttons -->
<uni-section>
  <uni-buttons [isFlexEnd]="true">
    <uni-button [isMargin]="true">
      Button 1
    </uni-button>
    <uni-button [isMargin]="true" [style]="'secondary'">
      Button 2
    </uni-button>
    <uni-button [isMargin]="true" [style]="'link'">
      Button 3
    </uni-button>
  </uni-buttons>
</uni-section>
