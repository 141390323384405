import { concat, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { uniSnackbarActions } from '@unifonic/common';
import * as TemplatesActions from '@templates/store/templates.actions';
import { TemplatesRepository } from '@templates/shared/templates.repository';

@Injectable()
export class TemplatesEffects {
  constructor(
    private actions$: Actions,
    private templatesRepository: TemplatesRepository,
  ) { }

  @Effect() setUserTemplates$ = this.actions$
    .pipe(
      ofType<TemplatesActions.SetUserTemplates>(TemplatesActions.SET_USER_TEMPLATES),
      mergeMap(({id, params}) => this.templatesRepository
        .getUserTemplates(id, params)
        .pipe(
          mergeMap(response => concat(
              of(new TemplatesActions.SetUserTemplatesSuccess(response.body)),
            ),
          ),
          catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault'))),
        )
      )
    );

  @Effect() deleteTemplate$ = this.actions$
    .pipe(
      ofType<TemplatesActions.DeleteTemplate>(TemplatesActions.DELETE_TEMPLATE),
      mergeMap(({ templateId, accountId, params }) => this.templatesRepository
        .deleteTemplate(templateId, accountId)
        .pipe(
          mergeMap(() => concat(
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.templateDeleted')),
            of(new TemplatesActions.SetUserTemplates(accountId, params)),
          )),
          catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')) ),
        )
      )
    );

    @Effect() setTemplate$ = this.actions$
    .pipe(
      ofType<TemplatesActions.SetTemplate>(TemplatesActions.SET_TEMPLATE),
      mergeMap(({ id }) => this.templatesRepository
        .getTemplate(id)
        .pipe(
          mergeMap(response => of(new TemplatesActions.SetTemplateSuccess(response.body))),
          catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.entityNotFound')) ),
        )
      )
    );

    @Effect() saveTemplate$ = this.actions$
    .pipe(
      ofType<TemplatesActions.SaveTemplate>(TemplatesActions.SAVE_TEMPLATE),
      mergeMap(({ template }) => this.templatesRepository
        .saveTemplate(template)
        .pipe(
          mergeMap(response => of(new TemplatesActions.SaveTemplateSuccess(response.body))),
          catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')) ),
        ),
      )
    );

    @Effect() updateTemplate$ = this.actions$
    .pipe(
      ofType<TemplatesActions.UpdateTemplate>(TemplatesActions.UPDATE_TEMPLATE),
      mergeMap(({ template }) => this.templatesRepository
        .updateTemplate(template)
        .pipe(
          mergeMap(response => of(new TemplatesActions.UpdateTemplateSuccess(response.body))),
          catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')) ),
        )
      )
    );
}
