import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { LOGIN_KEY } from '../store/login.state';
import { DefaultAccount, Workspace } from './login.model';
import { httpEncoder } from '../../../utils/http.utils';
import { Environment } from '../../../utils/environment.utils';

@Injectable()
export class LoginRepository {
  apiUrl = `${Environment.getMainHost(
    Environment.APP.CONSOLE
  )}/app/api/v2/users`;
  apiUrlV3 = `${Environment.getMainHost(
    Environment.APP.CONSOLE
  )}/app/api/v3/users`;

  constructor(private http: HttpClient) {}

  getWorkspaces(
    id: string,
    params: Params = {}
  ): Observable<HttpResponse<Workspace[]>> {
    return this.http.get<Workspace[]>(
      `${this.apiUrl}/${id}/account-switcher/workspaces`,
      {
        params: httpEncoder(params),
        observe: 'response',
        headers: {
          queryName: 'getWorkspaces',
          queryGroups: [LOGIN_KEY],
        },
      }
    );
  }

  putSelectedAccount(
    userId: string,
    accountId: string
  ): Observable<DefaultAccount> {
    return this.http.put<DefaultAccount>(
      `${this.apiUrlV3}/${userId}/account-access/selected-accounts/${accountId}`,
      {},
      {
        headers: {
          queryName: 'putSelectedAccount',
          queryGroups: [LOGIN_KEY],
        },
      }
    );
  }
}
