import {Component, OnInit, Input} from '@angular/core';
import FlowTemplatesService from '../flow-templates-service';
import {UniTextColor} from '../../../../unifonic-common/src';
import TemplateModel from '../../models/template-model';

@Component({
  selector: 'app-template-list-table',
  templateUrl: './template-list-table.component.html',
  styleUrls: ['./template-list-table.component.scss']
})
export class TemplateListTableComponent implements OnInit {

  templatesList: TemplateModel[] = [];
  uniTextColor = UniTextColor;
  isLoaderActive: boolean;

  constructor(private templateService: FlowTemplatesService) {
  }

  @Input()
  status: string;

  @Input()
  count: number;

  ngOnInit(): void {
    if (this.status === undefined) {
      this.status = 'PUBLISH';
    }
    if (this.count === undefined) {
      this.count = 3;
    }
    this.getTemplates();
  }

  ngOnChanges(changes: any) {
    if (changes.status.currentValue !== changes.status.previousValue) {
      this.ngOnInit();
    }
  }

  private getTemplates() {
    this.isLoaderActive = true;
    this.templateService.getTemplatesPaginated(0, this.count, this.status)
      .subscribe(
        response => {
          this.templatesList = response;
        },
        error => {
          this.isLoaderActive = false;
        },
        () => {
          this.isLoaderActive = false;
        }
      );
  }

  public delete(templateId: string) {
    this.isLoaderActive = true;
    if (templateId != null) {
      this.templateService.delete(templateId).subscribe(
        response => {
          this.ngOnInit();
        },
        error => {
          this.isLoaderActive = false;
        },
        () => {
          this.isLoaderActive = false;
        }
      );
    }
  }

}
