import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AudienceActions from '@flows/store/audience/audience.actions';
import { catchError, debounceTime, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AudienceRepository } from '@flows/shared/audience/audience.repository';
import { uniSnackbarActions } from '@unifonic/common';

@Injectable()
export class AudienceEffects {
  @Effect() setAudienceEvents$ = this.actions$.pipe(
    ofType<AudienceActions.SetAudienceEvents>(AudienceActions.SET_AUDIENCE_EVENTS),
    mergeMap(() =>
      this.audienceRepository.getAudienceEvents().pipe(
        mergeMap((response) => of(new AudienceActions.SetAudienceEventsSuccess(response.body))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );
  @Effect() setAudienceSources$ = this.actions$.pipe(
    ofType<AudienceActions.SetAudienceSources>(AudienceActions.SET_AUDIENCE_SOURCES),
    mergeMap(() =>
      this.audienceRepository.getAudienceSources().pipe(
        mergeMap((response) => of(new AudienceActions.SetAudienceSourcesSuccess(response.body))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );
  @Effect() setAudienceProperties$ = this.actions$.pipe(
    ofType<AudienceActions.SetAudienceProperties>(AudienceActions.SET_AUDIENCE_PROPERTIES),
    debounceTime(200),
    mergeMap(() =>
      this.audienceRepository.getAudienceProperties().pipe(
        mergeMap((response) => of(new AudienceActions.SetAudiencePropertiesSuccess(response.body))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );
  @Effect() setAudienceMappings$ = this.actions$.pipe(
    ofType<AudienceActions.SetAudienceMappings>(AudienceActions.SET_AUDIENCE_MAPPINGS),
    mergeMap(() =>
      this.audienceRepository.getAudienceMappings().pipe(
        mergeMap((response) => of(new AudienceActions.SetAudienceMappingsSuccess(response.mappings))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  constructor(private actions$: Actions, private audienceRepository: AudienceRepository) {}
}
