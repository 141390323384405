import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOGIN_KEY, LoginState } from './login.state';

export const selectLoginFeature = createFeatureSelector<LoginState>(LOGIN_KEY);

export const selectWorkspaces = createSelector(
  selectLoginFeature,
  (state: LoginState) => state.workspaces
);

export const selectWorkspacesAmount = createSelector(
  selectLoginFeature,
  (state: LoginState) => state.workspacesAmount
);
