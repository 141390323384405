import { AudienceEventModel, AudienceMapping, AudiencePropertiesModel, AudienceSourceModel } from '@flows/shared/audience/audience.model';

export const AUDIENCE_QUERY_GROUP = 'audience';

export interface AudienceState {
  audienceEvents: AudienceEventModel;
  audienceSources: AudienceSourceModel;
  audienceProperties: AudiencePropertiesModel;
  mappings: AudienceMapping[];
  selectedMapping?: AudienceMapping;
}

export const initialAudienceState: AudienceState = {
  audienceEvents: { total: 0, eventTypes: [] },
  audienceSources: { total: 0, eventSources: [] },
  audienceProperties: { properties: [], groups: [], items: {} },
  mappings: [],
};
