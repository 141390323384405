import {Injectable, Input} from '@angular/core';
import {Permission, UniAuthFacade} from '@unifonic/common';
import {Features} from '@shared/features';
import {TemplatePermissionsService} from '@templates/shared/template-permissions.service';
import {FlowsPermissionsService} from '@flows/shared/flows-permissions.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  appCheckPermissions: Permission;
  appCheckPermissionsFeature: Features;

  constructor(
    private templatePermissionService: TemplatePermissionsService,
    private flowPermissionService: FlowsPermissionsService,
    private uniAuthFacade: UniAuthFacade
  ) {
  }

  public isActionPermitted(permission, feature) {
    if (!permission || !feature) {
      return false;
    }

    this.appCheckPermissions = permission;
    this.appCheckPermissionsFeature = feature;

    let actionIsPermitted;
    switch (this.appCheckPermissions) {
      case Permission.see :
      case Permission.seeOwn :
        actionIsPermitted = this.canSee();
        break;
      case Permission.create :
      case Permission.createOwn :
        actionIsPermitted = this.canCreate();
        break;
      case Permission.edit :
        actionIsPermitted = this.canEdit();
        break;
      default:
        actionIsPermitted = false;
        break;
    }

    return actionIsPermitted;

  }

  private canSee(): boolean {
    let canSee;

    switch (this.appCheckPermissionsFeature) {
      case Features.TEMPLATE_MANAGEMENT:
        canSee = this.templatePermissionService.canSee();
        break;
      case Features.TEMPLATE_USE:
        canSee = !this.uniAuthFacade.isAdminLevel();
        break;
      case Features.FLOWS:
        canSee = this.flowPermissionService.canSee();
        break;
      case Features.DASHBOARD:
        canSee = true;
        break;
      case Features.REPORTS:
      default:
        canSee = !this.uniAuthFacade.isAdminLevel();
    }

    return canSee;
  }

  private canCreate(): boolean {
    let canCreate = false;

    switch (this.appCheckPermissionsFeature) {
      case Features.TEMPLATE_MANAGEMENT:
        canCreate = this.templatePermissionService.canCreate();
        break;
      case Features.FLOWS:
        canCreate = this.flowPermissionService.canCreate();
        break;
    }

    return canCreate;
  }

  private canEdit(): boolean {
    let canCreate = false;
    switch (this.appCheckPermissionsFeature) {
      case Features.TEMPLATE_MANAGEMENT:
        canCreate = this.templatePermissionService.canEdit();
        break;
      case Features.FLOWS:
        canCreate = this.flowPermissionService.canEdit();
        break;
    }

    return canCreate;
  }
}
