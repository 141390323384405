import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AudienceState } from '@flows/store/audience/audience.state';

export const selectAudienceFeature = createFeatureSelector<AudienceState>('audience');

export const selectAudienceEvent = createSelector(selectAudienceFeature, (state: AudienceState) => state.audienceEvents);

export const selectAudienceSource = createSelector(selectAudienceFeature, (state: AudienceState) => state.audienceSources);

export const selectAudienceProperties = createSelector(selectAudienceFeature, (state: AudienceState) => state.audienceProperties);

export const selectAudienceMappings = createSelector(selectAudienceFeature, (state: AudienceState) => state.mappings);

export const selectSelectedAudienceMapping = createSelector(selectAudienceFeature, (state: AudienceState) => state.selectedMapping);
