<uni-container>
  <uni-section [isFirst]="true">
    <uni-title-container>
      <uni-title-item>
        <uni-text-subheader
          [color]="uniTextColor.black"
          [isUppercase]="false">
          {{ "page.activationRequest.flowStudio" | translate }}
        </uni-text-subheader>
      </uni-title-item>
    </uni-title-container>
    <uni-separator [class]="'border-dark mt-0'"></uni-separator>
  </uni-section>

  <uni-section>
    <div class="row">
      <div class="col-md-6">
        <uni-section-header>
          <uni-text-subheader [isUppercase]="false"
                              class="activation-request-header">{{ "page.activationRequest.title" | translate }}</uni-text-subheader>
        </uni-section-header>
        <uni-text-body [class]="'d-block'" [isUppercase]="false"
                       [weight]="uniTextWeight.regular">{{ "page.activationRequest.description" | translate }}</uni-text-body>
        <uni-button
          [autoWidth]="true" [class]="'mt-5'" [fit]="true"
          [path]="settingsService.settings.ucUrl+'/crm/contact-sales?product=Flow Studio'" target="_blank">
          {{ "page.activationRequest.button" | translate }}
        </uni-button>
      </div>
      <div class="col-md-3 img">
        <img alt="activation-request" src="/assets/img/flow.svg"/>
      </div>
    </div>
  </uni-section>

  <uni-section>
    <div class="row">
      <div class="col-md-4 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <img alt="interact-with-customers" class="mx-auto d-block mb-3"
                 src="/assets/img/interact-with-customers.svg"/>
            <uni-text-body [isUppercase]="false" class="d-block my-3">
              {{ "page.activationRequest.firstFeature.title" | translate }}
            </uni-text-body>
            <uni-text-body [isUppercase]="false" [weight]="uniTextWeight.regular" class="d-block">
              {{ "page.activationRequest.firstFeature.description" | translate }}
            </uni-text-body>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <img alt="automate-communication-workflows" class="mx-auto d-block mb-3"
                 src="/assets/img/automate-communication-workflows.svg"/>
            <uni-text-body [isUppercase]="false" class="d-block my-3">
              {{ "page.activationRequest.secondFeature.title" | translate }}
            </uni-text-body>
            <uni-text-body [isUppercase]="false" [weight]="uniTextWeight.regular" class="d-block">
              {{ "page.activationRequest.secondFeature.description" | translate }}
            </uni-text-body>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <img alt="prebuilt-templates-or-start-from-scratch" class="mx-auto d-block mb-3"
                 src="/assets/img/prebuilt-templates-or-start-from-scratch.svg"/>
            <uni-text-body [isUppercase]="false" class="d-block my-3">
              {{ "page.activationRequest.thirdFeature.title" | translate }}
            </uni-text-body>
            <uni-text-body [isUppercase]="false" [weight]="uniTextWeight.regular" class="d-block">
              {{ "page.activationRequest.thirdFeature.description" | translate }}
            </uni-text-body>
          </div>
        </div>
      </div>
    </div>
  </uni-section>
</uni-container>
