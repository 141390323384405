import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UniAuthFacade, UniTableSelection } from '@unifonic/common';
import { Observable, of, Subject } from 'rxjs';
import { values } from 'lodash';
import { Flow, FlowVersion } from '@flows/shared/flows.model';
import { FlowsListTableColumns } from './flows-list-table.model';
import { FlowsFacade } from '@flows/shared/flows.facade';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'fs-flows-list-table',
  templateUrl: './flows-list-table.component.html',
  styleUrls: ['./flows-list-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', display: 'flex' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FlowsListTableComponent extends UniTableSelection<Flow> implements OnInit, OnDestroy {
  @ViewChild('table', { static: false }) table: ElementRef;
  @Input() params: Params;
  @Input() showPagination: boolean;
  dataSource: any = null;
  isModalActive = false;
  isFlowVersionListModalActive = false;
  isLoaderActive = true;
  unsubscribe$ = new Subject();
  columns = FlowsListTableColumns;
  totalItems = 0;
  itemsPerPage = 10;
  flowId: string;
  flowVersion: number;
  accountId: string;
  flowVersions: FlowVersion[];
  flowName: string;
  activeTab: string = null;
  expandedElement: any;
  versionedFlows: FlowVersion[] = [];
  showModal = false;
  triggerTypes: any[] = ['VOICE', 'WEBHOOK', 'INTEGRATION', 'CAMPAIGN', 'CHATBOT', 'SCHEDULE'].map((name) => ({ name, id: name }));

  constructor(
    private changeDetector: ChangeDetectorRef,
    private flowsFacade: FlowsFacade,
    private uniAuthFacade: UniAuthFacade,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  get displayedColumns(): string[] {
    return values(this.columns);
  }

  get isSearchParams(): boolean {
    const { flowName, triggerType } = this.route.snapshot.queryParams;
    return !!(flowName || triggerType);
  }

  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  ngOnInit() {
    this.initTable();
    this.initFlowVersionListener();
    this.accountId = this.uniAuthFacade.user.id;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initTable(): void {
    this.flowsFacade.userFlows$.subscribe((userFlows) => {
      if (userFlows) {
        this.dataSource = new FlowListDatasource(userFlows?.content || []);
        if (userFlows.totalElements !== undefined) {
          this.totalItems = userFlows.totalElements;
          this.isLoaderActive = false;
        }
        this.changeDetector.detectChanges();
      }
    });
  }

  onRemove() {
    this.flowsFacade.deleteFlow(this.flowId, this.accountId, this.flowVersion, this.params);
  }

  getTriggerType(input) {
    return input
      ? input
          .split('_')
          .filter((x) => x !== 'TRIGGER')
          .reverse()[0]
      : input;
  }

  openConfirmModal(id: string, version: number) {
    this.flowId = id;
    this.flowVersion = version;
    this.isModalActive = true;
  }

  toggleModal() {
    this.isModalActive = !this.isModalActive;
  }

  openFlowVersionListModal(flowId: string, flowName: string) {
    this.flowId = flowId;
    this.flowName = flowName;
    this.showModal = true;
    this.flowsFacade.setFlowVersions(this.accountId, flowId, this.params);
  }

  toggleFlowVersionListModal() {
    this.showModal = !this.isFlowVersionListModalActive;
    this.isFlowVersionListModalActive = !this.isFlowVersionListModalActive;
  }

  initFlowVersionListener(): void {
    this.flowsFacade.flowVersions$.subscribe((flowVersions) => {
      this.flowVersions = flowVersions;
      this.versionedFlows = flowVersions;

      this.changeDetector.detectChanges();
      if (this.showModal) {
        this.isFlowVersionListModalActive = true;
      }
    });
  }

  getVersionedData(flow: Flow) {
    this.isLoaderActive = true;

    if (this.expandedElement?.flowId === flow.flowId) {
      this.expandedElement = null;
      this.isLoaderActive = false;
      return;
    }
    this.expandedElement = flow;
    this.flowsFacade.setFlowVersions(this.accountId, flow.flowId, this.params);
  }

  undeployFlow(flow: Flow): void {
    this.flowsFacade.undeployFlow(flow.flowId, this.accountId, flow.version, this.params);
  }

  resetSearch() {
    return this.router.navigate([], {
      queryParams: {
        page: 1,
      },
    });
  }
}

/**
 * Data source to provide what data should be rendered in the table. The observable provided
 * in connect should emit exactly the data that should be rendered by the table. If the data is
 * altered, the observable should emit that new set of data on the stream. In our case here,
 * we return a stream that contains only one set of data that doesn't change.
 */
export class FlowListDatasource extends DataSource<Flow> {
  /** Connect function called by the table to retrieve one stream containing the data to render. */

  constructor(private data: Flow[]) {
    super();
  }

  connect(): Observable<Flow[]> {
    const rows = [];
    if (this.data) {
      this.data.forEach((element) => rows.push(element, { detailRow: true, element }));
    }

    return of(rows);
  }

  disconnect() {}
}
