import {concat, of} from 'rxjs';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {uniSnackbarActions} from '@unifonic/common';
import * as VoiceActions from './voice.actions';
import {VoiceRepository} from "@flows/shared/voice/voice.repository";

@Injectable()
export class VoiceEffects {
  constructor(
    private actions$: Actions,
    private voiceRepository: VoiceRepository,
  ) {
  }

  @Effect() setVoiceAudioUrls$ = this.actions$
    .pipe(
      ofType<VoiceActions.SetVoiceAudioUrls>(VoiceActions.SET_VOICE_AUDIO_URLS),
      mergeMap(() => this.voiceRepository
        .callVoiceAudioListProxy()
        .pipe(
          mergeMap(response => of(new VoiceActions.SetVoiceAudioUrlsSuccess(response.body))),
        )
      )
    );

  @Effect() setVoiceApplications$ = this.actions$
    .pipe(
      ofType<VoiceActions.SetVoiceApplications>(VoiceActions.SET_VOICE_APPLICATIONS),
      mergeMap(() => this.voiceRepository
        .callVoiceApplicationList()
        .pipe(
          mergeMap(response => of(new VoiceActions.SetVoiceApplicationsSuccess(response.body))),
        )
      )
    );
  @Effect() setInboundCallerIds$ = this.actions$
    .pipe(
      ofType<VoiceActions.SetInboundCallerIds>(VoiceActions.SET_INBOUND_CALLER_IDS),
      mergeMap(() => this.voiceRepository
        .callInboundCallerIdsListProxy()
        .pipe(
          mergeMap(response => of(new VoiceActions.SetInboundCallerIdsSuccess(response.body))),
        )
      )
    );
}
