<ng-template #content>
  <uni-text-body
    color="inherit"
    [level]="getSize(size)"
    [isUppercase]="false"
    [isAligned]="true"
  >
    <uni-icon
      *ngIf="iconLeft"
      [name]="iconLeft"
      [size]="getIconSize(size)"
      [type]="getIconType(style, isDefault)"
    ></uni-icon>
    <span class="uni-button__content">
      <ng-content></ng-content>
    </span>
    <uni-icon
      *ngIf="iconRight"
      [name]="iconRight"
      [size]="getIconSize(size)"
      [type]="getIconType(style, isDefault)"
    ></uni-icon>
  </uni-text-body>
</ng-template>
<button
  *ngIf="!path; else link"
  class="uni-button"
  [ngClass]="
    [
      'uni-button--' + style,
      isDefault ? 'is-default' : 'is-destructive',
      isGroupItem ? 'uni-button--group' : '',
      autoWidth ? 'uni-button--auto-width' : '',
      this.noHorizontalSpace ? 'uni-button--no-horizontal-space' : '',
      this.noVerticalSpace ? 'uni-button--no-vertical-space' : ''
    ]"
  [type]="type"
  (click)="onClick()"
  [disabled]="disabled"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
<ng-template #link>
  <a
    class="uni-button"
    [ngClass]="
      [
        'uni-button--' + style,
        isDefault ? 'is-default' : 'is-destructive',
        isGroupItem ? 'uni-button--group' : '',
        noHorizontalSpace ? 'uni-button--no-horizontal-space' : '',
        noVerticalSpace ? 'uni-button--no-vertical-space' : ''
      ]"
    (click)="onClick()"
    [href]="path"
    [target]="target"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>
