
export default class TemplateModel {

  constructor(
    public id?: string,
    public name?: string,
    public description?: string,
    public templateStatus?: string,
    public createdAt?: string,
    public modifiedAt?: string,
    public flowJson?: {}
  ) {
  }
}
