import {Injectable} from '@angular/core';
import { Input} from '@angular/core';
import { AutoUnsubscribe, UniAuthFacade } from '@unifonic/common';
import { ActivatedRoute, Params } from '@angular/router';
import { TemplatesFacade } from '@templates/shared/templates.facade';


@Injectable()
export default class TemplateListService extends AutoUnsubscribe {
    @Input() params: Params;
    accountId: string;
    queryParams: Params;
    activeTab = 'ALL';
    tabChanged: boolean = false;

    constructor(
      private activatedRoute: ActivatedRoute,
      private templatesFacade: TemplatesFacade,
      private uniAuthFacade: UniAuthFacade
    ) {
      super();
    }

    public init(limit, status){
      this.tabChanged = status !== this.activeTab;
      this.accountId = this.uniAuthFacade.user.id;
      this.activeTab = status;

      this.subs.sink = this.activatedRoute.queryParams.subscribe(params => {
        let pageNumber = 0;
        if (!this.tabChanged && params.page && params.page > 0) {
          pageNumber = params.page - 1;
        }
        this.queryParams = {
          ...params,
          page: pageNumber,
          limit,
          flowTemplateStatus: this.activeTab
        };
        this.templatesFacade.setUserTemplates(this.accountId, this.queryParams);
      });
    }
  }
