import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UniTextColor, UniTextWeight } from '../../../uni-text';
import { UniIconType } from '../../../uni-icon';

@Component({
  selector: 'uni-menu-item',
  templateUrl: './uni-menu-item.component.html',
  styleUrls: ['./uni-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UniMenuItemComponent {
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() subtitle: string;
  @HostBinding('class.is-disabled') @Input() disabled: boolean;
  @HostBinding('class.is-wrapped') @Input() isWrapped = true;
  @HostBinding('class.is-selected') @Input() isSelected = false;
  subtitleColor = UniTextColor.secondary;
  subtitleLevel = '050';
  titleLevel = '100';
  titleWeight = UniTextWeight.regular;
  titleColor = UniTextColor.primary;
  titleDisabled = UniTextColor.disabled;
  iconColor = UniIconType.subtle;
  iconDisabled = UniIconType.disabled;
  iconSelected = UniIconType.brand;
}
