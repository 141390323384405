import { Flow, FlowJumptoStorageModel, FlowResponse, FlowVersion, FlowVersionDTO } from '@flows/shared/flows.model';

export const FLOWS_KEY = 'flows';

export interface FlowsState {
  userFlows: FlowResponse;
  flowVersions: FlowVersion[];
  activeFlowVersions: FlowVersionDTO[];
  flow: Flow;
  widgetVisibility: boolean;
  jumptoSelection: FlowJumptoStorageModel;
  sourceIdsForNewWidget?: string[];
  chooseTriggerVisibility: boolean;
}

export const initialState: FlowsState = {
  userFlows: {},
  flowVersions: [],
  activeFlowVersions: [],
  flow: {},
  widgetVisibility: false,
  jumptoSelection: {},
  chooseTriggerVisibility: false,
};
