<ng-container
  *ngIf="newMenuEnabled$ | async as isMenuEnabled; else noMenu"
>
  <uni-top-bar>
    <uni-icon
      #hamburger
      [isBorder]="true"
      class="uni-nav__hamburger-icon"
      name="bars"
      size="40" [style]="'far'"
      (click)="toggleHamburgerMenu()">
    </uni-icon>
  </uni-top-bar>
  <uni-menu-content></uni-menu-content>
  <div
    class="uni-nav__container"
    [ngClass]="{'is-collapsed': isNavCollapsed$ | async }"
  >
    <div
      class="uni-nav" [ngClass]="{'uni-nav--is-open': isMenuOpen}"
    >
        <uni-nav-new-toggle></uni-nav-new-toggle>

        <div
          *ngIf="workspaces$ | async as workspaces"
          class="uni-nav__switcher-container"
        >
          <uni-menu>
            <div class="uni-nav__switcher" uniMenuTrigger>
              <uni-menu-item
                iconRight="sort"
                [subtitle]="
                  'label.' + (!!parentAccount ? 'subaccount' : 'account')
                    | translate
                "
                [matTooltip]="accountName"
                matTooltipPosition="above"
              >
                <div class="uni-nav__account-container">
                  {{ accountName }}
                </div>
              </uni-menu-item>
            </div>
            <uni-menu-section
              [header]="'label.subaccounts' | translate"
              [isWide]="true"
            >
              <uni-menu-item
                *ngFor="let workspace of workspaces"
                [isSelected]="isSelected(workspace)"
                [iconRight]="isSelected(workspace) && 'check'"
                [subtitle]="
                  workspace.parentName ? null : ('label.main' | translate)
                "
                [isWrapped]="false"
                (click)="onImpersonate(workspace)"
              >
                {{ getWorkspaceName(workspace, workspaces) }}
              </uni-menu-item>
            </uni-menu-section>
            <uni-menu-section [isWide]="true">
              <uni-menu-item routerLink="/cloud/sub-accounts">
                {{ 'label.allSubaccounts' | translate }}
              </uni-menu-item>
            </uni-menu-section>
          </uni-menu>
        </div>

        <ng-container *ngIf="navModel$ | async as navModel">
            <ng-container *ngFor="let group of navModel.groups">
                <uni-nav-new-group [group]="group"></uni-nav-new-group>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngTemplateOutlet="noMenu">
    </ng-container>
  </div>
</ng-container>
<ng-template #noMenu>
  <ng-content></ng-content>
  <uni-footer></uni-footer>
</ng-template>

<!-- @TODO: plan better containers structur in project later -->
