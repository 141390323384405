<uni-loader *ngIf="isLoaderActive"></uni-loader>
<uni-container>
  <uni-section [isFirst]="true">
    <uni-title-container>
      <uni-title-item>
        <uni-text-body>
          Templates
        </uni-text-body>
      </uni-title-item>
    </uni-title-container>
    <uni-separator></uni-separator>
  </uni-section>

  <uni-section>
    <div class="row">
        <div class="col-md-10">
            <uni-box-tabs>
                <uni-box-tab
                    (click)="changeTab('ALL')"
                    [isActive]="activeTab === 'ALL'"
                >
                    All
                </uni-box-tab>
                <uni-box-tab
                    (click)="changeTab('DRAFT')"
                    [isActive]="activeTab === 'DRAFT'"
                >
                DRAFT
                </uni-box-tab>
                <uni-box-tab
                    (click)="changeTab('PUBLISH')"
                    [isActive]="activeTab === 'PUBLISH'"
                >
                PUBLISH
                </uni-box-tab>
                <uni-box-tab
                    (click)="changeTab('UNPUBLISH')"
                    [isActive]="activeTab === 'UNPUBLISH'"
                >
                UNPUBLISH
                </uni-box-tab>
            </uni-box-tabs>
        </div>
        <div class="col-md-1">
            <uni-button
                [routerLink]="'/studio'"
                [queryParams]="{type: 'template'}"
                [fitContent] = "'true'"
                [fit] = "'true'"
            >
            Create Template
            </uni-button>
        </div>
    </div>
    <div class="pt-5 pl-5 pr-5 pb-5 rounded">
        <div class="flow-templates-container">
            <app-template-list-table count=50 status={{templateStatus}} ></app-template-list-table>
        </div>
    </div>
    
    </uni-section>
</uni-container>
