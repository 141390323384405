import { Workspace } from '../shared/login.model';

export const LOGIN_KEY = 'login';

export interface LoginState {
  workspaces?: Workspace[];
  workspacesAmount?: number;
}

export const initialState: LoginState = {};
