import {Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';
import { values } from 'lodash';
import { FlowsVersionListTableColumns } from './flow-version-list-table.model'

@Component({
  selector: 'fs-flow-version-list-modal',
  templateUrl: './flow-version-list-modal.component.html',
  styleUrls: ['./flow-version-list-modal.component.scss'],
})
export class FlowVersionListModalComponent{
  @Input() isActive = false;
  @Input() flowVersions: Params;
  @Input() flowName: Params;
  @Input() flowId: Params;
  @Output() isActiveChange = new EventEmitter();
  @Output() submitChange = new EventEmitter();


  columns = FlowsVersionListTableColumns;

  get displayedColumns(): string[] {
    return values(this.columns);
  }
  hideFlowVersionModal(): void {
    this.isActiveChange.emit(false);
  }

  onSubmit(): void {
    this.submitChange.emit(true);
    this.hideFlowVersionModal();
  }
}
