import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Features} from '@shared/features';
import {Permission} from '@unifonic/common';
import {PermissionService} from '@templates/shared/permission.service';

@Directive({
  selector: '[appCheckPermissions]',
})
export class CheckPermissionsDirective implements OnInit {

  @Input() appCheckPermissions: Permission;
  @Input() appCheckPermissionsFeature: Features;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {
  }

  ngOnInit(): void {
    const actionIsPermitted = this.permissionService.isActionPermitted(this.appCheckPermissions, this.appCheckPermissionsFeature);
    if (actionIsPermitted) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }



}
