import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import TemplateModel from '../models/template-model';
import { SettingsService } from '@shared/settings.service';

@Injectable({
  providedIn: 'root'
})
export default class FlowTemplatesService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  public getTemplatesPaginated(page: number, limit: number, templateStatus: string) {
    const url = this.settingsService.apiUrl + `/api/v1/flow-studio/templates?flowTemplateStatus=${templateStatus}&limit=${limit}&page=${page}`;
    return this.httpClient.get<TemplateModel>(url)
      .pipe(
        map(data => {
          const templateList = [];
          if ((data as any).numberOfElements === 0) {
            return templateList;
          }

          for (const template of (data as any).content) {
            const templateModel = new TemplateModel(
              template.id,
              template.name,
              template.description,
              template.templateStatus,
              template.createdAt,
              template.modifiedAt,
              template.flowJson
            );
            templateList.push(templateModel);
          }

          return templateList;
        })
      );
  }

  delete(templateId: string) {
    const url = this.settingsService.apiUrl + '/api/v1/flow-studio/templates/' + templateId;
    return this.httpClient.delete(url);
  }

}
