<uni-text-body
  *ngIf="header"
  [level]="headerLevel"
  [color]="headerColor"
  class="uni-menu-section__header"
>
  {{ header }}
</uni-text-body>
<div class="uni-menu-section__container">
  <ng-content></ng-content>
</div>
<div class="uni-menu-section__separator"></div>
