import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
})
export class TemplateListComponent implements OnInit {
  activeTab = localStorage.getItem('activeTemplateTab') ?  localStorage.getItem('activeTemplateTab') : 'ALL';
  templateStatus:string;
  isLoaderActive:Boolean;
  constructor(private router: Router, private route: ActivatedRoute) {
    
  }
  ngOnInit(): void {
    this.activeTab = localStorage.getItem('activeTemplateTab') ?  localStorage.getItem('activeTemplateTab') : 'ALL';
    this.templateStatus = this.activeTab; 
  }

  changeTab(activeTab: string) {
    this.activeTab = activeTab;
    localStorage.setItem('activeTemplateTab', activeTab);
    this.templateStatus = this.activeTab; 
  }

}
