import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@unifonic/common';

@Component({
  selector: 'fs-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  isLoaderActive = true;

  constructor(private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    window.location.href = this.settingsService.settings.ucUrl + '/logout';
  }
}
