import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TEMPLATES_KEY } from './../../store';
import { AutoUnsubscribe, HttpQueryFacade } from '@unifonic/common';
@Component({
  selector: 'fs-templates',
  templateUrl: './templates.component.html',
})
export class TemplatesComponent extends AutoUnsubscribe implements OnInit {
  isLoaderActive = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private httpQueryFacade: HttpQueryFacade,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.httpQueryFacade
      .isInProgress$(TEMPLATES_KEY)
      .subscribe(isActive => {
        this.isLoaderActive = isActive;
        this.cdr.detectChanges();
      });
  }
}
