import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fs-flows-list-modal',
  templateUrl: './flows-list-modal.component.html',
  styleUrls: ['./flows-list-modal.component.scss'],
})
export class FlowsListModalComponent {
  @Input() isActive = false;
  @Output() isActiveChange = new EventEmitter();
  @Output() submitChange = new EventEmitter();

  hideModal(): void {
    this.isActiveChange.emit(false);
  }

  onSubmit(): void {
    this.submitChange.emit(true);
    this.hideModal();
  }
}
