import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { forEach, get, find, isObject } from 'lodash';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { DateUtils, getPriceDecimalRegex, REGEX, XREGEX } from '../../../utils';
import { FormError, FormOption } from './uni-form.model';
import * as XRegExp from 'xregexp';

@Injectable()
export class UniFormService {

  selectFormErrors(errors$: Observable<FormError[]>, form: FormGroup) {
    errors$.subscribe(errors => {
      forEach(errors, ({ message, propertyPath }) => {
        const control = form.get(propertyPath);

        if (control) {
          control.setErrors({ [message]: true }, { emitEvent: true });
        }
      });
    });
  }

  numberValidators(): ValidatorFn[] {
    return [
      Validators.maxLength(15),
      Validators.pattern(REGEX.number),
    ];
  }

  unitsValidators(): ValidatorFn[] {
    return [
      Validators.required,
      Validators.pattern(REGEX.number),
      Validators.pattern(REGEX.notZero),
    ];
  }

  getCampaignValidators(): ValidatorFn[] {
    return [
      Validators.required,
      this.campaignNameValidator,
      Validators.maxLength(255),
    ];
  }

  getNameValidators(): ValidatorFn[] {
    return [
      Validators.required,
      this.nameValidator,
      Validators.maxLength(255),
    ];
  }

  getNameWithArabicValidators(): ValidatorFn[] {
    return [
      Validators.required,
      this.nameWithArabicValidator,
      Validators.maxLength(255),
    ];
  }

  getEmailValidators(): ValidatorFn[] {
    return [
      Validators.required,
      this.emailValidator,
      Validators.maxLength(255),
    ];
  }

  getPriceValidators(decimalValue = 2): ValidatorFn[] {
    return [
      Validators.required,
      Validators.pattern(getPriceDecimalRegex(decimalValue)),
      Validators.pattern(REGEX.notZero),
    ];
  }

  getSellingRateValidators(decimalValue = 4): ValidatorFn[] {
    return [
      Validators.required,
      Validators.pattern(getPriceDecimalRegex(decimalValue)),
      Validators.max(1),
      Validators.min(0.01),
    ];
  }

  getSelectValidator(): ValidatorFn[] {
    return [
      Validators.required,
      this.objectValidator,
    ];
  }

  getRequiredPhoneNumberValidators(): ValidatorFn[] {
    return [
      Validators.required,
      Validators.minLength(5),
      Validators.pattern(REGEX.phone)
    ];
  }

  phoneValidator(control: AbstractControl) {

    const parsedNumber = parsePhoneNumberFromString(control.value);
    return !!parsedNumber && parsedNumber.isValid()
      ? null
      : { phoneNumber: { valid: false } };
  }

  emailValidator(control: AbstractControl) {
    const regex = XRegExp(REGEX.email);

    return regex.test(control.value) ? null : {
      email: {
        valid: false
      }
    };
  }

  ipAddressValidator(control: AbstractControl) {
    const regex = XRegExp(REGEX.ip);

    return regex.test(control.value) ? null : {
      ipAddress: {
        valid: false
      }
    };
  }

  templateValidator(control: AbstractControl) {
    const regex = XRegExp(REGEX.templateNames);

    return regex.test(control.value) ? null : {
     template: {
        valid: false
      }
    };
  }

  templateNoSpacesValidator(control: AbstractControl) {
    const regex = XRegExp(REGEX.noSpaces);

    return regex.test(control.value) ? null : {
      templateNoSpaces: {
        valid: false
      }
    };
  }

  templateNoMultipleSpacesValidator(control: AbstractControl) {
    const regex = XRegExp(REGEX.noMultipleSpaces);

    return !regex.test(control.value) ? null : {
      templateNoMultipleSpaces: {
        valid: false
      }
    };
  }

  templateNoSpecialCharsValidator(control: AbstractControl) {
    const regex = XRegExp(REGEX.alphanumericAndUnderscore);

    return regex.test(control.value) ? null : {
      templateNoSpecialChars: {
        valid: false
      }
    };
  }

  templateNumberOfVariablesValidator(control: AbstractControl) {
    const variableOccurrences = control.value?.match(XRegExp(REGEX.templateVariable))?.length || 0;
    const wordsOccurrences = control.value?.trim()?.split(' ')?.length || 0;

    return variableOccurrences*2 <= wordsOccurrences ? null : {
      templateNumberOfVariables: {
        valid: false
      }
    };
  }

  templateBodyBeginsOrEndsWithVariableValidator(control: AbstractControl) {
    const beginsWithVariableRegex = XRegExp(REGEX.beginsWithVariable);
    const endsWithVariableRegex = XRegExp(REGEX.endsWithVariable);

    return !(beginsWithVariableRegex.test(control.value) || endsWithVariableRegex.test(control.value)) ? null : {
      templateCannotBeginOrEndWithVariable: {
        valid: false
      }
    };
  }

  templateBodyEndsWithVariableValidator(control: AbstractControl) {
    const endsWithVariableRegex = XRegExp(REGEX.endsWithVariable);

    return !(endsWithVariableRegex.test(control.value)) ? null : {
      templateCannotBeginOrEndWithVariable: {
        valid: false
      }
    };
  }

  objectValidator(control: AbstractControl) {
    return !isObject(control.value)
      ? { pattern: { valid: false } }
      : null;
  }

  requiredOneActive(data: FormArray) {
    let valid = false;

    for (let x = 0; x < data.length; ++x) {
      if (get(data.at(x).value, 'isActive')) {
        valid = true;
        break;
      }
    }

    return valid
      ? null
      : { requiredOneActive: true };
  }

  requiredOneChecked(data: FormArray) {
    let valid = false;

    for (let x = 0; x < data.length; ++x) {
      if (get(data.at(x).value, 'isChecked')) {
        valid = true;
        break;
      }
    }

    return valid
      ? null
      : { requiredOneActive: true };
  }

  seControlValue(control: AbstractControl, options: FormOption[]): void {
    const id = get(control.value, 'id');
    const value = find(options, { id });

    if (!value) {
      return;
    }

    control.setValue(value);
  }

  alphanumericValidator(input: AbstractControl) {
    const regex = XRegExp(XREGEX.alphanumeric);

    return regex.test(input.value) ? null : {
      nameValidator: {
        valid: false
      }
    };
  }

  nameValidator(input: AbstractControl) {
    const regex = XRegExp(XREGEX.name);

    return regex.test(input.value) ? null : {
      nameValidator: {
        valid: false
      }
    };
  }

  nameWithArabicValidator(input: AbstractControl) {
    const regex = XRegExp(XREGEX.nameWithArabic);

    return regex.test(input.value) ? null : {
      nameValidator: {
        valid: false
      }
    };
  }

  campaignNameValidator(input: AbstractControl) {
    const regex = XRegExp(XREGEX.name2);

    return regex.test(input.value) ? null : {
      nameValidator: {
        valid: false
      }
    };
  }

  getAccountNameValidators(): ValidatorFn[] {
    return [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(255),
    ];
  }

  getUrlValidator(): ValidatorFn[] {
    return [
      Validators.maxLength(256),
      this.urlValidator
    ];
  }

  urlValidator(input: AbstractControl) {
    const regex = XRegExp(REGEX.urlPatternWithParam);

    return !input.value || regex.test(input.value) ? null : {
      urlFormat: {
        valid: false
      }
    };
  }

  arabicCharacters(input: AbstractControl) {
    const regex = XRegExp(REGEX.arabicCharacters);

    return regex.test(input.value) ? null : {
      arabicCharacters: {
        valid: false
      }
    };
  }

  urduCharacters(input: AbstractControl) {
    const regex = XRegExp(REGEX.urduCharacters);

    return regex.test(input.value) ? null : {
      urduCharacters: {
        valid: false
      }
    };
  }

  getAudioMessageCustomValidation(language: any) {
    if (language === 'english') {
      return [this.englishCharacters];
    } else if (language === 'arabic') {
      return [this.arabicCharacters];
    } else if (language === 'urdu') {
      return [this.urduCharacters];
    } else {
      return [];
    }
  }

  englishCharacters(input: AbstractControl) {
    const regex = XRegExp(REGEX.englishCharacters);

    return regex.test(input.value) ? null : {
      englishCharacters: {
        valid: false
      }
    };
  }

  specialCharacterExists(input: AbstractControl) {
    const regex = XRegExp(REGEX.specialChars);

    return !regex.test(input.value) ? null : {
      specialCharacterNotAllowed: {
        valid: false
      }
    };
  }

  ageRange(input: AbstractControl) {
    if (!input.value) {
      return null;
    }

    const values = input.value
      .split(',')
      .map(item => item.trim());

    const isInvalid = values.some(item => {
      if (
        String(item).toLowerCase() === 'all'
        || String(item).toLowerCase() === 'الكل'
      ) {
        return false;
      }

      const range = item.split('-') || [];

      if (range.length === 2) {
        return !(Number(range[0]) < Number(range[1])
          && Number(range[0]) >= 0
          && Number(range[1]) <= 150);
      }

      const singleAge = Number(item);

      if (!!singleAge) {
        return !(singleAge >= 0 && singleAge <= 150);
      }

      return true;
    });

    return !isInvalid ? null : {
      ageRange: {
        valid: false
      }
    };
  }

  passwordValidator(input: AbstractControl) {
    const regex = XRegExp(REGEX.password);

    return regex.test(input.value) ? null : {
      password: {
        valid: false
      }
    };
  }

  userEditPasswordValidator(input: AbstractControl) {
    const regex = XRegExp(REGEX.password);
    const value = input.value;

    return value.length <= 0 || regex.test(value) ? null : {
      password: {
        valid: false
      }
    };
  }

  httpsProtocolValidator(input: AbstractControl) {
    const regex = XRegExp(REGEX.urlWithHttpsProtocol);

    return !input.value || regex.test(input.value)
      ? null
      : { httpsProtocol: { valid: false } };
  }

  specialCharactersNotAllowedValidator(input: AbstractControl): ValidationErrors | null {
    const regex = XRegExp(REGEX.specialCharactersNotAllowed);

    return regex.test(input.value)
      ? { specialCharactersNotAllowed: true }
      : null;
  }

  notEqualPassword(oldPassword: AbstractControl) {
    return (control: AbstractControl) => {
      const newPassword = control.value;
      return newPassword !== oldPassword.value ? null : { notEqualPassword: true };
    };
  };

  equalConfirmPasswords: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.get('newPassword');
    const newPasswordConfirmation = control.get('newPasswordConfirmation');
    return newPasswordConfirmation?.dirty &&
      newPassword?.value !== newPasswordConfirmation?.value ?
      { passwordConfirmation: true }
      : null;
  };

  endTimeIsBeforeStartTime(start = 'startTime', end = 'endTime'): ValidatorFn {
    return (data: FormArray): ValidationErrors | null => {
      data.controls.forEach(formGroup => {
        const startTime: FormControl = formGroup.get(start) as FormControl;
        const endTime: FormControl = formGroup.get(end) as FormControl;

        if (
          endTime?.dirty &&
          (DateUtils.endTimeIsBeforeStartTime(
            startTime?.value,
            endTime?.value
          ) ||
          startTime?.value === endTime?.value)
        ) {
          formGroup.setErrors({ endIsBeforeStart: true });
          data.setErrors({ endIsBeforeStart: true });
        } else {
          formGroup.setErrors(null);
          data.setErrors(null);
        }
      });

      return !data.valid ? { endIsBeforeStart: true } : null;
    };
  }

  validateForm(formGroup: FormGroup): void {
    Object
      .keys(formGroup.controls)
      .forEach(key => {
        const nestedFormGroup = formGroup.get(key) as FormGroup;
        if (!nestedFormGroup.controls) {
          nestedFormGroup.markAsDirty();
          return;
        }
        this.validateForm(nestedFormGroup);
      });
  }
}
