import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import TriggerSelectionService from './trigger-selection-service';

@Component({
  selector: 'app-trigger-selection',
  templateUrl: './trigger-selection.component.html',
  styleUrls: ['./trigger-selection.component.scss']
})
export class TriggerSelectionComponent implements OnInit {

  @Input()
  selectionType: string;

  @Input()
  title: string;

  @Output() messageEvent = new EventEmitter<string>();

  constructor(private triggerSelectionService: TriggerSelectionService) {
  }

  ngOnInit(): void {
  }

  triggerSelected(widgetType: string) {
    this.messageEvent.emit(widgetType);
  }

  getWidgetList() {
    if (this.selectionType === 'template') {
      return this.triggerSelectionService.getTemplateWidgetList();
    } else {
      return this.triggerSelectionService.getFlowWidgetList();
    }
  }
}
