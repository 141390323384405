
import {FlowAuthenticationModel} from '@flows/shared/webhookTrigger/webhook-trigger.model';
import {Action, UPDATE} from '@ngrx/store';


export const SET_FLOW_JWT_ACCESS_TOKEN = '[WebhookTrigger] SET_FLOW_JWT_ACCESS_TOKEN';
export const SET_FLOW_JWT_ACCESS_TOKEN_SUCCESS = '[WebhookTriggers] SET_FLOW_JWT_ACCESS_TOKEN_SUCCESS';

export const GENERATE_FLOW_REFRESH_TOKEN = '[WebhookTrigger] GENERATE_FLOW_REFRESH_TOKEN';
export const GENERATE_FLOW_REFRESH_TOKEN_SUCCESS = '[WebhookTrigger] GENERATE_FLOW_REFRESH_TOKEN_SUCCESS';

export class SetFlowJwtAccessToken implements Action {
  readonly type = SET_FLOW_JWT_ACCESS_TOKEN;
  constructor() { }
}

export class SetFlowJwtAccessTokenSuccess implements Action {
  readonly type = SET_FLOW_JWT_ACCESS_TOKEN_SUCCESS;
  constructor(public response: FlowAuthenticationModel) { }
}

export class GenerateFlowRefreshToken implements Action {
  readonly type = GENERATE_FLOW_REFRESH_TOKEN;
  constructor(public data: FlowAuthenticationModel) { }
}

export class GenerateFlowRefreshTokenSuccess implements Action {
  readonly type = GENERATE_FLOW_REFRESH_TOKEN_SUCCESS;
  constructor(public response: FlowAuthenticationModel) { }
}


export type All =
  | SetFlowJwtAccessToken
  | SetFlowJwtAccessTokenSuccess
  | GenerateFlowRefreshToken
  | GenerateFlowRefreshTokenSuccess
  ;
