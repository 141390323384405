import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UniPage404Component } from '@unifonic/common';

@NgModule({
  imports: [RouterModule.forRoot([
    {
      path: '**',
      component: UniPage404Component,
      canDeactivate: ['canDeactivate404']
    }
  ])],
  exports: [RouterModule],
  providers: [
    {
      provide: 'canDeactivate404',
      useValue: () => window.location.href = '/'
    }
  ]
})
export class ErrorRoutingModule { }
