import {WhatsappApplicationKey, WhatsappApplicationModel, WhatsappApplicationTemplates} from '@flows/shared/whatsapp/whatsapp.model';
import {Action} from '@ngrx/store';

export const SET_WHATSAPP_APPLICATIONS = '[Whatsapp] SET_WHATSAPP_APPLICATIONS';
export const SET_WHATSAPP_APPLICATIONS_SUCCESS = '[Whatsapp] SET_WHATSAPP_APPLICATIONS_SUCCESS';

export const SET_WHATSAPP_APPLICATION_TEMPLATES = '[Whatsapp] SET_WHATSAPP_APPLICATION_TEMPLATES';
export const SET_WHATSAPP_APPLICATION_TEMPLATES_SUCCESS = '[Whatsapp] SET_WHATSAPP_APPLICATION_TEMPLATES_SUCCESS';


export const SET_WHATSAPP_APPLICATION_KEY = '[Whatsapp] SET_WHATSAPP_APPLICATION_KEY';
export const SET_WHATSAPP_APPLICATION_KEY_SUCCESS = '[Whatsapp] SET_WHATSAPP_APPLICATION_KEY_SUCCESS';


export class SetWhatsappApplications implements Action {
  readonly type = SET_WHATSAPP_APPLICATIONS;

  constructor() {
  }
}

export class SetWhatsappApplicationsSuccess implements Action {
  readonly type = SET_WHATSAPP_APPLICATIONS_SUCCESS;

  constructor(public response: WhatsappApplicationModel[]) {
  }
}

export class SetWhatsappApplicationTemplates implements Action {
  readonly type = SET_WHATSAPP_APPLICATION_TEMPLATES;

  constructor(public applicationId: string, public channelId: string) {
  }
}

export class SetWhatsappApplicationTemplatesSuccess implements Action {
  readonly type = SET_WHATSAPP_APPLICATION_TEMPLATES_SUCCESS;

  constructor(public response: WhatsappApplicationTemplates[]) {
  }
}

export class SetWhatsappApplicationKey implements Action {
  readonly type = SET_WHATSAPP_APPLICATION_KEY;

  constructor(public applicationId: string) {
  }
}

export class SetWhatsappApplicationKeySuccess implements Action {
  readonly type = SET_WHATSAPP_APPLICATION_KEY_SUCCESS;

  constructor(public response: WhatsappApplicationKey) {
  }
}

export type All =
  SetWhatsappApplications
  | SetWhatsappApplicationsSuccess
  | SetWhatsappApplicationTemplates
  | SetWhatsappApplicationTemplatesSuccess
  | SetWhatsappApplicationKey
  | SetWhatsappApplicationKeySuccess
  ;
