import { get, isEmpty } from 'lodash';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { Subject, BehaviorSubject } from 'rxjs';

import { Inject, Injectable, Optional } from '@angular/core';

import { UniAuthFacade } from './uni-auth.facade';
import { UserMe } from './uni-auth.model';
import { FeatureFlagsUser, FeatureFlagOperator, FeatureFlagKeys, FeatureFlags } from './uni-feature-flags.model';
import { SettingsService } from '../../../shared/settings/settings.service';
import {filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UniFeatureFlagsService {
  canSee$ = new Subject();
  client: any;
  flags: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(
    private uniAuthFacade: UniAuthFacade,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}

  initFeatureFlags(data: UserMe = null, environment = this.environment) {
    if (!!this.client) {
      return;
    }

    const launchDarklyKey = get(this.settingsService.settings, 'launchDarklyKey') || get(environment, 'launchDarklyKey');

    const user: FeatureFlagsUser = !!data ? {
      key: data.user.id,
      name: data.user.firstName + ' ' + data.user.lastName,
      anonymous: false,
      email: data.user.email,
      firstName: data.user.firstName,
      lastName: data.user.lastName,
      custom: {
        admin: data.user.isAdminLevel,
        whiteLabel: data.systemSettings.isWhitelabelActive,
        account: data.user.account ? data.user.account.name : '',
        accountId: data.user.account ? data.user.account.id : '',
      }
    }
    : {
      key: 'anonymous',
      anonymous: true,
    };

    this.client = LDClient.initialize(launchDarklyKey, user, {
      sendEventsOnlyForVariation: true
    });

    this.client.on('change', (flags: any) => {
      const updatedFlags = {...this.flags.value};

      for (const flag in flags) {
        if (!!updatedFlags && !!updatedFlags[flag]) {
          updatedFlags[flag] = flags[flag].current;
        }
      }

      this.setFeatureFlags(updatedFlags);
    });

    this.client.on('ready', () => {
      this.setFeatureFlags();
    });
  }

  setFeatureFlags(flags = this.client.allFlags()) {
    this.flags.next(flags);
    this.updateState();
  }

  updateState() {
    this.uniAuthFacade.setFeatureFlags(this.flags.value);
  }

  flagsStateUpdated$() {
      return this.uniAuthFacade.featureFlags$.pipe(filter(Boolean));
  }

  getPermission$(flagName: FeatureFlagKeys | string, trueOnly = false) {
    return this.flagsStateUpdated$().pipe(
      map(() => this.getPermission(flagName)),
      filter(hasPermission => !trueOnly || hasPermission)
    );
  }

  getPermission(
    flagName: FeatureFlagKeys | string,
    flags?: FeatureFlags
  ) {

    if (this.client) {
      return this.client.variation(flagName, false);
    }

    if (flags) {
      return flags[flagName];
    }

    return;
  }

  hasAllPermissions(permissions: FeatureFlagKeys[] | string[]): boolean {
    return !permissions.some(permission => !this.getPermission(permission));
  }

  hasSomePermission(permissions: FeatureFlagKeys[] | string[]): boolean {
    return permissions.some(permission => this.getPermission(permission));
  }

  checkPermission(operator: FeatureFlagOperator, permissions: FeatureFlagKeys[] | string[]): boolean {
    if (isEmpty(permissions)) {
      return false;
    }

    if (operator === FeatureFlagOperator.OR) {
      return this.hasSomePermission(permissions);
    }

    if (operator === FeatureFlagOperator.AND) {
      return this.hasAllPermissions(permissions);
    }

    return false;
  }
}
