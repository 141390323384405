import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRepository } from './shared/login.repository';
import { LoginFacade } from './shared/login.facade';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LOGIN_KEY } from './store/login.state';
import { reducer } from './store/login.reducers';
import { LoginEffects } from './store/login.effects';
import { HttpQueryModule } from '../../store/http-query/http-query.module';

@NgModule({
  imports: [
    CommonModule,
    HttpQueryModule,
    StoreModule.forFeature(LOGIN_KEY, reducer),
    EffectsModule.forFeature([LoginEffects]),
  ],
  providers: [LoginRepository, LoginFacade],
})
export class UniLoginModule {}
