import { isBoolean } from 'lodash';
import { filter } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe, navAnimations, UniNavFacade } from '@unifonic/common';

@Component({
  selector: 'fs-nav-wrapper',
  templateUrl: './nav-wrapper.component.html',
  styleUrls: ['./nav-wrapper.component.scss'],
  animations: [...navAnimations],
})
export class NavWrapperComponent extends AutoUnsubscribe implements OnInit {
  isMobileNavOpen: boolean;

  constructor(private uniNavFacade: UniNavFacade) {
    super();
  }

  ngOnInit() {
    this.selectNavState();
  }

  selectNavState() {
    this.subs.sink = this.uniNavFacade.isNavOpen$
      .pipe(filter(state => isBoolean(state)))
      .subscribe(state => this.isMobileNavOpen = state);
  }
}
