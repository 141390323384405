<uni-container>
  <uni-section>
    <div class="col-md-12 col-lg-12 header-frame">
      <div class="choose-channel col-md-5 col-lg-5  bg-white">
        <uni-text-subheader> {{ 'label.chooseChannel' | translate }} </uni-text-subheader>
        <uni-text-info>{{ 'label.chooseChannelDesc' | translate }}</uni-text-info>
        <div class="x2-container">
          <div class="col-md-3">
            <div class="channels-container">
              <div class="channel">
                <span class="channel-icon">
                  <uni-link [path]="settingsService.settings.communicationApi+'/application'">
                    <img alt="SMS" src="/assets/img/message-new.svg">
                  </uni-link>
                </span>
                <span class="channel-text">SMS</span>
              </div>
              <div class="channel">
                <span class="channel-icon">
                  <uni-link [path]="settingsService.settings.communicationApi+'/application/voice'">
                    <img alt="Voice Call" src="/assets/img/voicecall-new.svg">
                  </uni-link>
                </span>
                <span class="channel-text">Voice</span>
              </div>
              <div class="channel">
                <span class="channel-icon">
                  <uni-link [path]="settingsService.settings.voiceUrl+'/applications'">
                    <img alt="WhatsApp" src="/assets/img/whatsapp-new.svg">
                  </uni-link>
                </span>
                <span class="channel-text">WhatsApp</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="choose-channel build-it-yourself col-md-7 col-lg-7  bg-white">
        <div class="build-it-yourself-text">
          <div>
            <uni-text-subheader>{{ 'label.buildYourself' | translate }}</uni-text-subheader>
          </div>
          <div>
            <uni-text-info>{{ 'label.buildYourselfText' | translate }}
            </uni-text-info>
          </div>
          <div *appCheckPermissions="permissions.create, feature: features.FLOWS" class="mt-4">
            <uni-button [routerLink]="'/flows/flow-builder'">{{ 'label.startFreshFlow' | translate }}</uni-button>
          </div>
        </div>
        <div class="build-it-yourself-img"></div>
      </div>
    </div>
    <div class="create-flow-using-templates p-5 rounded">
      <uni-text-subheader class="d-block">{{ 'label.createFlowFromTemplate' | translate}}</uni-text-subheader>
      <div class="flow-templates-container">
        <fs-template-list-items [showPagination]="false"></fs-template-list-items>
      </div>
    </div>
    <uni-section>
      <div class="row">
        <div class="col-5 mt-5">
          <hr>
        </div>
        <a [routerLink]="'/v2/templates/template-list'" class="col-1 mt-3 text-center">
          <div class="view-all-templates-icon"><i class="fa fa-chevron-down"></i></div>
          <uni-text-info>{{ 'label.viewAllTemplates' | translate }}</uni-text-info>
        </a>
        <div class="col-6 mt-5">
          <hr>
        </div>
      </div>
    </uni-section>

    <uni-section *appCheckPermissions="permissions.seeOwn, feature: features.FLOWS">
      <div class="pb-5">
        <uni-text-subheader class="d-block">{{ 'label.myFlows' | translate }}
          <uni-link [routerLink]="'/flows'">{{ 'label.viewAll' | translate }}
          </uni-link>
        </uni-text-subheader>
      </div>

      <fs-flows-list-table [params]="" [showPagination]="false"></fs-flows-list-table>
    </uni-section>
  </uni-section>
</uni-container>
