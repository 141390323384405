import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {NotificationRepository} from '@flows/shared/notification/notification.repository';
import {of} from 'rxjs';

import * as NotificationActions from './notification.actions';
import {mergeMap} from 'rxjs/operators';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationRepository: NotificationRepository
  ) {
  }

  @Effect() setNotificationApplications$ = this.actions$
    .pipe(
      ofType<NotificationActions.SetNotificationApplications>(NotificationActions.SET_NOTIFICATION_APPLICATIONS),
      mergeMap(() => this.notificationRepository
        .getNotificationApplications()
        .pipe(
          mergeMap(response => {
            return of(new NotificationActions.SetNotificationApplicationsSuccess(response.body));
          }),
        )
      )
    );
}
