import { User } from '../modules/uni-auth/shared/uni-auth.model';
import { Userpilot } from 'userpilot';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

enum ConsoleUserType {
  customer = 'customer_console_user',
  admin = 'admin_console_user',
}

@Injectable({ providedIn: 'root' })
export class UniUserpilotService implements OnDestroy {
  unsubscribe$ = new Subject<void>();

  constructor(private router: Router) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initialize(token: string) {
    Userpilot.initialize(token);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.reload());
  }

  init(user: User) {
    const { isSelfServe } = user.account;
    Userpilot.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      user_created_at: user.registrationDate,
      locale_code: user.locale,
      currency: user.defaultCurrency,
      timezone: user.timezone,
      otpChannel: user.otpChannel,
      roles: user.roles.toString(),
      console_user_type: user.isAdminLevel ? ConsoleUserType.admin : ConsoleUserType.customer,
      additionalGrants: user.additionalGrants?.toString(),
      ...(user.account) && {
      company: {
        id: user.account.id,
        name: user.account.name,
        country: user.account.country,
        type: user.account.type,
        plan: user.account.plan,
        sector: user.account.accountSector,
        category: user.account.category,
        account_created_at: user.account.registrationDate,
        account_creation_type: !!isSelfServe ? (isSelfServe ? 'self_serve' : 'crm') : null,
        white_labeled_account: user.account.isWhiteLabel,
        parentAccount: user.account.parentAccount,
        useMnp: user.account.useMnp,
        '2FA': user.account.twoFactorAuthenticationEnabled
      }},
      trigger: 'spa'
    });
  }

  changeTheme(isRtl = true): void {
    if (!!(window as any).userpilot) {
      (window as any).userpilotCallMethods.theme.push({
        name: isRtl ? 'Unifonic Arabic' : 0
      });
    }
  }

  reload(): void {
    if (!!(window as any).userpilot) {
      Userpilot.reload();
    }
  }
}
