import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TemplatesState } from './templates.state';

export const selectTemplatesFeature = createFeatureSelector<TemplatesState>('templates');

export const selectUserTemplates = createSelector(
  selectTemplatesFeature,
  (state: TemplatesState) => state.userTemplates,
);

export const selectTemplate = createSelector(
  selectTemplatesFeature,
  (state: TemplatesState) => state.template,
);

export const selectTemplateStatusResponse = createSelector(
  selectTemplatesFeature,
  (state: TemplatesState) => state.templateStatusResponse,
);
