import {WhatsappApplicationKey, WhatsappApplicationModel, WhatsappApplicationTemplates} from '@flows/shared/whatsapp/whatsapp.model';

export const WHATSAPP_KEY = 'whatsapp';

export interface WhatsappState {
  whatsappApplications: WhatsappApplicationModel[];
  whatsappApplicationTemplates: WhatsappApplicationTemplates[];
  whatsappApplicationKey: WhatsappApplicationKey;
}

export const initialWhatsappState: WhatsappState = {
  whatsappApplications: [],
  whatsappApplicationTemplates: [],
  whatsappApplicationKey: null
};
