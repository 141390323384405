import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SmsRepository } from '@flows/shared/sms/sms.repository';
import { of } from 'rxjs';

import * as SmsApplicationActions from './sms.actions';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class SmsEffects {
  @Effect() setSmsApplications$ = this.actions$.pipe(
    ofType<SmsApplicationActions.SetSmsApplications>(SmsApplicationActions.SET_SMS_APPLICATIONS),
    mergeMap(() =>
      this.smsRepository.getSmsApplications().pipe(
        mergeMap((response) => {
          return of(new SmsApplicationActions.SetSmsApplicationsSuccess(response.body));
        })
      )
    )
  );
  @Effect() setSmsAppIds$ = this.actions$.pipe(
    ofType<SmsApplicationActions.SetSmsAppIds>(SmsApplicationActions.SET_SMS_APP_IDS),
    mergeMap(() =>
      this.smsRepository.getSmsAppIds().pipe(
        mergeMap((response) => {
          return of(new SmsApplicationActions.SetSmsAppIdsSuccess(response));
        })
      )
    )
  );
  @Effect() setSmsSenders$ = this.actions$.pipe(
    ofType<SmsApplicationActions.SetSmsSenders>(SmsApplicationActions.SET_SMS_SENDERS),
    mergeMap(() =>
      this.smsRepository.getSmsSenders().pipe(
        mergeMap((response) => {
          return of(new SmsApplicationActions.SetSmsSendersSuccess(response));
        })
      )
    )
  );

  constructor(private actions$: Actions, private smsRepository: SmsRepository) {}
}
