import { Component, OnInit } from '@angular/core';
import { UniLayoutFacade, UniTextColor, UniTextWeight } from '@unifonic/common';
import { SettingsService } from '@shared/settings.service';

@Component({
  selector: 'fs-activation-request',
  templateUrl: './activation-request.component.html',
  styleUrls: ['./activation-request.component.scss']
})
export class ActivationRequestComponent implements OnInit {
  uniTextWeight = UniTextWeight;
  uniTextColor = UniTextColor;

  constructor(private uniLayoutFacade: UniLayoutFacade, public settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.uniLayoutFacade.setIsLoader(false);
  }
}
