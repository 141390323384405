import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniMenuComponent } from './components/uni-menu/uni-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { UniTextModule } from '../uni-text';
import { UniMenuSectionComponent } from './components/uni-menu-section/uni-menu-section.component';
import { UniMenuItemComponent } from './components/uni-menu-item/uni-menu-item.component';
import { UniIconModule } from '../uni-icon';

const components = [
  UniMenuComponent,
  UniMenuSectionComponent,
  UniMenuItemComponent
];

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    UniTextModule,
    UniIconModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniMenuModule { }
