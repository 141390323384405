import { Params } from '@angular/router';
import {TemplateResponse, Template, CreateTemplate, FlowTemplateModel, TemplateStatusResponse} from '@templates/shared/templates.model';
import { Action } from '@ngrx/store';

export const SET_USER_TEMPLATES = '[Templates] SET_USER_TEMPLATES';
export const SET_USER_TEMPLATES_SUCCESS = '[Templates] SET_USER_TEMPLATES_SUCCESS';

export const DELETE_TEMPLATE = '[Templates] DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESS = '[Templates] DELETE_TEMPLATE_SUCCESS';

export const SET_TEMPLATE = '[Templates] SET_TEMPLATE';
export const SET_TEMPLATE_SUCCESS = '[Templates] SET_TEMPLATE_SUCCESS';

export const SAVE_TEMPLATE = '[Templates] SAVE_TEMPLATE';
export const SAVE_TEMPLATE_SUCCESS = '[Templates] SAVE_TEMPLATE_SUCCESS';

export const UPDATE_TEMPLATE = '[Templates] UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESS = '[Templates] UPDATE_TEMPLATE_SUCCESS';

export const DESTROY_TEMPLATE = '[Templates] DESTROY_TEMPLATE';
export const DESTROY_TEMPLATE_SUCCESS = '[Templates] DESTROY_TEMPLATE_SUCCESS';



export class SetUserTemplates implements Action {
  readonly type = SET_USER_TEMPLATES;
  constructor(public id: string, public params: Params) { }
}

export class SetUserTemplatesSuccess implements Action {
  readonly type = SET_USER_TEMPLATES_SUCCESS;
  constructor(public response: TemplateResponse) { }
}

export class DeleteTemplate implements Action {
  readonly type = DELETE_TEMPLATE;
  constructor(
    public templateId: string,
    public accountId: string,
    public params: Params
  ) { }
}

export class DeleteTemplateSuccess implements Action {
  readonly type = DELETE_TEMPLATE_SUCCESS;
  constructor() { }
}


export class SetTemplate implements Action {
  readonly type = SET_TEMPLATE;
  constructor(public id: string) { }
}

export class SetTemplateSuccess implements Action {
  readonly type = SET_TEMPLATE_SUCCESS;
  constructor(public response: Template) { }
}

export class SaveTemplate implements Action {
  readonly type = SAVE_TEMPLATE;
  constructor(public template: CreateTemplate) { }
}

export class SaveTemplateSuccess implements Action {
  readonly type = SAVE_TEMPLATE_SUCCESS;
  constructor(public response: Template) { }
}

export class UpdateTemplate implements Action {
  readonly type = UPDATE_TEMPLATE;
  constructor(public template: FlowTemplateModel) { }
}

export class UpdateTemplateSuccess implements Action {
  readonly type = UPDATE_TEMPLATE_SUCCESS;
  constructor(public response: TemplateStatusResponse) { }
}

export class DestroyTemplate implements Action {
  readonly type = DESTROY_TEMPLATE;
  constructor() { }
}

export class DestroyTemplateSuccess implements Action {
  readonly type = DESTROY_TEMPLATE_SUCCESS;
  constructor() { }
}



export type All =
  | SetUserTemplates
  | SetUserTemplatesSuccess
  | DeleteTemplate
  | DeleteTemplateSuccess
  | SetTemplate
  | SetTemplateSuccess
  | SaveTemplate
  | SaveTemplateSuccess
  | UpdateTemplate
  | UpdateTemplateSuccess
  | DestroyTemplate
  | DestroyTemplateSuccess;
