<uni-snackbar></uni-snackbar>
<ng-container *ngIf="isFeatureFlagFetched">
  <uni-nav-new *ngIf="isNewNav; else oldNav">
    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
  </uni-nav-new>

  <ng-template #oldNav>
    <uni-app-logged>
      <fs-nav-wrapper></fs-nav-wrapper>
    </uni-app-logged>
  </ng-template>
</ng-container>
