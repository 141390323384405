import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { select, Store } from '@ngrx/store';

import * as selectors from '@templates/store/templates.selectors';
import * as templatesActions from '@templates/store/templates.actions';
import { TemplatesState } from '@templates/store/templates.state';
import {CreateTemplate, FlowTemplateModel} from '@templates/shared/templates.model';

@Injectable({
  providedIn: 'root'
})
export class TemplatesFacade {
  userTemplates$ = this.store.pipe(select(selectors.selectUserTemplates));
  template$ = this.store.pipe(select(selectors.selectTemplate));
  templateStatusResponse$ = this.store.pipe(select(selectors.selectTemplateStatusResponse));
  
  constructor(private store: Store<TemplatesState>) {}

  deleteTemplate(
    templateId: string,
    accountId: string,
    params: Params
  ): void {
    this.store.dispatch(new templatesActions.DeleteTemplate(templateId, accountId,  params));
  }

  setUserTemplates(id: string, params: Params): void {
    this.store.dispatch(new templatesActions.SetUserTemplates(id, params));
  }

  setTemplate(id: string): void {
    this.store.dispatch(new templatesActions.SetTemplate(id));
  }

  saveTemplate(
    template: CreateTemplate
  ): void {
    this.store.dispatch(new templatesActions.SaveTemplate(template));
  }

  updateTemplate(
    template: FlowTemplateModel
  ): void {
    this.store.dispatch(new templatesActions.UpdateTemplate(template));
  }

  destroyTemplate(): void {
    this.store.dispatch(new templatesActions.DestroyTemplate());
  }
}
