import { Component, ElementRef, OnInit } from '@angular/core';
import { Flow, FlowComponentModel, FlowVersion } from '../models/flow.model';

import FlowBuilderService from './flow-builder-service';
import { ActivatedRoute, Router } from '@angular/router';
import { VoiceApplicationModel } from '../models/voice.model';
import ValidationRules from './validation-rules';
import { HttpQueryFacade } from '@unifonic/common';
import { FLOWS_KEY } from '@flows/store';
import { SettingsService } from '@shared/settings.service';

declare function dropVariableTargetListener(): any;

declare function rebuildFlow(flow, widgetType): any;

declare function updateWidgetName(widgetIndex, widgetName): any;

@Component({
  selector: 'fs-flow-builder',
  templateUrl: './flow-builder.component.html',
  styleUrls: [
    './../../assets/css/base.scss',
    // './../../assets/css/styles.css',
    // './../../assets/css/studio.css',
    './flow-builder.component.scss',
  ],
})
export class FlowBuilderComponent implements OnInit {
  accountId: string = localStorage.getItem('accountId') ? localStorage.getItem('accountId') : 'b6a1fd4e-5032-4e75-b8fd-84c7d2a0519e';
  // topbar;
  startBuilding: boolean = false;
  triggerSelection: boolean = false;
  isLoaderActive = false;
  isLoaderActive$ = this.httpQueryFacade.isInProgress$(FLOWS_KEY);
  name: string = '';
  description: string = '';
  flowId: string = '';
  flowStatus: string = 'DRAFT';
  modifiedAt: string = null;
  // flowModel: FlowModel;
  flow: Flow = null;
  isDuplicate = false;
  flowVersion: number = 0;
  webhookUrl: string = '';
  flowDeploymentId: string = '';
  flowExecutionName: string = '';
  isModalActive: boolean = false;
  isTestFlowModalActive: boolean = false;
  isCreateModalActive: boolean = false;
  isPublishModalActive: boolean = false;
  isFlowVersionsModalActive: boolean = false;
  isSetWidgetNameModalActive: boolean = false;

  isTemplate: boolean = false;
  templateName: string = '';
  templateDescription: string = '';
  // templateStartBuilding: boolean = false;
  templateId: string = '';
  isFlowTemplatePublishModalActive: boolean = false;
  isFlowTemplateUnPublishModalActive: boolean = false;
  toggleTemplateButton: boolean = false;
  useTemplateForFlow: boolean;

  testVariableName: string = '';
  testVariableValue: string = '';
  testFlowVariables = [];
  flowVariables: string[];
  flowVersionModel: FlowVersion;

  voiceApplications: VoiceApplicationModel[] = [];
  phoneNumber: any = '';
  applicationKey: any = '';
  voiceAudioList: any[] = [];
  audioName: any = '';
  audioUrl: any = '';

  activeFlowStatus: string = 'ACTIVE';
  flowVersionsModel: FlowVersion[];

  widgetVariableList: string[] = [];
  variableListForDisplay: string[] = [];

  widgetName: string = '';
  widgetComponent: FlowComponentModel = null;
  backendError: boolean;
  backendErrorMsg: string;

  constructor(
    private flowBuilderService: FlowBuilderService,
    private route: ActivatedRoute,
    private router: Router,
    private elRef: ElementRef,
    private httpQueryFacade: HttpQueryFacade,
    private settingsService: SettingsService
  ) {
    this.loadStudioJs();
    this.route.queryParams.subscribe((params) => {
      this.flowId = params.flowId;
      this.flowVersion = params.flowVersion;

      if (this.flowId === undefined || this.flowId === 'undefined') {
        this.flowId = null;
      } else {
        this.startBuilding = true;
      }

      if (params.isDuplicate !== undefined) {
        this.isDuplicate = params.isDuplicate.toLocaleLowerCase() === 'true';
      }

      this.isTemplate = params.type === 'template';
      this.templateId = params.templateId;
      if (params.templateId === undefined && this.isTemplate) {
        this.templateId = null;
        this.startBuilding = false;
      } else if (this.isTemplate) {
        this.startBuilding = true;
      }

      this.useTemplateForFlow = params.useTemplateForFlow;
      if (this.useTemplateForFlow && this.isTemplate && this.templateId !== undefined) {
        this.isTemplate = false;
        this.startBuilding = true;
      } else {
        this.useTemplateForFlow = false;
      }

      /*Creating new template
      if(params['type'] === 'template') {
          this.isTemplate = true;
          this.startBuilding = true;
      } else {
          this.isTemplate = false;
      }
      //Editing the existing template
      if(params['templateId'] === undefined){
          this.templateStartBuilding = true;
      } else {
          this.templateStartBuilding = false;
      }*/
    });
  }

  ngOnInit(): void {
    if (this.flowId && this.flowVersion) {
      this.getFlowById(this.flowId, this.flowVersion);
    } else {
      localStorage.setItem('flowList', JSON.stringify([]));
    }
    this.isLoaderActive = false;
    if (this.flowId || this.templateId) {
      this.getActiveFlowVersions();
    }

    this.getVoiceApplicationList();
    this.getVoicePlayAudioUrls();
  }

  public getFlowById(flowId: string, flowVersion: number) {
    this.isLoaderActive = true;
    this.flowBuilderService.getFlowById(flowId, flowVersion, this.accountId).subscribe(
      (response) => {
        this.flow = response.flow;
        this.generateWebHookUrl();

        const flowJson = response.flowVersion.flowJson;
        if (this.isDuplicate === true) {
          this.flowId = null;
          this.name = response.flow.name + '_copy';
          flowJson.map((x) => {
            // tslint:disable-next-line:forin
            for (const i in x.data) {
              x.data[i] = null;
            }
            return x;
          });
          this.flowStatus = 'DRAFT';
        } else {
          this.flowStatus = response.flowVersion.flowStatus;
          this.modifiedAt = response.flowVersion.modifiedAt;
          this.flowId = response.flow.id;
          this.name = response.flow.name;
          this.flowVersionModel = response.flowVersion;
          this.startBuilding = true;
          this.flowDeploymentId = response.flowVersion.deploymentId;
          this.flowExecutionName = response.flowVersion.startExecutionName;
          this.getVariables();
        }
        this.generateWebHookUrl();
        localStorage.setItem('flowList', JSON.stringify(flowJson));
        setTimeout(() => {
          rebuildFlow(flowJson, response.triggerType);
        }, 400);
      },
      (error) => {
        console.log('loadFlowById ERROR', error);
        this.isLoaderActive = false;
      },
      () => {
        this.isLoaderActive = false;
      }
    );
  }

  public selectTrigger() {
    this.triggerSelection = true;
  }

  public triggerSelected(widgetType: string) {
    this.startBuilding = true;
    localStorage.setItem('triggerType', widgetType);
    setTimeout(() => {
      rebuildFlow([], widgetType);
    }, 200);
  }

  loadStudioJs() {
    const node = document.createElement('script');
    node.src = '/assets/js/studio.js';
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public createFlow() {
    this.isCreateModalActive = false;
    this.isLoaderActive = true;
    const data = {
      name: this.name,
      description: this.description,
      components: JSON.parse(localStorage.getItem('flowList')),
      flowId: this.flowId,
      flowVersion: this.flowVersion,
      triggerType: localStorage.getItem('triggerType'),
    };

    let saveOrUpdateResponse;
    if (this.flowId) {
      saveOrUpdateResponse = this.flowBuilderService.createVersion(data, this.flowId, this.accountId);
      this.navigateToflowCreatedUrl(saveOrUpdateResponse);
    } else if (!this.isTemplate) {
      saveOrUpdateResponse = this.flowBuilderService.createFlow(data, this.accountId);
      this.navigateToflowCreatedUrl(saveOrUpdateResponse);
    } else if (this.isTemplate && !this.templateId) {
      data.name = this.templateName;
      data.description = this.templateDescription;
      saveOrUpdateResponse = this.flowBuilderService.createTemplate(data);
      this.navigateToflowTemplateCreatedUrl(saveOrUpdateResponse);
    } else if (this.isTemplate && this.templateId) {
      const data1 = {
        flowTemplateId: this.templateId,
        components: JSON.parse(localStorage.getItem('flowList')),
      };

      this.flowBuilderService.updateFlowTemplate(data1).subscribe(
        (response) => {
          this.getFlowTemplate();
        },
        (error) => {
          console.log('-----------', error);
        }
      );
    }
  }

  public updateFlowTemplateStatus(state: string) {
    const data = {
      flowTemplateId: this.templateId,
      status: 'PUBLISH',
      // components: JSON.parse(localStorage.getItem('flowList')),
    };

    if (state === 'Unpublish') {
      this.isFlowTemplateUnPublishModalActive = false;
      data.status = 'UNPUBLISH';
    } else if (state === 'Publish') {
      this.isFlowTemplatePublishModalActive = false;
    }
    if (this.isTemplate) {
      this.flowBuilderService.updateFlowTemplate(data).subscribe(
        (response) => {
          this.getFlowTemplate();
        },
        (error) => {
          console.log('-----------', error);
        }
      );
    }
  }

  public navigateToflowTemplateCreatedUrl(saveOrUpdateResponse) {
    saveOrUpdateResponse.subscribe(
      (response) => {
        const url = `/studio?type=template&templateId=${response.id}`;
        this.router.navigateByUrl(url).then((res) => {
          window.location.reload();
          return;
        });
      },
      (error) => {
        console.log('++++++++++', error);
        this.isLoaderActive = false;
      },
      () => {
        this.isLoaderActive = false;
      }
    );
  }

  public navigateToflowCreatedUrl(saveOrUpdateResponse) {
    saveOrUpdateResponse.subscribe(
      (response) => {
        const url = `/studio?flowId=${response.flowId}&flowVersion=${parseFloat(response.version)}`;
        this.router.navigateByUrl(url).then((res) => {
          window.location.reload();
          return;
        });
      },
      (error) => {
        console.log('++++++++++', error);
        this.isLoaderActive = false;
      },
      () => {
        this.isLoaderActive = false;
      }
    );
  }

  public testFlowExecution() {
    if (this.flowVersionModel && this.flowVersionModel.deploymentId) {
      this.isTestFlowModalActive = false;
      this.isLoaderActive = true;

      const variableData = {};
      this.testFlowVariables.filter((data) => {
        variableData[data.name] = data.value;
      });

      const data1 = {
        requestParams: variableData,
      };

      const executeTestFlowResponse = this.flowBuilderService.testFlowExecution(this.flowVersionModel.deploymentId, data1);

      executeTestFlowResponse.subscribe(
        (response) => {},
        (error) => {
          console.log('++++++++++', error);
          this.isLoaderActive = false;
        },
        () => {
          this.isLoaderActive = false;
        }
      );
    } else {
      alert('Flow not deployed yet.');
    }
  }

  public deployFlow() {
    this.isPublishModalActive = false;
    this.isLoaderActive = true;
    if (this.flowId) {
      const isFlowValid = this.validateFlowParameters();
      if (!isFlowValid) {
        this.backendError = true;
        this.backendErrorMsg = 'An error occurred. Your flow was not successfully published. Please check the parameters.';
        this.isLoaderActive = false;
        return;
      }

      this.backendError = false;
      this.backendErrorMsg = null;
      const deployFlowResponse = this.flowBuilderService.deployFlow(this.flowId, this.flowVersion, this.accountId);

      deployFlowResponse.subscribe(
        (response) => {
          const url = `/studio?flowId=${this.flowId}&flowVersion=${this.flowVersion}`;
          this.router.navigateByUrl(url).then((res) => {
            window.location.reload();
            return;
          });
        },
        (error) => {
          this.backendError = true;
          this.backendErrorMsg = 'An error occurred. Your flow was not successfully published.';

          console.log('++++++++++', error);
          this.isLoaderActive = false;
        },
        () => {
          this.isLoaderActive = false;
        }
      );
    } else {
      this.isLoaderActive = false;
      this.backendError = true;
      this.backendErrorMsg = 'Flow has not created yet.';
    }
  }

  showPostRequest() {
    this.isModalActive = true;
  }

  hidePostRequest() {
    this.isModalActive = false;
  }

  showCreateFlowConfirmation() {
    this.isCreateModalActive = true;
  }

  hideCreateFlowConfirmation() {
    this.isCreateModalActive = false;
  }

  showPublishFlowConfirmation() {
    this.isPublishModalActive = true;
  }

  hidePublishFlowConfirmation() {
    this.isPublishModalActive = false;
  }

  showFlowTemplateConfirmation(state: string) {
    if (state === 'Unpublish') {
      this.isFlowTemplateUnPublishModalActive = true;
    } else if (state === 'Publish') {
      this.isFlowTemplatePublishModalActive = true;
    }
  }

  hideFlowTemplateConfirmation(state: string) {
    if (state === 'Unpublish') {
      this.isFlowTemplateUnPublishModalActive = false;
    } else if (state === 'Publish') {
      this.isFlowTemplatePublishModalActive = false;
    }
  }

  showFlowVersionsModal() {
    this.isFlowVersionsModalActive = true;
  }

  hideFlowVersionsModal() {
    this.isFlowVersionsModalActive = false;
  }

  showTestFlowModal() {
    this.flowVariables = this.getAllVariables();
    this.isTestFlowModalActive = true;
  }

  hideTestFlowModal() {
    this.isTestFlowModalActive = false;
  }

  showWidgetNameModal() {
    this.widgetComponent = JSON.parse(localStorage.getItem('component'));
    this.widgetName = '';
    this.isSetWidgetNameModalActive = true;
  }

  hideWidgetNameModal() {
    this.isSetWidgetNameModalActive = false;
  }

  public addTestVariable() {
    if (this.testVariableName && this.testVariableValue) {
      const data = {
        name: this.testVariableName,
        value: this.testVariableValue,
      };
      this.testFlowVariables.push(data);

      this.testVariableName = '';
      this.testVariableValue = '';
      // remove from flowVariable
      this.flowVariables = this.flowVariables.filter((variable) => {
        if (variable !== data.name) {
          return variable;
        }
      });
    }
  }

  public removeTestVariable(name: string) {
    if (name) {
      this.flowVariables.push(name);
      this.testFlowVariables = this.testFlowVariables.filter((data) => {
        if (name !== data.name) {
          return data;
        }
      });
    }
  }

  getPhoneNumber(applicationKey: any) {
    this.voiceApplications.map((data) => {
      if (data.applicationKey === applicationKey) {
        this.phoneNumber = data.callerId;
        this.applicationKey = data.applicationKey;
      }
    });
  }

  getAudioUrl(selectedAudioName) {
    this.voiceAudioList.map((data) => {
      if (data.audioName === selectedAudioName) {
        this.audioUrl = data.audioUrl;
      }
    });
  }

  addNewVariable($event: string) {
    this.widgetVariableList.push($event);

    const flowList = JSON.parse(localStorage.getItem('flowList'));
    const selectedElement: HTMLCollectionOf<Element> = document.getElementsByClassName('selected-widget');
    const selectedId = selectedElement[0].getAttribute('id');

    if (selectedId === null) {
      flowList[0].variables = this.widgetVariableList;
    } else {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < flowList.length; i++) {
        if (flowList[i].index === selectedId) {
          flowList[i].variables = this.widgetVariableList;
          break;
        }
      }
    }

    this.variableListForDisplay.push($event);
    localStorage.setItem('flowList', JSON.stringify(flowList));
    dropVariableTargetListener();
  }

  public changeWidgetName() {
    const index = this.widgetComponent.index;
    const name = this.widgetName;
    updateWidgetName(index, name);
    this.hideWidgetNameModal();
  }

  getVariables() {
    this.variableListForDisplay = [];
    const flowList = JSON.parse(localStorage.getItem('flowList'));

    const selectedElement: HTMLCollectionOf<Element> = document.getElementsByClassName('selected-widget');
    if (selectedElement[0] === undefined) {
      return;
    }
    const selectedId = selectedElement[0].getAttribute('id');

    let result = null;
    flowList.map((obj, i) => {
      if (obj.index === selectedId) {
        result = obj;
        return;
      } else if ('FS_BRANCH' === obj.type) {
        result = this.getComponentByIndex(obj, selectedId, result);
      } else {
        this.variableListForDisplay = this.variableListForDisplay.concat(obj.variables);
      }
    });

    if (result) {
      this.variableListForDisplay = this.variableListForDisplay.concat(result.variables);
    }
    this.variableListForDisplay = [...new Set([].concat(...this.variableListForDisplay))];
  }

  private getAndSetTemplateForFlow() {
    if (this.templateId != null) {
      this.flowBuilderService.getFlowTemplate(this.templateId).subscribe(
        (response) => {
          localStorage.setItem('flowList', JSON.stringify(response.flowJson));
          localStorage.setItem('triggerType', response.triggerType);
          this.flowStatus = '';
          this.name = response.name;
          this.description = response.description;
        },
        (error) => {
          console.log('getActiveFlowVersions ERROR', error);
          this.isLoaderActive = false;
        },
        () => {
          this.isLoaderActive = false;
        }
      );
    }
  }

  private getFlowTemplate() {
    this.isLoaderActive = true;
    if (this.templateId != null) {
      this.flowBuilderService.getFlowTemplate(this.templateId).subscribe(
        (response) => {
          localStorage.setItem('flowList', JSON.stringify(response.flowJson));
          localStorage.setItem('triggerType', response.triggerType);
          this.flowStatus = response.templateStatus;
          this.templateName = response.name;
          this.toggleTemplateButton = this.flowStatus === 'PUBLISH';
        },
        (error) => {
          console.log('getActiveFlowVersions ERROR', error);
          this.isLoaderActive = false;
        },
        () => {
          this.isLoaderActive = false;
        }
      );
    }
  }

  private generateWebHookUrl() {
    if (this.flowStatus !== 'ACTIVE') {
      return;
    }

    this.webhookUrl = `${this.settingsService.apiUrl}/api/v1/flow-studio/execute/webhook/${this.flowDeploymentId}`;
  }

  private getVoiceApplicationList() {
    this.flowBuilderService.getVoiceApplicationList().subscribe(
      (response) => {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < response.length; i++) {
          const callerObj = new VoiceApplicationModel(
            null,
            null,
            response[i].name,
            null,
            response[i].applicationKey,
            response[i].primaryCaller.callerNumber.phoneNumber
          );
          this.voiceApplications.push(callerObj);
        }
      },
      (error) => {
        console.log('ERRROROROROR', error);
      }
    );
  }

  private getVoicePlayAudioUrls() {
    this.flowBuilderService.getVoiceAudioList().subscribe(
      (response) => {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < response.length; i++) {
          const audioObj = {
            audioUrl: response[i].audioUrl,
            audioName: response[i].name,
          };
          this.voiceAudioList.push(audioObj);
        }
      },
      (error) => {
        console.log('ERRROROROROR', error);
        this.isLoaderActive = false;
      }
    );
  }

  private getActiveFlowVersions() {
    this.isLoaderActive = true;
    if (this.isTemplate) {
      this.getFlowTemplate();
    } else if (!this.isTemplate && this.useTemplateForFlow) {
      this.getAndSetTemplateForFlow();
    } else {
      this.flowBuilderService.getActiveFlowVersions(this.activeFlowStatus, this.accountId).subscribe(
        (response) => {
          this.flowVersionsModel = response;
        },
        (error) => {
          console.log('getActiveFlowVersions ERROR', error);
          this.isLoaderActive = false;
        },
        () => {
          this.isLoaderActive = false;
        }
      );
    }

    const flowJson = localStorage.getItem('flowList');
    const triggerType = localStorage.getItem('triggerType');

    setTimeout(() => {
      rebuildFlow(flowJson, triggerType);
    }, 400);
  }

  private getComponentByIndex(obj, index, result) {
    if ('FS_BRANCH' === obj.type) {
      obj.nodes?.map((node, j) => {
        node?.components.map((component, k) => {
          if (component.index === index) {
            result = component;
            return;
          } else if ('FS_BRANCH' === component.type) {
            return this.getComponentByIndex(component, index, result);
          } else {
            this.variableListForDisplay = this.variableListForDisplay.concat(component.variables);
          }
        });
      });
    }
    return result;
  }

  private getAllVariables() {
    let result = [];
    const flowList = JSON.parse(localStorage.getItem('flowList'));
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < flowList.length; i++) {
      if (flowList[i].variables != null) {
        result = result.concat(flowList[i].variables);
      }
    }

    return result;
  }

  private validateFlowParameters(): boolean {
    const validationRulesByWidgetType = ValidationRules.getValidationRules();

    const invalidElementList = [];
    const flowList = JSON.parse(localStorage.getItem('flowList'));
    flowList.map((item) => {
      const validationRulesForItem = validationRulesByWidgetType.filter((rule) => {
        return rule.type === item.type;
      });

      if (!item.trigger && validationRulesForItem.length > 0 && validationRulesForItem[0].requiredParams.length > 0) {
        if (item.data === undefined || item.data === null || (typeof item.data === 'object' && item.data.length === 0)) {
          invalidElementList.push('empty_data+' + item.name);
        } else {
          for (const param of validationRulesForItem[0].requiredParams) {
            if (item.data[param] === undefined || item.data[param].length === 0) {
              invalidElementList.push(param);
            }
          }
        }
      }
    });

    console.log(invalidElementList);
    return invalidElementList.length <= 0;
  }
}
