export enum UniTextWeight {
  bold = 'bold',
  semibold = 'semibold',
  regular = 'regular',
  medium = 'medium',
}

export enum UniTextColor {
  black = 'black',
  dark = 'dark',
  darkLight = 'dark-light',
  active = 'active',
  alert = 'alert',
  info = 'info',
  confirmation = 'confirmation',
  secondary = 'secondary',
  primary = 'primary'
}
