import { Injectable } from '@angular/core';
import { LoginRepository } from '../shared/login.repository';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as LoginActions from './login.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UniRouteService } from '../../../shared/uni-route.service';
import { ValidationUtils } from '../../../utils/validation.utils';
import { uniSnackbarActions } from '../../uni-snackbar/store/';

@Injectable()
export class LoginEffects {
  constructor(
    private loginRepository: LoginRepository,
    private actions$: Actions,
    private uniRouteService: UniRouteService,
  ) {}

  @Effect() setWorkspaces$ = this.actions$.pipe(
    ofType<LoginActions.SetWorkspaces>(LoginActions.SET_WORKSPACES),
    mergeMap(({ id, params }) =>
      this.loginRepository.getWorkspaces(id, params).pipe(
        map(
          (response) =>
            new LoginActions.SetWorkspacesSuccess(
              response.body,
              Number(response.headers.get('Total-Items'))
            )
        ),
        catchError((error) =>
          of(
            new uniSnackbarActions.NewSnackbar(
              'error',
              ValidationUtils.getViolationError(error)
            )
          )
        )
      )
    )
  );

  @Effect() putSelectedAccount$ = this.actions$.pipe(
    ofType<LoginActions.PutSelectedAccount>(
      LoginActions.PUT_SELECTED_ACCOUNT
    ),
    mergeMap(({ userId, accountId }) =>
      this.loginRepository.putSelectedAccount(userId, accountId).pipe(
        map(() => new LoginActions.PutSelectedAccountSuccess()),
        catchError((error) =>
          of(
            new uniSnackbarActions.NewSnackbar(
              'error',
              ValidationUtils.getViolationError(error)
            )
          )
        )
      )
    )
  );

  @Effect({ dispatch: false }) putSelectedAccountSuccess$ = this.actions$.pipe(
    ofType<LoginActions.PutSelectedAccount>(
      LoginActions.PUT_SELECTED_ACCOUNT_SUCCESS
    ),
    tap(() => {
      this.uniRouteService.navigate('/');
    })
  );
}
