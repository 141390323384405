import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FlowExecution, FlowExecutionLog, FlowExecutionResponseModel, FlowNameAndVersionList } from '../models/flow-execution.model';
import { uniSnackbarActions } from '@unifonic/common';
import { SettingsService } from '@shared/settings.service';

@Injectable({
  providedIn: 'root',
})
export default class FlowExecutionService {
  constructor(private httpClient: HttpClient, private settingsService: SettingsService) {}

  getUserFlowExecutionsPaginated(page: number, startDate?: string, endDate?: string) {
    const url = `${this.settingsService.apiUrl}/api/v1/executions?limit=10&page=${page}&flowStatus=ALL`;
    const params: Record<string, string> = {
      page: page.toString(),
    };
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    return this.httpClient
      .get<FlowExecutionResponseModel>(url, {
        params,
      })
      .pipe(
        map((data) => {
          const flows = [];
          for (const flow of (data as any).content) {
            flows.push(flow);
          }

          return new FlowExecutionResponseModel(
            data.id,
            data.accountId,
            data.createdAt,
            data.modifiedAt,
            flows,
            data.totalElements,
            (data as any).size
          );
        })
      );
  }

  getFlowExecutionLogs(correlationId: string) {
    const url = `${this.settingsService.apiUrl}/api/v1/execution/${correlationId}/logs`;
    return this.httpClient.get<FlowExecutionLog[]>(url);
  }

  getFlowCamundaInstance(instanceId: string) {
    const url = `${this.settingsService.apiUrl}/api/v1/execution/${instanceId}/instance`;
    return this.httpClient.get<any[]>(url);
  }

  getExecutionVariables(executionId: string) {
    const url = `${this.settingsService.apiUrl}/api/v1/execution/${executionId}/variables`;
    return this.httpClient.get<FlowExecution>(url);
  }

  getExecutionStatusByReferenceId(executionId: string) {
    const url = `${this.settingsService.apiUrl}/api/v1/execution/${executionId}/status`;
    return this.httpClient.get<FlowExecution>(url);
  }

  downloadReport(data: any) {
    const url = `${this.settingsService.apiUrl}/api/v1//execution/download/report`;
    return this.httpClient.post(url, data, { withCredentials: true }).pipe(
      tap(() => console.log('Execute download report submitted!')),
      catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
    );
  }

  getFlowNameAndVersionList() {
    const url = `${this.settingsService.apiUrl}/api/v2/flow/name-and-versions`;
    return this.httpClient.get<FlowNameAndVersionList[]>(url);
  }
}
