import { UniAccount } from '../../uni-accounts/shared/uni-accounts.model';

export enum UserRole {
  admin = 'ADMIN',
  user = 'USER',
  accountUser = 'ACCOUNT_USER',
  accountAdmin = 'ACCOUNT_ADMIN',
  accountManager = 'ACCOUNT_MANAGER',
  routingAdmin = 'UNIFONIC_ROUTING_ADMIN',
  superAdmin = 'SUPER_ADMIN',
  unifonicFinance = 'UNIFONIC_FINANCE',
  supportLead = 'SUPPORT_LEAD',
  support = 'SUPPORT'
}

export enum Permission {
  all = 'ALL',
  approve = 'APPROVE',
  activateAll = 'ACTIVATE_ALL',
  createAll = 'CREATE_ALL',
  create = 'CREATE',
  createOwn = 'CREATE_OWN',
  superCreate = 'SUPER_CREATE',
  deactivateAll = 'DEACTIVATE_ALL',
  deactivateOwn = 'DEACTIVATE_OWN',
  edit = 'EDIT',
  editAll = 'EDIT_ALL',
  editOwn = 'EDIT_OWN',
  smppEditAll = 'SMPP_EDIT_ALL',
  deduct = 'DEDUCT',
  reject = 'REJECT',
  see = 'SEE',
  seeAll = 'SEE_ALL',
  seeManaged = 'SEE_MANAGED',
  seeOwn = 'SEE_OWN',
  seeUserOwn = 'SEE_USER_OWN',
  superSee = 'SUPER_SEE',
  smppSeeAll = 'SMPP_SEE_ALL',
  topUp = 'TOP_UP',
  deleteAll = 'DELETE_ALL',
  deleteOwn = 'DELETE_OWN',
  deleteUserOwn = 'DELETE_USER_OWN',
  bypass = 'BYPASS',
  shareOwn = 'SHARE_OWN',
  shareParent = 'SHARE_PARENT',
  SendOwn = 'SEND_OWN',
  pay = 'PAY',
  exportOwn = 'EXPORT_OWN',
  upload = 'UPLOAD',
  partialEdit = 'PARTIAL_EDIT',
  transfer = 'TRANSFER',
  updateAll = 'UPDATE_ALL',
  updateOwn = 'UPDATE_OWN',
  updateExpiry = 'UPDATE_EXPIRY',
  read = 'READ',
  update = 'UPDATE',
  delete = 'DELETE'
}

export enum AdditionalGrants {
  adminLogs = 'HAS_ADMIN_LOGS_ACCESS',
  campaignDirectSending = 'PERMISSION.CC.CAMPAIGN_DIRECT_SENDING.SEE_ALL',
  audienceAccess = 'HAS_AUDIENCE_ACCESS',
  uploadCR = 'CAN_UPLOAD_CR',
  subaccounts = 'HAS_SUB_ACCOUNTS',
  impersonationHistoryAccess = 'HAS_IMPERSONATION_HISTORY_ACCESS',
  viewPendingRequests = 'CAN_VIEW_PENDING_REQUESTS',
  hasPackages = 'HAS_PACKAGES'
}

export interface UserPermissions {
  [key: string]: Record<string, Permission[]>;
}

export interface UserAccount {
  id: string;
  twoFactorAuthenticationEnabled: boolean;
  balance: number;
}

export interface User {
  name: string;
  roles: UserRole[];
  permissions: UserPermissions;
  account?: UniAccount;
  email: string;
  defaultCurrency: string;
  firstName: string;
  id: string;
  isAdminLevel: boolean;
  lastName: string;
  phone: string;
  timezone: string;
  locale?: string;
  otpChannel: string;
  registrationDate?: string;
  additionalGrants: string[];
  lastRequestedExportAt?: string;
}

export interface SystemSettings {
  appleTouchIcon: string;
  domain: string;
  emailFrom: string;
  emailSupport: string;
  favIcon: string;
  isWhitelabelActive: boolean;
  logo: string;
  menuLogo: string;
  name: string;
  scheme: string;
}

export interface ImpersonationUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  account?: any;
  registrationDate?: string;
  phone?: string;
  defaultCurrency?: string;
  timezone?: string;
  roles?: UserRole[];
  locale?: string;
  isAdminLevel?: boolean;
}

export interface ImpersonationSession {
  uuid: string;
  impersonator: ImpersonationUser;
  impersonatee: ImpersonationUser;
  rootImpersonator: ImpersonationUser;
  parentSession: any;
}

export interface UserMe {
  impersonationSession: ImpersonationSession;
  systemSettings: SystemSettings;
  user: User;
}

export interface GlobalSettings {
  name: string;
  logo: string;
  menuLogo: string;
  favIcon: string;
  appleTouchIcon: string;
  emailFrom: string;
  emailSupport: string;
  domain: string;
  scheme: string;
  isWhitelabelActive: boolean;
}

export enum KeycloakCookies {
  kcJWT = 'KC_JWT',
  kcRefreshJWT = 'KC_REFRESH_JWT'
}
