import { Injectable } from '@angular/core';
import { SettingsService as CommonSettingsService } from '@unifonic/common';
import { FSSettings } from '../models/fs-settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private commonSettingsService: CommonSettingsService) {
  }

  get settings(): FSSettings {
    return this.commonSettingsService.settings as unknown as FSSettings;
  }

  get apiUrl() {
    return this.settings.apiBaseUrl;
  }


  get communicationApiUrl() {
    return this.settings.communicationApi;
  }
}
