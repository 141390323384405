import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationCaller, VoiceApplicationModel, VoiceAudioModel, VoiceCallerIdModel } from '@flows/shared/voice/voice.model';
import { VOICE_KEY } from '@flows/store/voice/voice.state';
import { SettingsService } from '@shared/settings.service';

@Injectable({
  providedIn: 'root',
})
export class VoiceRepository {
  constructor(private http: HttpClient, private settingsService: SettingsService) {}

  callVoiceApplicationList(): Observable<HttpResponse<VoiceApplicationModel[]>> {
    const url = `${this.settingsService.apiUrl}/api/v1/proxy/voice/application`;
    //   const url ='https://run.mocky.io/v3/e09cb447-f055-4f34-b8cf-614daf17f908';
    return this.http.get<VoiceApplicationModel[]>(url, {
      observe: 'response',
      headers: {
        queryName: 'callVoiceApplicationList',
        queryGroups: [VOICE_KEY],
      },
    });
  }

  callCallerIdsList(): Observable<VoiceCallerIdModel[]> {
    const url = `${this.settingsService.apiUrl}/api/v1/proxy/voice/callerid`;
    //   const url ='https://run.mocky.io/v3/e09cb447-f055-4f34-b8cf-614daf17f908';
    return this.http.get<VoiceCallerIdModel[]>(url, {
      headers: {
        queryName: 'callCallerIdsList',
        queryGroups: [VOICE_KEY],
      },
    });
  }

  callVoiceAudioListProxy(): Observable<HttpResponse<VoiceAudioModel[]>> {
    const url = `${this.settingsService.apiUrl}/api/v1/proxy/voice/audio/libraries`;
    //    const url = `https://run.mocky.io/v3/10811e1f-c666-420f-b6a5-468ae4260d82`;
    return this.http.get<VoiceAudioModel[]>(url, {
      observe: 'response',
      headers: {
        queryName: 'callVoiceAudioListProxy',
        queryGroups: [VOICE_KEY],
      },
    });
  }

  callInboundCallerIdsListProxy(): Observable<HttpResponse<ApplicationCaller[]>> {
    const url = `${this.settingsService.communicationApiUrl}/app/api/v2/callers?type=notification&statuses%5B%5D=approved&priority=primary&withoutVoiceApplication=true&limit=1000`;
    //    const url = `https://run.mocky.io/v3/fb07e624-4807-448a-a038-edf70cb9cb98`;
    return this.http.get<ApplicationCaller[]>(url, {
      observe: 'response',
      headers: {
        queryName: 'callInboundCallerIdsListProxy',
        queryGroups: [VOICE_KEY],
      },
    });
  }

  callVoiceAudioList(): Observable<HttpResponse<VoiceApplicationModel[]>> {
    const url = `https://run.mocky.io/v3/10811e1f-c666-420f-b6a5-468ae4260d82`;

    return this.http.get<VoiceApplicationModel[]>(url, {
      observe: 'response',
      headers: {
        queryName: 'callVoiceAudioList',
        queryGroups: [VOICE_KEY],
      },
    });
  }
}
