import { Injectable, Input, OnDestroy } from '@angular/core';
import { AutoUnsubscribe, UniAuthFacade } from '@unifonic/common';
import { ActivatedRoute, Params } from '@angular/router';
import { FlowsFacade } from '@flows/shared/flows.facade';

@Injectable()
export default class FlowsListService extends AutoUnsubscribe implements OnDestroy {
  @Input() params: Params;
  accountId: string;
  queryParams: Params;
  activeTab = 'ALL';

  constructor(private activatedRoute: ActivatedRoute, private flowsFacade: FlowsFacade, private uniAuthFacade: UniAuthFacade) {
    super();
  }

  public init(limit, status, flowName, triggerType) {
    this.activeTab = status;
    this.accountId = this.uniAuthFacade.user.id;
    this.subs.sink = this.activatedRoute.queryParams.subscribe((params) => {
      let pageNumber = 0;
      if (params.page && params.page > 0) {
        pageNumber = params.page - 1;
      }
      this.queryParams = {
        ...params,
        page: pageNumber,
        limit,
      };
      if (flowName && Boolean(flowName)) {
        this.queryParams['flowName'] = flowName;
      }
      if (triggerType) {
        switch (triggerType) {
          case 'VOICE':
            triggerType = 'FS_INBOUND_VOICE';
            break;
          case 'SCHEDULE':
            triggerType = 'FS_SCHEDULE_TRIGGER';
            break;
          default:
            triggerType = 'FS_' + triggerType;
            break;
        }
        this.queryParams.triggerType = triggerType;
      }
      this.flowsFacade.setUserFlows(this.accountId, this.queryParams);
    });
  }
}
