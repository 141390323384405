import { Injectable } from '@angular/core';
import { SmsAppIdModel, SmsAppIdsResponse, SmsApplicationModel, SmsSenderModel, SmsSendersResponse } from '@flows/shared/sms/sms.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SMS_KEY } from '@flows/store/sms/sms.state';
import { SettingsService } from '@shared/settings.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SmsRepository {
  constructor(private http: HttpClient, private settingsService: SettingsService) {}

  getSmsApplications(): Observable<HttpResponse<SmsApplicationModel[]>> {
    return this.http.get<SmsApplicationModel[]>(`${this.settingsService.apiUrl}/api/v1/proxy/communication/applications`, {
      observe: 'response',
      headers: {
        queryName: 'getSmsApplications',
        queryGroups: [SMS_KEY],
      },
    });
  }

  getSmsAppIds(): Observable<SmsAppIdModel[]> {
    return this.http
      .get<SmsAppIdsResponse>(`${this.settingsService.apiUrl}/api/v1/apps-ids`, {
        headers: {
          queryName: 'getAppIds',
          queryGroups: [SMS_KEY],
        },
      })
      .pipe(map((value) => value.Data?.AppSids?.List || []));
  }

  getSmsSenders(): Observable<SmsSenderModel[]> {
    return this.http
      .get<SmsSendersResponse>(`${this.settingsService.apiUrl}/api/v1/smsSenders`, {
        headers: {
          queryName: 'getSmsSenders',
          queryGroups: [SMS_KEY],
        },
      })
      .pipe(map((value) => value.Data?.SenderIDs || []));
  }
}
