<uni-icon
  *ngIf="iconLeft"
  [name]="iconLeft"
  size="24"
  [type]="disabled ? iconDisabled : iconColor"
></uni-icon>

<div class="uni-menu-item__container">
  <uni-text-body
    [level]="titleLevel"
    [weight]="titleWeight"
    [color]="disabled ? titleDisabled : titleColor"
    class="uni-menu-item__title"
  >
    <ng-content></ng-content>
  </uni-text-body>
  <uni-text-body
    *ngIf="subtitle"
    [level]="subtitleLevel"
    [color]="disabled ? titleDisabled : subtitleColor"
    class="uni-menu-item__subtitle"
  >
    {{ subtitle }}
  </uni-text-body>
</div>

<uni-icon
  *ngIf="iconRight"
  [name]="iconRight"
  size="20"
  [type]="disabled
    ? iconDisabled
    : isSelected
      ? iconSelected
      : iconColor"
></uni-icon>
