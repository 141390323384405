import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import FlowExecutionService from './flow-execution-service';
import { FlowExecution, FlowExecutionLog, FlowNameAndVersionList } from '../models/flow-execution.model';
import { values } from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { FlowsExecutionTableColumns } from './flows-execution-table.model';
import { WidgetService } from '@flows/modules/flow-builder/components/flow-widgets/widget.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';

@Component({
  selector: 'fs-flow-execution',
  templateUrl: './flow-execution.component.html',
  styleUrls: ['./flow-execution.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', display: 'flex' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FlowExecutionComponent implements OnInit {
  topbar;
  form: FormGroup;
  isModalActive: boolean = false;
  isLoaderActive = false;
  elementForDelete: any;
  isTopbar: boolean = true;
  searched: boolean = false;
  displayedColumns = FlowsExecutionTableColumns;
  dataSource: any = null;
  tempDatasource: any = null;
  expandedElement: FlowExecution | null;
  flowExecutionLogs: FlowExecutionLog[] = [];
  totalElements = 0;
  itemsPerPage = 10;
  page = 0;
  isVariablesModalActive: boolean = false;
  actualVariablesDataSource: any[] = [];
  variableColumns = ['name', 'value'];
  downloadReportForm: FormGroup;
  flowNameAndVersionList: FlowNameAndVersionList[] = [];
  flowVersionList = [];
  isLoadingFlows = false;

  constructor(
    private flowExecutionService: FlowExecutionService,
    private widgetService: WidgetService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  get myDisplayedColumns(): string[] {
    return values(this.displayedColumns);
  }

  get searchControl(): FormControl {
    return this.form.get('search') as FormControl;
  }

  get isDatesSelected(): boolean {
    const { startDate, endDate } = this.route.snapshot.queryParams;
    return !!startDate || !!endDate;
  }

  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  ngOnInit(): void {
    this.initForm();
    this.setupDownloadReportForm();
    this.route.queryParams.subscribe(({ page = 1, startDate, endDate }) => {
      this.page = page - 1;
      this.initTable();
    });
  }

  initForm(): void {
    this.form = new FormGroup({
      search: new FormControl(),
      startDate: new FormControl(this.route.snapshot.queryParams.startDate),
      endDate: new FormControl(this.route.snapshot.queryParams.endDate),
    });

    this.form.controls.endDate.valueChanges.subscribe((endDate) => {
      const { search, startDate } = this.form.value;
      if (!search && startDate && endDate) {
        this.router.navigate([], { queryParams: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') } });
      }
    });
  }

  setupDownloadReportForm(): void {
    this.downloadReportForm = new FormGroup({
      emailAddress: new FormControl(null, Validators.required),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
      isWithVariables: new FormControl(false),
      flow: new FormControl({ value: null, disabled: true }),
      flowVersion: new FormControl({ value: null, disabled: true }),
    });
    this.downloadReportForm
      .get('isWithVariables')
      .valueChanges.pipe(filter(Boolean), take(1))
      .subscribe(() => {
        this.isLoadingFlows = true;
        this.flowExecutionService.getFlowNameAndVersionList().subscribe((resp) => {
          this.flowNameAndVersionList = [...resp];
          this.isLoadingFlows = false;
        });
      });
    this.downloadReportForm
      .get('isWithVariables')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        const controls = [this.downloadReportForm.get('flow'), this.downloadReportForm.get('flowVersion')];
        controls.forEach((control, index) => {
          if (value) {
            control.setValidators(Validators.required);
            if (index === 0) {
              control.enable();
            }
          } else {
            control.setValue(null);
            control.setValidators(null);
            control.disable();
          }
          control.updateValueAndValidity();
        });
      });
    this.downloadReportForm
      .get('flow')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        const control = this.downloadReportForm.get('flowVersion');
        this.flowVersionList = value?.flowVersionList?.map((x) => String(x)) || [];
        if (value) {
          control.enable();
        } else {
          control.setValue(null);
          control.disable();
        }
      });
  }

  clearSearchInput(): void {
    this.searchControl.reset();
    this.dataSource = this.tempDatasource;
    this.searched = false;
  }

  initTable(): void {
    this.isLoaderActive = true;
    const { startDate, endDate } = this.route.snapshot.queryParams;
    this.flowExecutionService.getUserFlowExecutionsPaginated(this.page, startDate, endDate).subscribe(
      (flowExecutionResponse) => {
        if (flowExecutionResponse) {
          this.dataSource = new FlowExecutionDatasource(flowExecutionResponse.list);
          this.tempDatasource = new FlowExecutionDatasource(flowExecutionResponse.list);
          this.itemsPerPage = flowExecutionResponse.totalPages;
          this.totalElements = flowExecutionResponse.totalElements;
        }
      },
      (error) => {
        this.isLoaderActive = false;
        console.log('error happen while getting the flow executions ', error);
      },
      () => {
        this.isLoaderActive = false;
      }
    );
  }

  getFlowExecutionLogsData(flowExecution: FlowExecution) {
    this.isLoaderActive = true;

    if (this.expandedElement?.id === flowExecution.id) {
      this.expandedElement = null;
      this.isLoaderActive = false;
      return;
    }
    this.flowExecutionService.getFlowExecutionLogs(flowExecution.id).subscribe(
      (flowList) => {
        this.expandedElement = null;
        if (flowList) {
          this.flowExecutionLogs = flowList;
          this.expandedElement = flowExecution;
        }
      },
      (error) => {
        console.log('error happen while getting the flow list ', error);
      },
      () => {
        this.isLoaderActive = false;
      }
    );
  }

  getExecutionStatusByReferenceId() {
    if (this.searchControl.value.length > 0) {
      this.isLoaderActive = true;
      this.searched = true;
      this.flowExecutionService.getExecutionStatusByReferenceId(this.searchControl.value).subscribe(
        (execution) => {
          this.dataSource = new FlowExecutionDatasource([execution]);
        },
        (error) => {
          this.isLoaderActive = false;
          this.openSnackBar('Error happen while getting execution status by reference', 'error-snackbar');
          console.log('error happen while getting execution status by reference ', error);
        },
        () => {
          this.isLoaderActive = false;
        }
      );
    }
  }

  getWidgetItemByType(type) {
    const widget = this.widgetService.findWidgetByType(type);
    return widget ? widget : this.widgetService.findTriggerWidgetByType(type);
  }

  timeConverter(seconds) {
    seconds = seconds / 1000;
    return new Date(1970, 0, 1).setSeconds(seconds);
  }

  toggleVariablesModal() {
    this.isVariablesModalActive = !this.isVariablesModalActive;
  }

  displayExecutionVariables(flowExecution: FlowExecution) {
    this.actualVariablesDataSource = [];
    this.isLoaderActive = true;
    this.flowExecutionService.getExecutionVariables(flowExecution.executionId).subscribe(
      (resp) => {
        // tslint:disable-next-line:forin
        for (const key in resp) {
          this.actualVariablesDataSource.push({ key, value: resp[key] });
        }
        this.toggleVariablesModal();
        this.isLoaderActive = false;
        this.actualVariablesDataSource = [...this.actualVariablesDataSource];
      },
      (error) => {
        console.log('error while getting  execution variables ', error);
        this.isLoaderActive = false;
        this.openSnackBar('Failed to get execution variables', 'error-snackbar');
      }
    );
  }

  onSubmitDownloadReport() {
    Object.values(this.downloadReportForm.controls).forEach((control) => {
      control.markAsTouched({ onlySelf: true });
    });

    if (this.downloadReportForm.valid) {
      const { flow, flowVersion, ...data } = this.downloadReportForm.getRawValue();
      this.downloadReport({ ...data, flowId: flow?.flowId, flowVersion: +flowVersion });
    }
  }

  downloadReport(data: any) {
    data.endDate = data.endDate.format('YYYY-MM-DD') + 'T23:59:59+07:00';
    data.startDate = data.startDate.format('YYYY-MM-DD') + 'T00:00:00+07:00';
    this.isLoaderActive = true;
    this.flowExecutionService.downloadReport(data).subscribe(
      (response) => {
        this.openSnackBar('Report download successfully initiated', 'success-snackbar');
        this.downloadReportForm.reset();
        this.downloadReportForm.get('isWithVariables').setValue(false);
      },
      (error) => {
        this.openSnackBar('An error occured. Report download was unsuccessful', 'error-snackbar');
      },
      () => {
        this.isLoaderActive = false;
      }
    );
    this.toggleVisibility();
  }

  toggleVisibility() {
    this.isModalActive = !this.isModalActive;
  }

  openSnackBar(message: string, type: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['error-snackbar'];
    config.duration = 4000;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    this.snackBar.open(message, 'Close', config);
  }

  getControl(form: FormGroup, controlName: string) {
    return form.get(controlName) as FormControl;
  }

  clearDates() {
    this.form.patchValue({
      startDate: undefined,
      endDate: undefined,
    });
    this.router.navigate([], {});
  }
}

export class FlowExecutionDatasource extends DataSource<FlowExecution> {
  /** Connect function called by the table to retrieve one stream containing the data to render. */

  constructor(private data: FlowExecution[]) {
    super();
  }

  connect(): Observable<FlowExecution[]> {
    const rows = [];
    if (this.data) {
      this.data.forEach((element) => rows.push(element, { detailRow: true, element }));
    }

    return of(rows);
  }

  disconnect() {}
}
