import {createFeatureSelector, createSelector} from '@ngrx/store';
import {WhatsappState} from '@flows/store/whatsapp/whatsapp.state';

export const selectWhatsappFeature = createFeatureSelector<WhatsappState>('whatsapp');

export const selectWhatsappApplications = createSelector(
  selectWhatsappFeature,
  (state: WhatsappState) => state.whatsappApplications,
);

export const selectWhatsappApplicationTemplates = createSelector(
  selectWhatsappFeature,
  (state: WhatsappState) => state.whatsappApplicationTemplates,
);

export const selectWhatsappApplicationKey = createSelector(
  selectWhatsappFeature,
  (state: WhatsappState) => state.whatsappApplicationKey,
);

