export class VoiceApplicationModel {

    constructor(
        public id?: string,
        public accountId?: string,
        public name?: string,
        public description?: string,
        public applicationKey?: string,
        public callerId?:string
    ) {

    }

}
