import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SettingsService } from '@shared/settings.service';
import { Observable } from 'rxjs';
import { AUDIENCE_QUERY_GROUP } from '@flows/store';
import {
  AudienceEventModel,
  AudiencePropertiesModel,
  AudienceQueryNames,
  AudienceSourceModel,
  GetMappingsResponse,
} from '@flows/shared/audience/audience.model';

@Injectable({
  providedIn: 'root',
})
export class AudienceRepository {
  constructor(private http: HttpClient, private settingsService: SettingsService) {}

  getAudienceEvents(): Observable<HttpResponse<AudienceEventModel>> {
    return this.http.get<AudienceEventModel>(`${this.settingsService.apiUrl}/api/v1/audience/event/types`, {
      observe: 'response',
      headers: {
        queryName: AudienceQueryNames.GetEventList,
        queryGroups: [AUDIENCE_QUERY_GROUP],
      },
    });
  }

  getAudienceSources(): Observable<HttpResponse<AudienceSourceModel>> {
    return this.http.get<AudienceSourceModel>(`${this.settingsService.apiUrl}/api/v1/audience/event/sources`, {
      observe: 'response',
      headers: {
        queryName: AudienceQueryNames.GetSourceList,
        queryGroups: [AUDIENCE_QUERY_GROUP],
      },
    });
  }

  getAudienceProperties(): Observable<HttpResponse<AudiencePropertiesModel>> {
    return this.http.get<AudiencePropertiesModel>(`${this.settingsService.apiUrl}/api/v1/audience/event/properties`, {
      observe: 'response',
      headers: {
        queryName: AudienceQueryNames.GetPropertyList,
        queryGroups: [AUDIENCE_QUERY_GROUP],
      },
    });
  }

  getAudienceMappings(): Observable<GetMappingsResponse> {
    return this.http.get<GetMappingsResponse>(`${this.settingsService.apiUrl}/api/v1/audience/event/mapping-parameters`, {
      headers: {
        queryName: AudienceQueryNames.GetAudienceMappings,
        queryGroups: [AUDIENCE_QUERY_GROUP],
      },
    });
  }
}
