import { Action } from '@ngrx/store';
import {
  AudienceEventModel,
  AudienceMapping,
  AudiencePropertiesModel,
  AudienceSourceModel,
  GetMappingsResponse,
} from '@flows/shared/audience/audience.model';

export const SET_AUDIENCE_EVENTS = '[Audience] SET_AUDIENCE_EVENTS';
export const SET_AUDIENCE_EVENTS_SUCCESS = '[Audience] SET_AUDIENCE_EVENTS_SUCCESS';
export const SET_AUDIENCE_SOURCES = '[Audience] SET_AUDIENCE_SOURCES';
export const SET_AUDIENCE_SOURCES_SUCCESS = '[Audience] SET_AUDIENCE_SOURCES_SUCCESS';
export const SET_AUDIENCE_PROPERTIES = '[Audience] SET_AUDIENCE_PROPERTIES';
export const SET_AUDIENCE_PROPERTIES_SUCCESS = '[Audience] SET_AUDIENCE_PROPERTIES_SUCCESS';
export const SET_AUDIENCE_MAPPINGS = '[Audience] SET_AUDIENCE_MAPPINGS';
export const SET_AUDIENCE_MAPPINGS_SUCCESS = '[Audience] SET_AUDIENCE_MAPPINGS_SUCCESS';
export const SET_SELECTED_AUDIENCE_MAPPING = '[Audience] SET_SELECTED_AUDIENCE_MAPPING';

export class SetAudienceEvents implements Action {
  readonly type = SET_AUDIENCE_EVENTS;

  constructor() {}
}

export class SetAudienceEventsSuccess implements Action {
  readonly type = SET_AUDIENCE_EVENTS_SUCCESS;

  constructor(public response: AudienceEventModel) {}
}

export class SetAudienceSources implements Action {
  readonly type = SET_AUDIENCE_SOURCES;

  constructor() {}
}

export class SetAudienceSourcesSuccess implements Action {
  readonly type = SET_AUDIENCE_SOURCES_SUCCESS;

  constructor(public response: AudienceSourceModel) {}
}

export class SetAudienceProperties implements Action {
  readonly type = SET_AUDIENCE_PROPERTIES;

  constructor() {}
}

export class SetAudiencePropertiesSuccess implements Action {
  readonly type = SET_AUDIENCE_PROPERTIES_SUCCESS;

  constructor(public response: AudiencePropertiesModel) {}
}

export class SetAudienceMappings implements Action {
  readonly type = SET_AUDIENCE_MAPPINGS;

  constructor() {}
}

export class SetAudienceMappingsSuccess implements Action {
  readonly type = SET_AUDIENCE_MAPPINGS_SUCCESS;

  constructor(public response: GetMappingsResponse['mappings']) {}
}

export class SetSelectedAudienceMapping implements Action {
  readonly type = SET_SELECTED_AUDIENCE_MAPPING;

  constructor(public mapping?: AudienceMapping) {}
}

export type All =
  | SetAudienceEvents
  | SetAudienceEventsSuccess
  | SetAudienceSources
  | SetAudienceSourcesSuccess
  | SetAudienceProperties
  | SetAudiencePropertiesSuccess
  | SetAudienceMappings
  | SetAudienceMappingsSuccess
  | SetSelectedAudienceMapping;
