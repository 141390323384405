<uni-loader *ngIf="isLoaderActive"></uni-loader>
<div *ngIf="templatesList">
  <div *ngIf="templatesList.length" class="x3-container">
    <div *ngFor="let template of templatesList" class="round_edges flow-template border rounded mt-4">
      <div class="flow-template-head fs_item_head">
        <uni-text-subheader [isUppercase]="false">{{template.name}}</uni-text-subheader>
        <div *appCheckPermissions="permissions.edit, feature: features.TEMPLATE_MANAGEMENT">
          <uni-icon [matMenuTriggerFor]="menu" [name]="'ellipsis-v'"></uni-icon>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <uni-link [path]="'flows/flow-builder?type=template&templateId='+template.id+''">
                {{ 'label.edit' | translate }}
              </uni-link>
            </button>
            <button mat-menu-item>
              <span>
                <uni-link (click)="openConfirmModal(template.id)">
                  {{ 'label.delete' | translate }}
                </uni-link>
              </span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="flow-template-body fs_item_body">
        <uni-text-info>{{template.description}}</uni-text-info>
      </div>
      <div class="fs_item_foot d-flex justify-content-between">
        <ng-container *appCheckPermissions="permissions.see, feature: features.TEMPLATE_USE">
          <uni-link *ngIf="template.templateStatus == 'PUBLISH'"
                    [path]="'flows/flow-builder?type=template&useTemplateForFlow=true&templateId='+template.id+''">
            {{ 'label.useTemplate' | translate }}
          </uni-link>
        </ng-container>
        <div>
          <uni-tag type="${UniTagType.active}">{{template.templateStatus}}</uni-tag>
        </div>
      </div>
    </div>
  </div>
</div>
<fs-template-delete-modal
  [isActive]="isModalActive"
  (isActiveChange)="toggleModal()"
  (submitChange)="onRemove()"
></fs-template-delete-modal>
